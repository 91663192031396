import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeliveryOrderService {
    constructor(private http: HttpClient) {}

    getSingleDeliveryOrder(deliveryOrderId: number): Observable<any> {
        return this.http.get<any>(`api:///DeliveryOrder/GetSingleDeliveryOrder/${deliveryOrderId}`);
    }

    getDeliveryOrderList(filter: any): Observable<any> {
        return this.http.post('api:///DeliveryOrder/DeliveryOrderList', filter);
    }

    getDeliveryOrders(id) {
        return this.http.get<any[]>(`api:///DeliveryOrder/GetDeliveryOrders/${id}`);
    }

    getTerminalsWithinSamePort(portId) {
        return this.http.get<any>(`api:///DeliveryOrder/GetTerminalsWithinSamePort/${portId}`);
    }

    getTerminalOperatorsBasedOnTerminal(terminalId) {
        return this.http.get<any>(`api:///DeliveryOrder/GetTerminalOperatorsBasedOnTerminal/${terminalId}`);
    }

    getBerthsBasedOnTerminal(terminalId) {
        return this.http.get<any>(`api:///DeliveryOrder/GetBerthsBasedOnTerminal/${terminalId}`);
    }

    getSystemsBasedOnOrganization(terminalId, organizationId) {
        return this.http.get<any>(`api:///DeliveryOrder/GetSystemsBasedOnOrganization/${terminalId}/${organizationId}`);
    }

    addDeliveryOrder(model: any) {
        return this.http.post<any>('api:///DeliveryOrder/AddNewDeliveryOrder', model);
    }

    deleteDeliveryOrder(deliverOrderIds: number[]) {
        return this.http.post<any>('api:///DeliveryOrder/DeleteDeliveryOrder/', deliverOrderIds);
    }

    cancelDeliveryOrder(deliverOrderId: number) {
        return this.http.post<any>('api:///DeliveryOrder/CancelDeliveryOrder/', deliverOrderId);
    }

    sendDeliveryOrder(deliverOrderId: number) {
        return this.http.post<any>('api:///DeliveryOrder/SendDeliveryOrder/', deliverOrderId);
    }

    getSingleDeliveryOrderItem(deliveryOrderItemId: number): Observable<any> {
        return this.http.get<any>(`api:///DeliveryOrder/GetSingleDeliveryOrderItem/${deliveryOrderItemId}`);
    }

    getDeliveryOrderItems(deliveryOrderId: number): Observable<any> {
        return this.http.get<any[]>(`api:///DeliveryOrder/GetDeliveryOrderItems/${deliveryOrderId}`);
    }

    addDeliveryOrderItem(model: any) {
        return this.http.post<any>('api:///DeliveryOrder/AddNewDeliveryOrderItem', model);
    }

    deleteDeliveryOrderItem(deliveryOrderItemIds: any) {
        return this.http.post<any>('api:///DeliveryOrder/DeleteDeliveryOrderItem/', deliveryOrderItemIds);
    }

    addAttachment(model: any) {
        return this.http.post<any>(`api:///DeliveryOrder/AddAttachment`, model);
    }

    removeAttachment(ids: any) {
        return this.http.post<any>(`api:///DeliveryOrder/RemoveAttachment`, ids);
    }
}
