import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class PasswordValidationService {
    constructor(
        private toastyService: ToastrService,
        private translateService: TranslateService
    ) {}

    isPasswordValid(password: string, confirmPassword: string): boolean {
        // passwords match
        if (password !== confirmPassword) {
            this.toastyService.error(this.translateService.instant(marker('Passwords do not match')));
            return false;
        }
        // minimum 9 characters
        if (password.length < 9) {
            this.toastyService.error(
                this.translateService.instant(marker('Your password must be at least 9 characters long.'))
            );
            return false;
        }
        // at least one number
        if (!/\d/.test(password)) {
            this.toastyService.error(
                this.translateService.instant(marker('Your password must contain at least one digit (0-9).'))
            );
            return false;
        }
        // at least one special character
        if (!/[^a-zA-Z0-9]/.test(password)) {
            this.toastyService.error(
                this.translateService.instant(
                    marker('Your password must contain at least one non alphanumeric character.')
                )
            );
            return false;
        }
        // at least one uppercase letter
        if (!/[A-Z]/.test(password)) {
            this.toastyService.error(
                this.translateService.instant(marker('Your password must contain at least one uppercase letter (A-Z).'))
            );
            return false;
        }
        // at least one lowercase letter
        if (!/[a-z]/.test(password)) {
            this.toastyService.error(
                this.translateService.instant(marker('Your password must contain at least one lowercase letter (a-z).'))
            );
            return false;
        }
        return true;
    }
}

