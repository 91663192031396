import { EnvironmentNames } from '@common/knownTypes/EnvironmentNames';
import * as _ from 'lodash';
import { environment as base } from './environment.base';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    production: true,
    name: EnvironmentNames.DEVELOP
});
