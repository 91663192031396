import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CodelistService } from '@common/services/codelist.service';
import { ModalService } from '@common/services/modal.service';
import { VesselVisitService } from '@common/services/vessel-visit.service';
import { maxLengthValidator } from '@common/validators/max-length-validator';
import { regexValidator } from '@common/validators/regex-validator';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-save-vehicle-modal',
    templateUrl: './save-vehicle-modal.component.html',
    styleUrls: ['./save-vehicle-modal.component.scss']
})
export class SaveVehicleModalComponent implements OnInit, AfterViewInit {
    vesselVisitId: number;
    isBusy: boolean;
    form: UntypedFormGroup;
    isEditMode: boolean;
    vehicle: any;
    terminalOptions$: Observable<any>;

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        private cdr: ChangeDetectorRef,
        public service: VesselVisitService,
        public codelistService: CodelistService
    ) {}

    ngOnInit() {
        this.form = this.createFormGroup();
        this.terminalOptions$ = this.service.getTerminalOptionsForVehicles(this.vesselVisitId);
        this.checkEditMode();
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    public saveVehicle() {
        const body = { ...this.form.getRawValue(), vesselVisitId: this.vesselVisitId };
        this.service.saveVehicle(body).subscribe({
            next: () => {
                this.isEditMode
                    ? this.toastyService.success(this.translateService.instant(marker('Vehicle updated successfully')))
                    : this.toastyService.success(this.translateService.instant(marker('Vehicle added successfully')));
                this.activeModal.close(true);
            }
        });
    }

    private checkEditMode() {
        if (this.vehicle) {
            this.isEditMode = true;
            this.form.patchValue(this.vehicle);
        }
    }

    private createFormGroup() {
        const form = new UntypedFormGroup({
            id: new UntypedFormControl(null),
            cargoDirectionId: new UntypedFormControl(null, requiredValidator()),
            relationSystemToOrganizationToTerminalId: new UntypedFormControl(null, requiredValidator()),
            vehicleTypeId: new UntypedFormControl(null, requiredValidator()),
            vehicleMake: new UntypedFormControl(null),
            vehicleModel: new UntypedFormControl(null),
            vin: new UntypedFormControl(null),
            quantity: new UntypedFormControl(null),
            weight: new UntypedFormControl(null),
            length: new UntypedFormControl(null),
            width: new UntypedFormControl(null),
            selfPropelled: new UntypedFormControl(true),
            driverName: new UntypedFormControl(null),
            licencePlate: new UntypedFormControl(null, requiredValidator()),
            trailerPlates: new UntypedFormControl(null),
            countryOfOriginId: new UntypedFormControl(null),
            countryOfDestinationId: new UntypedFormControl(null),
            portOfLoadingId: new UntypedFormControl(null),
            portOfDischargeId: new UntypedFormControl(null),
            cargoDescription: new UntypedFormControl(null),
            carriesDangerousGoods: new UntypedFormControl(false)
        });
        form.patchValue(this);
        form.get('vin').setValidators([maxLengthValidator(17), regexValidator('^[A-Za-z0-9]*$')]);
        return form;
    }
}
