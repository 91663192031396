import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return (control: AbstractControl): { [key: string]: any } | null => {
        let valErr = control.errors;

        if (control.value != null && !validateEmail(control.value)) {
            if (valErr == null) {
                valErr = {};
            }

            valErr['email'] = {
                code: 'rfv09'
            };
        } else {
            if (valErr && valErr['email']) delete valErr['email'];
            if (valErr && Object.keys(valErr).length == 0) valErr = null;
        }

        return valErr;
    };
}
