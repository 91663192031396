import { Directive, ViewChild } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserModel } from '../models/User.model';
import { ModalService } from '../services/modal.service';
import { currentUserSubject } from '../services/user.service';

@Directive()
export class CommonController {
    user: UserModel;
    currentUser$: Subscription;
    protected initialSelectedTabIndex: number;

    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

    constructor(
        protected dialogService: ModalService,
        protected translateService: TranslateService
    ) {
        this.user = currentUserSubject.getValue();
    }

    hasPermission(fullPermissionName: string) {
        const user = currentUserSubject.getValue();
        if (!user) {
            return false;
        }
        return user.hasPermission(fullPermissionName);
    }

    belongsToOrganization(organization: any): boolean {
        const user = currentUserSubject.getValue();
        if (!user || !organization) {
            return false;
        }
        return user.belongsToOrganization(organization);
    }

    userHasRole(roleName: string) {
        const user = currentUserSubject.getValue();
        if (!user) {
            return false;
        }
        return user.hasRole(roleName);
    }

    userBelongsToOrganization(organizations: any) {
        const user = currentUserSubject.getValue();
        if (!user) {
            return false;
        }
        return user.organization.id.toString() === organizations.toString();
    }

    triggerDeactivationMessage(): Observable<any> {
        return this.dialogService
            .confirm(
                this.translateService.instant(marker('Confirm removal')) as string,
                this.translateService.instant(marker('Are you sure you want to remove this item?')) as string,
                this.translateService.instant(marker('YES')) as string,
                this.translateService.instant(marker('No')) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)));
    }

    triggerSaveMessage(message = null): Observable<any> {
        return this.dialogService
            .confirm(
                this.translateService.instant(marker('Confirm save')) as string,
                this.translateService.instant(
                    marker(message ? message : 'Are you sure you want to save this item?')
                ) as string,
                this.translateService.instant(marker('YES')) as string,
                this.translateService.instant(marker('No')) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)));
    }

    processDates(model: any, datePropsOnModel: any[]) {
        if (!model) return;

        for (const [key, value] of Object.entries(model)) {
            if (datePropsOnModel.includes(key, 0) && value !== null) {
                model[key] = new Date(value as string);
            }
        }
    }

    /** Displays tooltips in kendo grid columns where text is longer than a given cell width */
    public showTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if (
            (element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
            element.offsetWidth < element.scrollWidth
        ) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    rowCallback = (context: RowClassArgs) => {
        const { dataItem } = context;

        if (dataItem?.customsHold === true) {
            const color = this.isCustomsHoldVisible(dataItem) ? dataItem?.guiColor || 'orange' : false;
            return {
                [color]: true
            };
        }

        if (
            (typeof dataItem?.numberOfUnits === 'number' && dataItem?.numberOfUnits < 0) ||
            (typeof dataItem?.nettWeight === 'number' && dataItem?.nettWeight < 0) ||
            (typeof dataItem?.grossWeight === 'number' && dataItem?.grossWeight < 0) ||
            (typeof dataItem?.volume === 'number' && dataItem?.volume < 0) ||
            (typeof dataItem?.onStockNumberOfUnits === 'number' && dataItem?.onStockNumberOfUnits < 0) ||
            (typeof dataItem?.onStockNettWeight === 'number' && dataItem?.onStockNettWeight < 0) ||
            (typeof dataItem?.onStockGrossWeight === 'number' && dataItem?.onStockGrossWeight < 0) ||
            (typeof dataItem?.onStockVolume === 'number' && dataItem?.onStockVolume < 0)
        ) {
            return {
                ['red']: true
            };
        }
    };

    getCustomsIconTooltip(row: any) {
        if (!row?.cargoHoldTypeVisiblities) {
            return false;
        }

        const tooltip = row.cargoHoldTypeVisiblities
            .filter(
                (visibility) =>
                    this.hasPermission('Customs.CustomsHold.ViewCustoms') ||
                    (visibility.cargoHoldVisibilityId !== 'CU' &&
                        this.hasPermission('Customs.CustomsHold.ViewTerminal')) ||
                    (visibility.cargoHoldVisibilityId !== 'CU' && visibility.cargoHoldVisibilityId !== 'CUTO')
            )
            .map((visibility) => visibility.cargoHoldTypeId);

        return tooltip.length > 0 ? tooltip : false;
    }

    isCustomsHoldVisible(row: any) {
        const hasTerminalViewable = !!row?.cargoHoldTypeVisiblities?.some(
            (visibilities) => visibilities?.cargoHoldVisibilityId === 'CUTO'
        );

        const hasPublicViewable = !!row?.cargoHoldTypeVisiblities?.some(
            (visibilities) => visibilities?.cargoHoldVisibilityId === 'PUB'
        );

        return (
            this.hasPermission('Customs.CustomsHold.ViewCustoms') ||
            (this.hasPermission('Customs.CustomsHold.ViewTerminal') && hasTerminalViewable) ||
            hasPublicViewable
        );
    }
}
