import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodeListLanguageModel } from '@common/models/common/CodeListLanguageModel';
import { ControlPropertyModel } from '@common/models/control-property.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QuickReportsService {
    constructor(private http: HttpClient) {}

    getReports(): Observable<any> {
        return this.http.get<any>('api:///QuickReports/GetQuickReports');
    }

    getReport(id: string) {
        return this.http.get(`api:///QuickReports/GetReport/${id}`);
    }

    saveQuickReport(model: any) {
        return this.http.post('api:///QuickReports/SaveQuickReport', model);
    }

    removeQuickReportLanguage(reportId: string, languageId: any) {
        return this.http.post('api:///QuickReports/RemoveQuickReportLanguage', {
            quickReportId: reportId,
            languageId: languageId
        });
    }

    addQuickReportLanguage(model: CodeListLanguageModel) {
        return this.http.post('api:///QuickReports/AddQuickReportLanguage', model);
    }

    addQuickReportProperty(model: ControlPropertyModel) {
        return this.http.post('api:///QuickReports/AddQuickReportProperty', model);
    }

    removeQuickReportProperty(reportId: string, propertyId: any) {
        return this.http.post('api:///QuickReports/RemoveQuickReportProperty', {
            quickReportId: reportId,
            propertyId: propertyId
        });
    }
}
