import { DatePipe } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CodelistDisplayModel } from '@common/models/common/CodelistDisplay.model';
import { GeneralActService } from '@common/services/general-act.service';
import { maxLengthValidator } from '@common/validators/max-length-validator';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { GeneralActDisplayModel } from '../../models/GeneralAct.display.model';

@Component({
    selector: 'app-save-general-act-modal',
    templateUrl: './save-general-act-modal.component.html',
    styleUrls: ['./save-general-act-modal.component.scss']
})
export class SaveGeneralActModalComponent implements OnInit, AfterViewInit, AfterContentChecked {
    public title: string;
    vesselVisitId: number;
    agentId: string;
    vesselName: string;
    vesselVisitEta: Date;
    form: FormGroup;
    isBusy: boolean;
    editMode: boolean;
    // if we have generalAct, then it is for edit, otherwise it is for create
    generalAct: GeneralActDisplayModel;

    terminalOptions$: Observable<any>;
    terminalOptions: CodelistDisplayModel[];
    vesselAgents$: Observable<any>;
    customMRNCodelist$: Observable<any>;
    vesselAgents: CodelistDisplayModel[];
    customMRNCodelist: CodelistDisplayModel[];

    // this is for the case when it is edit mode, to check if the mrnId is changed in order to update the number
    initialMrnId: number;

    constructor(
        public activeModal: DialogRef,
        private generalActService: GeneralActService,
        private toastyService: ToastrService,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.form = this.createFormGroup();
        this.initializeModal();
    }

    ngAfterViewInit(): void {
        this.checkEditMode();
        this.trackFormChanges();
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    saveGeneralAct() {
        this.generalActService
            .saveGeneralAct({ ...this.form.getRawValue(), vesselVisitId: this.vesselVisitId })
            .subscribe({
                next: () => {
                    this.generalAct
                        ? this.toastyService.success(
                              this.translateService.instant(marker('Outturn Report saved successfully'))
                          )
                        : this.toastyService.success(
                              this.translateService.instant(marker('Outturn Report created successfully'))
                          );
                    this.activeModal.close(true);
                },
                error: () => {}
            });
    }

    private initializeModal() {
        this.isBusy = true;
        this.title = this.generalAct
            ? this.translateService.instant(marker('Edit Outturn Report'))
            : this.translateService.instant(marker('Create Outturn Report'));
        this.initialMrnId = this.generalAct?.mrnId;
        forkJoin({
            terminalOptions: this.generalActService.getTerminalOptions(this.vesselVisitId),
            vesselAgents: this.generalActService.getVesselAgents(),
            customMRNCodelist: this.generalActService.getGeneralActCustomMRNCodelist(this.vesselVisitId)
        }).subscribe(({ terminalOptions, vesselAgents, customMRNCodelist }) => {
            this.terminalOptions = terminalOptions as CodelistDisplayModel[];
            if (this.terminalOptions?.length === 1)
                this.form.patchValue({ relationSystemToOrganizationToTerminalId: this.terminalOptions[0].id });
            this.vesselAgents = vesselAgents as CodelistDisplayModel[];
            this.customMRNCodelist = customMRNCodelist as CodelistDisplayModel[];
            if (this.customMRNCodelist?.length === 1) this.form.patchValue({ mrnId: this.customMRNCodelist[0].id });
            this.isBusy = false;
        });
    }

    private checkEditMode() {
        if (this.generalAct) {
            this.editMode = true;
            this.form.patchValue({
                ...this.generalAct,
                shippingLineId: this.generalAct.shippingLineId.toString(),
                relationSystemToOrganizationToTerminalId:
                    this.generalAct.relationSystemToOrganizationToTerminalId.toString()
            });
            ['relationSystemToOrganizationToTerminalId', 'cargoDirectionId', 'forContainers'].forEach((x) =>
                this.form.get(x).disable()
            );
        } else {
            // had to do this, because when edit mode it overrides the name in undefined / null
            this.form
                .get('name')
                .patchValue(this.vesselName + ' / ' + this.datePipe.transform(this.vesselVisitEta, 'dd.MM.yyyy'));
        }
    }

    private createFormGroup() {
        const form = new FormGroup({
            id: new FormControl(null),
            relationSystemToOrganizationToTerminalId: new FormControl(null, requiredValidator()),
            shippingLineId: new FormControl(this.agentId, requiredValidator()),
            cargoDirectionId: new FormControl('I', requiredValidator()),
            mrnId: new FormControl(null),
            forContainers: new FormControl(false, requiredValidator()),
            number: new FormControl(null, requiredValidator()),
            name: new FormControl(''),
            remark: new FormControl(null),
            vesselRepresentative: new FormControl(null, maxLengthValidator(50)),
            terminalRepresentative: new FormControl(null, maxLengthValidator(50)),
            customsRepresentative: new FormControl(null, maxLengthValidator(50)),
            operationsStartDate: new FormControl(null),
            operationsEndDate: new FormControl(null)
        });
        form.patchValue(this as any);
        return form;
    }

    private trackFormChanges() {
        if (!this.editMode) {
            this.form.get('relationSystemToOrganizationToTerminalId').valueChanges.subscribe((value) => {
                if (value) {
                    this.generalActService.checkIfVesselVisitIsForContainers(this.vesselVisitId, value).subscribe({
                        next: (result) => {
                            if (result) {
                                this.form.get('forContainers').patchValue(true);
                            } else {
                                this.form.get('forContainers').patchValue(false);
                            }
                        }
                    });
                }
            });
        }

        this.form.get('mrnId').valueChanges.subscribe((value) => {
            if (value && value !== this.initialMrnId?.toString()) {
                const mrnValue = this.customMRNCodelist.find((x) => x.id === value);
                this.form.get('number').patchValue(mrnValue?.name);
            }
        });
    }
}
