import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { CodelistService } from '@services/codelist.service';
import { ModalService } from '@services/modal.service';
import { NominatedAgentService } from '@services/nominated-agent.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-save-cargo-item',
    templateUrl: './save-cargo-item.component.html',
    styleUrls: ['./save-cargo-item.component.scss']
})
export class SaveCargoItemComponent implements OnInit, AfterViewInit, AfterContentChecked {
    form: UntypedFormGroup;
    customPortCodelist: any = [];
    manifestId = 0;
    containerized: boolean;
    forTosSystem: string;
    isArrival: boolean;
    isBusy: boolean;
    organizations$: Observable<any>;

    cargoManifestItem: any;
    isEditMode: boolean = false;
    showIMDG: boolean = false;

    cargoManifestMswDGContact: string;
    cargoManifestTypeId: string;

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private toastyService: ToastrService,
        public service: NominatedAgentService,
        public codelistService: CodelistService,
        public nominatedAgentService: NominatedAgentService
    ) {}

    ngOnInit(): void {
        this.form = this.createFormGroup();
        this.organizations$ = this.nominatedAgentService.getOrganizationsForCargoManifest(this.manifestId);
        this.checkEditMode();
        this.customValidations();
    }

    ngAfterViewInit() {
        // had to use the timeout because when the edit mode was first time opened, the form was not fully displayed.
        // If you know some other way to do it, please change it, because this is not the best solution.
        if (this.isEditMode) {
            setTimeout(() => {
                this.trackFormChanges();
            }, 0);
        } else this.trackFormChanges();
        this.cdr.detectChanges();
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    public get getIsFormDisabled() {
        return this.isBusy || (!this.form.valid && this.form.dirty);
    }

    saveCargoItem() {
        const confirmMessage = this.isEditMode
            ? this.translateService.instant(marker('Are you sure you want to update Cargo List item?'))
            : this.translateService.instant(marker('Are you sure you want to add Cargo List item?'));
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Confirm')),
                confirmMessage,
                this.translateService.instant(marker('YES')),
                this.translateService.instant(marker('No'))
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((res) => {
                if (!res) return;
                const body = this.form.getRawValue();
                this.service.saveCargoManifestItem(body).subscribe(() => {
                    this.isEditMode
                        ? this.toastyService.success(this.translateService.instant(marker('Cargo item updated')))
                        : this.toastyService.success(this.translateService.instant(marker('Cargo item added')));
                    this.activeModal.close(body);
                    if (!this.isEditMode) window.location.reload();
                });
            });
    }

    private checkEditMode() {
        if (this.cargoManifestItem) {
            this.isEditMode = true;
            this.form.patchValue(this.cargoManifestItem);
        }
    }

    private createFormGroup() {
        const form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            bookingNumber: new UntypedFormControl(),
            billOfLadingNbr: new UntypedFormControl(),
            containerNumber: new UntypedFormControl(),
            shippingLineOrganizationId: new UntypedFormControl(),
            loadTypeId: new UntypedFormControl(),
            containerIsoCodeId: new UntypedFormControl(),
            containerStatusId: new UntypedFormControl(),
            sealNumber: new UntypedFormControl(),
            sealTypeId: new UntypedFormControl(),
            containerTareWeight: new UntypedFormControl(),
            cargoNetWeight: new UntypedFormControl(),
            cargoGrossWeight: new UntypedFormControl(null, requiredValidator()),
            vgm: new UntypedFormControl(),
            cargoVolume: new UntypedFormControl(),
            packagingTypeId: new UntypedFormControl(null, requiredValidator()),
            numberOfPackages: new UntypedFormControl(),
            portOfOriginId: new UntypedFormControl(),
            portOfLoadingId: new UntypedFormControl(),
            portOfDischargeId: new UntypedFormControl(),
            portOfTranshipmentId: new UntypedFormControl(),
            fpodId: new UntypedFormControl(),
            cargoTypePerSystemId: new UntypedFormControl(),
            cargoReference: new UntypedFormControl(),
            cargoDescription: new UntypedFormControl(null, requiredValidator()),
            cargoShipper: new UntypedFormControl(),
            cargoNotify: new UntypedFormControl(),
            cargoConsingnee: new UntypedFormControl(),
            customsDeclarationNumber: new UntypedFormControl(),
            customsDeclarationItemNumber: new UntypedFormControl(),
            taricCode: new UntypedFormControl(),
            imdgClassId: new UntypedFormControl(),
            mswCargoRegulationId: new UntypedFormControl(),
            unNumberId: new UntypedFormControl(),
            packingGroupId: new UntypedFormControl(),
            temperatureFrom: new UntypedFormControl(),
            temperatureTo: new UntypedFormControl(),
            cargoTechnicalDescription: new UntypedFormControl(),
            dgContact: new UntypedFormControl(),
            manifestId: new UntypedFormControl(this.manifestId),
            manifestRowId: new UntypedFormControl(),
            marpolCategoryId: new UntypedFormControl(),
            ibcCodeId: new UntypedFormControl(),
            imsbcCodeId: new UntypedFormControl(),
            flashpoint: new UntypedFormControl(),
            locationOnBoard: new UntypedFormControl(),
            cargoCustomsStatusId: new UntypedFormControl(),
            cargoRealizationTypeId: new UntypedFormControl(),
            inTransit: new UntypedFormControl()
        });
        form.patchValue(this);
        return form;
    }

    private customValidations() {
        if (this.containerized) {
            if (this.cargoManifestTypeId === 'DCM') {
                this.form.get('bookingNumber').setValidators(requiredValidator());
                this.form.controls['bookingNumber'].updateValueAndValidity();
            }

            this.form.get('cargoNetWeight').setValidators(requiredValidator());
            this.form.controls['cargoNetWeight'].updateValueAndValidity();

            this.form.get('containerNumber').setValidators(requiredValidator());
            this.form.controls['containerNumber'].updateValueAndValidity();

            this.form.get('containerIsoCodeId').setValidators(requiredValidator());
            this.form.controls['containerIsoCodeId'].updateValueAndValidity();

            this.form.get('loadTypeId').setValidators(requiredValidator());
            this.form.controls['loadTypeId'].updateValueAndValidity();

            this.form.get('shippingLineOrganizationId').setValidators(requiredValidator());
            this.form.controls['shippingLineOrganizationId'].updateValueAndValidity();
        } else {
            this.form.get('cargoTypePerSystemId').setValidators(requiredValidator());
            this.form.controls['cargoTypePerSystemId'].updateValueAndValidity();
        }

        if (this.cargoManifestTypeId === 'ACM') {
            this.form.get('billOfLadingNbr').setValidators(requiredValidator());
            this.form.controls['billOfLadingNbr'].updateValueAndValidity();
            this.form.get('cargoConsingnee').setValidators(requiredValidator());
            this.form.controls['cargoConsingnee'].updateValueAndValidity();
        }
    }

    private trackFormChanges() {
        if (!this.containerized) {
            const dgClassificationBasedControls = [
                'marpolCategoryId',
                'imdgClassId',
                'unNumberId',
                'packingGroupId',
                'ibcCodeId',
                'imsbcCodeId',
                'flashpoint',
                'cargoTechnicalDescription',
                'locationOnBoard',
                'dgContact'
            ];
            const enableAgainControls = ['cargoTechnicalDescription', 'flashpoint', 'locationOnBoard', 'dgContact'];
            dgClassificationBasedControls.forEach((control) => {
                this.form.get(control).disable();
            });
            this.form.get('mswCargoRegulationId').valueChanges.subscribe((value) => {
                if (value) {
                    if (!this.isEditMode) {
                        if (this.cargoManifestMswDGContact) {
                            this.form.get('dgContact').setValue(this.cargoManifestMswDGContact);
                        }
                    } else {
                        if (this.form.get('dgContact').value === null && this.cargoManifestMswDGContact) {
                            this.form.get('dgContact').setValue(this.cargoManifestMswDGContact);
                        }
                    }
                }
                if (value === 'MARPOL_ANNEX1') {
                    ['marpolCategoryId', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                    });
                    this.form.get('marpolCategoryId').setValidators(requiredValidator());
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    this.form.controls['marpolCategoryId'].updateValueAndValidity();
                    ['imdgClassId', 'unNumberId', 'packingGroupId', 'ibcCodeId', 'imsbcCodeId'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IMDG') {
                    this.form.get('marpolCategoryId').setValidators(null);
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    ['imdgClassId', 'unNumberId', 'packingGroupId', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    this.form.controls['marpolCategoryId'].updateValueAndValidity();
                    ['marpolCategoryId', 'ibcCodeId', 'imsbcCodeId'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IBC') {
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    ['marpolCategoryId', 'ibcCodeId', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.get('dgContact').setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                    ['unNumberId', 'packingGroupId', 'imdgClassId', 'imsbcCodeId'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IMSBC') {
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    ['marpolCategoryId', 'imsbcCodeId', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    ['unNumberId', 'packingGroupId', 'imdgClassId', 'ibcCodeId'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else if (!value) {
                    dgClassificationBasedControls.forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else {
                    dgClassificationBasedControls.forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
        }

        if (this.containerized) {
            const disabledControls = [
                'loadTypeId',
                'cargoDescription',
                'cargoReference',
                'cargoVolume',
                'flashpoint',
                'cargoTechnicalDescription',
                'temperatureFrom',
                'temperatureTo',
                'cargoGrossWeight'
            ];
            const requiredControls = ['cargoNetWeight'];
            this.form.get('imdgClassId').valueChanges.subscribe((value) => {
                if (value) {
                    ['unNumberId', 'packingGroupId', 'dgContact'].forEach((control) => {
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    if (!this.isEditMode) {
                        if (this.cargoManifestMswDGContact) {
                            this.form.get('dgContact').setValue(this.cargoManifestMswDGContact);
                        }
                    } else {
                        if (this.form.get('dgContact').value === null && this.cargoManifestMswDGContact) {
                            this.form.get('dgContact').setValue(this.cargoManifestMswDGContact);
                        }
                    }
                } else {
                    ['unNumberId', 'packingGroupId', 'dgContact'].forEach((control) => {
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
            this.form.get('containerStatusId').valueChanges.subscribe((value) => {
                const disabled = value === 'E';

                if (disabled) {
                    disabledControls.forEach((control) => {
                        this.form.get(control).disable();
                    });
                    requiredControls.forEach((control) => {
                        this.form.get(control).setValue('0');
                    });
                    if (this.cargoManifestTypeId === 'ACM') {
                        this.form.get('billOfLadingNbr').setValidators(null);
                        this.form.controls['billOfLadingNbr'].updateValueAndValidity();
                    }
                } else if (value === 'F') {
                    disabledControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    requiredControls.forEach((control) => {
                        this.form.get(control).setValue(null);
                        this.form.get('cargoGrossWeight').setValue(null);
                    });
                    this.form.get('loadTypeId').setValidators(requiredValidator());
                    this.form.controls['loadTypeId'].updateValueAndValidity();
                    if (this.cargoManifestTypeId === 'ACM') {
                        this.form.get('billOfLadingNbr').setValidators(requiredValidator());
                        this.form.controls['billOfLadingNbr'].updateValueAndValidity();
                    }
                } else {
                    disabledControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    requiredControls.forEach((control) => {
                        this.form.get(control).setValue(null);
                        this.form.get('cargoGrossWeight').setValue(null);
                    });
                    this.form.get('loadTypeId').setValidators(null);
                    this.form.controls['loadTypeId'].updateValueAndValidity();
                    if (this.cargoManifestTypeId === 'ACM') {
                        this.form.get('billOfLadingNbr').setValidators(requiredValidator());
                        this.form.controls['billOfLadingNbr'].updateValueAndValidity();
                    }
                }
            });
        }
        this.form.get('containerNumber').valueChanges.subscribe((value) => {
            if (value) {
                ['containerStatusId', 'containerIsoCodeId'].forEach((control) => {
                    this.form.get(control).setValidators(requiredValidator());
                    this.form.controls[control].updateValueAndValidity();
                });
            } else {
                ['containerStatusId', 'containerIsoCodeId'].forEach((control) => {
                    this.form.get(control).setValidators(null);
                    this.form.controls[control].updateValueAndValidity();
                });
            }
        });
    }
}
