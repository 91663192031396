<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="isBusy">
                <ng-template>
                    <form class="p-3" [formGroup]="form">
                        <div class="row">
                            <div class="col">
                                <form-dropdown
                                    labelText="{{ 'Terminal' | translate }}"
                                    formControlName="relationSystemToOrganizationToTerminalId"
                                    [displayValue]="'name'"
                                    [codeListValues]="terminalOptions"></form-dropdown>
                            </div>
                            <div class="col">
                                <form-dropdown
                                    labelText="{{ 'Shipping Agent' | translate }}"
                                    formControlName="shippingLineId"
                                    [codeListValues]="vesselAgents"></form-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-dropdown
                                    labelText="{{ 'Cargo Direction' | translate }}"
                                    formControlName="cargoDirectionId"
                                    codeListName="CargoDirection"></form-dropdown>
                            </div>
                            <div class="col">
                                <form-dropdown
                                    labelText="{{ 'Primary MRN' | translate }}"
                                    formControlName="mrnId"
                                    displayValue="name"
                                    [codeListValues]="customMRNCodelist"
                                    [automaticPopulateWithFirstValue]="true"></form-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    labelText="{{ 'Outturn Report' | translate }} {{ 'Number' | translate }}"
                                    formControlName="number"></form-textbox>
                            </div>
                            <div class="col">
                                <form-textbox
                                    labelText="{{ 'Internal Document Name' | translate }}"
                                    formControlName="name"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <form-textbox
                                    labelText="{{ 'Ship Representative' | translate }}"
                                    formControlName="vesselRepresentative"></form-textbox>
                            </div>
                            <div class="col-4">
                                <form-textbox
                                    labelText="{{ 'Terminal Representative' | translate }}"
                                    formControlName="terminalRepresentative"></form-textbox>
                            </div>
                            <div class="col-4">
                                <form-textbox
                                    labelText="{{ 'Customs Representative' | translate }}"
                                    formControlName="customsRepresentative"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <form-textbox
                                    labelText="{{ 'Remarks' | translate }}"
                                    formControlName="remark"></form-textbox>
                            </div>
                            <div *ngIf="!editMode" class="col-3">
                                <form-checkbox
                                    labelText="{{ 'Containers' | translate }}"
                                    formControlName="forContainers"></form-checkbox>
                            </div>
                        </div>
                        <div *ngIf="editMode" class="row">
                            <div class="col-6">
                                <form-datetime
                                    labelText="{{ 'Start of Cargo Operations' | translate }}"
                                    formControlName="operationsStartDate"></form-datetime>
                            </div>
                            <div class="col-6">
                                <form-datetime
                                    labelText="{{ 'End of Cargo Operations' | translate }}"
                                    formControlName="operationsEndDate"></form-datetime>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button
                class="btn btn-sm btn-primary"
                preventMultiClick
                [disabled]="!form.valid"
                (click)="saveGeneralAct()">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
                &nbsp;&nbsp;{{ 'Save' | translate }}
            </button>
            <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                &nbsp;&nbsp;{{ 'Close' | translate }}
            </button>
        </div>
    </ng-template>
</app-loader>
