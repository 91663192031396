import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../models/common/MenuItem';

@Injectable({
    providedIn: 'root'
})
export class NavigationFactoryService {
    constructor(private translateService: TranslateService) {}

    getMenuItems(): MenuItem[] {
        // id and parentCategoryId are useful to get all the modules and  submodules in HELP module for categories and subcategories
        const vesselVisit: MenuItem = {
            id: 1,
            title: this.translateService.instant(marker('Vessel')),
            icon: ['fas', 'ship'],
            childs: [
                {
                    id: 1,
                    parentCategoryId: 1,
                    title: this.translateService.instant(marker('Visits')),
                    icon: ['fas', 'list'],
                    route: '/vessel-visit/visit',
                    permissions: ['VesselVisit.VesselVisit.Search']
                },
                {
                    id: 3,
                    parentCategoryId: 1,
                    title: this.translateService.instant(marker('Delivery Orders')),
                    icon: ['fas', 'list'],
                    route: '/vessel-visit/delivery-order',
                    permissions: ['VesselVisit.DeliveryOrder.Search']
                },
                {
                    id: 4,
                    parentCategoryId: 1,
                    title: this.translateService.instant(marker('Schedule')),
                    icon: ['fas', 'list'],
                    route: '/vessel-schedule/arrivals-and-departures',
                    permissions: ['VesselVisit.Schedule.Search']
                },
                {
                    id: 5,
                    parentCategoryId: 1,
                    title: this.translateService.instant(marker('Registry')),
                    icon: ['fas', 'list'],
                    route: '/vessel',
                    permissions: ['Administration.Vessel.Search']
                }
            ],
            permissions: ['VesselVisit.VesselVisit.Search']
        };

        const Chat: MenuItem = {
            id: 2,
            title: this.translateService.instant(marker('Chat')),
            icon: ['fas', 'comments'],
            childs: [
                {
                    id: 1,
                    parentCategoryId: 2,
                    title: this.translateService.instant(marker('Chats')),
                    icon: ['fas', 'list'],
                    route: '/chat',
                    permissions: ['Chat.Chat.CreateAndSearch']
                }
            ],
            permissions: ['Chat.Chat.CreateAndSearch']
        };

        const containers: MenuItem = {
            id: 3,
            title: this.translateService.instant(marker('Cargo')),
            icon: ['fas', 'cubes'],
            childs: [
                {
                    id: 1,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Service Requests')),
                    icon: ['fas', 'tasks'],
                    route: '/tos-operations/service-request',
                    permissions: ['TosOperations.ServiceRequest.Search']
                },
                {
                    id: 2,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Shipment Pools')),
                    icon: ['fas', 'inbox'],
                    route: '/tos-operations/shipment-pool',
                    permissions: ['TosOperations.ShipmentPool.Search']
                },
                {
                    id: 3,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Storage Documents')),
                    icon: ['fas', 'file'],
                    route: '/tos-operations/storage-document',
                    permissions: ['TosOperations.StorageDocument.Search']
                },
                {
                    id: 4,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Amendment Documents')),
                    icon: ['fas', 'file-signature'],
                    route: '/tos-operations/amendment-document',
                    permissions: ['TosOperations.AmendmentDocument.Search']
                },
                {
                    id: 5,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Container Visits')),
                    icon: ['fas', 'cube'],
                    route: '/cargo-operations/container-visit-list',
                    permissions: ['CargoOperations.ContainerVisit.View']
                },
                {
                    id: 6,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Vehicle Visits')),
                    icon: ['fas', 'car'],
                    route: '/tos-operations/vehicle-visit',
                    permissions: ['CargoOperations.VehicleVisit.ViewAll']
                },
                {
                    id: 7,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Container Moves')),
                    icon: ['fas', 'exchange-alt'],
                    route: '/cargo-operations/container-move-list',
                    permissions: ['CargoOperations.ContainerMove.View']
                },
                {
                    id: 8,
                    parentCategoryId: 3,
                    title: this.translateService.instant(marker('Release Orders')),
                    icon: ['fas', 'lock-open'],
                    route: '/tos-operations/release-order',
                    permissions: ['CargoOperations.ReleaseOrder.ViewList']
                }
            ],
            permissions: ['TosOperations.ServiceRequest.Search']
        };

        // const navis: MenuItem = {
        //     title: this.translateService.instant(marker('NAVIS')),
        //     icon: ['fas', 'ship'],
        //     childs: [
        //         {
        //             title: this.translateService.instant(marker('Container Details')),
        //             icon: ['fas', 'cube'],
        //             route: '/navis/container-details/HASU1441820',
        //             permissions: ['Administration.Vessel.Search'],
        //         },
        //         {
        //             title: this.translateService.instant(marker('Vessel Visit Details')),
        //             icon: ['fas', 'ship'],
        //             route: '/navis/vessel-visit-details/TBS004-21',
        //             permissions: ['Administration.Vessel.Search'],
        //         },
        //         {
        //             title: this.translateService.instant(marker('Vessel Visit Inbound Containers')),
        //             icon: ['fas', 'cubes'],
        //             route: '/navis/container-list/TBS004-21/in',
        //             permissions: ['Administration.Vessel.Search'],
        //         },
        //         {
        //             title: this.translateService.instant(marker('Vessel Visit Outbound Containers')),
        //             icon: ['fas', 'cubes'],
        //             route: '/navis/container-list/TBS004-21/out',
        //             permissions: ['Administration.Vessel.Search'],
        //         },
        //     ],
        // };

        // const rollOperations: MenuItem = {
        //     title: this.translateService.instant(marker('Rail Operations')),
        //     icon: ['fas', 'train'],
        //     childs: [
        //         {
        //             title: this.translateService.instant(marker('WBOR List')),
        //             icon: ['fas', 'list'],
        //             route: '/rail-operations/wbor-list',
        //             permissions: [],
        //         }
        //     ]
        // };

        const customs: MenuItem = {
            id: 4,
            title: this.translateService.instant(marker('Customs')),
            icon: ['fas', 'archive'],
            childs: [
                // {
                //     title: this.translateService.instant(marker('DTS List')),
                //     icon: ['fas', 'list'],
                //     route: '/customs/dts-list',
                //     permissions: ['DTS.DTS.Search'],
                // },
                {
                    id: 1,
                    parentCategoryId: 4,
                    title: this.translateService.instant(marker('Customs Declarations')),
                    icon: ['far', 'file'],
                    route: '/customs/declaration-list',
                    permissions: ['Customs.CustomsDeclarationsList.Search']
                },
                {
                    id: 2,
                    parentCategoryId: 4,
                    title: this.translateService.instant(marker('Blocked Containers')),
                    icon: ['fas', 'cubes'],
                    route: '/customs/blocked-containers-list',
                    permissions: ['Customs.CustomsDeclarationsList.Search']
                }
            ],
            permissions: ['Customs.CustomsDeclarationsList.Search']
        };

        const pcsApprovals: MenuItem = {
            id: 5,
            title: this.translateService.instant(marker('Approvals')),
            icon: ['fas', 'thumbs-up'],
            childs: [
                {
                    id: 1,
                    parentCategoryId: 5,
                    title: this.translateService.instant(marker('Approvals')),
                    icon: ['fas', 'list'],
                    route: '/pcs-approvals/pcs-approval-list',
                    permissions: ['PcsApprovals.PcsApprovals.Search']
                }
            ],
            permissions: ['PcsApprovals.PcsApprovals.Search']
        };

        const administration: MenuItem = {
            id: 6,
            additionalInfo: 'Administration',
            title: this.translateService.instant(marker('Administration')),
            icon: ['fas', 'cogs'],
            childs: [
                {
                    id: 1,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Users')),
                    icon: ['fas', 'users'],
                    route: '/administration/user',
                    permissions: ['Administration.User.Search']
                },
                {
                    id: 2,
                    parentCategoryId: 6,
                    additionalInfo: 'Organizations',
                    title: this.translateService.instant(marker('Organizations')),
                    icon: ['fas', 'building'],
                    route: '/administration/organization',
                    permissions: ['Administration.Organization.Search']
                },
                {
                    id: 3,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Roles')),
                    icon: ['fas', 'key'],
                    route: '/administration/role',
                    permissions: ['Administration.Role.Search']
                },
                {
                    id: 4,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('CodeList Manager')),
                    icon: ['fas', 'landmark'],
                    route: '/administration/codelist-manager',
                    permissions: ['Administration.CodelistManager.Search']
                },
                {
                    id: 5,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('System Relations')),
                    icon: ['fas', 'list'],
                    route: '/administration/system-relations',
                    permissions: ['Administration.SystemRelations.View']
                },
                {
                    id: 6,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Customs Relations')),
                    icon: ['fas', 'list'],
                    route: '/administration/customs-relations',
                    permissions: ['Administration.SystemRelations.View']
                },
                {
                    id: 7,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('System Settings')),
                    icon: ['fas', 'cogs'],
                    route: '/administration/attachment-settings',
                    permissions: ['Administration.SystemSettings.Search']
                },
                {
                    id: 8,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Translations')),
                    icon: ['fas', 'language'],
                    route: '/administration/translation',
                    permissions: ['Administration.Translation.Search']
                },
                {
                    id: 9,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Help')),
                    icon: ['fas', 'question'],
                    route: '/administration/help',
                    permissions: ['Administration.Help.Search']
                },
                {
                    id: 10,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('AGL Logging')),
                    icon: ['fas', 'database'],
                    route: window.location.origin + '/agl',
                    permissions: ['Logging.Agl.View'],
                    type: 'link'
                },
                {
                    id: 11,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Audit Logs')),
                    icon: ['fas', 'list'],
                    route: '/administration/audit-logs',
                    permissions: ['Administration.AuditLogs.View']
                },
                {
                    id: 12,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Notice Board')),
                    // edit document icon
                    icon: ['fas', 'file-alt'],
                    route: '/administration/notification',
                    permissions: ['Administration.NoticeBoard.Search']
                },
                {
                    id: 13,
                    parentCategoryId: 6,
                    title: this.translateService.instant(marker('Messages')),
                    // edit document icon
                    icon: ['fas', 'comments'],
                    route: '/administration/message',
                    permissions: ['Administration.Messages.Search']
                }
            ],
            permissions: ['Administration.User.Search']
        };

        const reports: MenuItem = {
            title: this.translateService.instant(marker('Reports')),
            icon: ['fas', 'table'],
            childs: [
                {
                    title: this.translateService.instant(marker('Quick Reports')),
                    icon: ['fas', 'list'],
                    route: '/reports/lkc-quick-reports',
                    permissions: ['Reports.QuickLkcList.View']
                },
                {
                    title: this.translateService.instant(marker('Advanced Reports')),
                    icon: ['fas', 'list'],
                    route: '/reports',
                    permissions: ['Reports.ReportList.View']
                }
            ]
        };

        const help: MenuItem = {
            title: this.translateService.instant(marker('Help')),
            icon: ['fas', 'question'],
            childs: [
                {
                    title: this.translateService.instant(marker('FAQ')),
                    icon: ['fas', 'question'],
                    route: '/help/faq-list',
                    permissions: ['Help.Faq.View']
                },
                {
                    title: this.translateService.instant(marker('User Manuals')),
                    icon: ['fas', 'question'],
                    route: '/help/user-manuals-list',
                    permissions: ['Help.UserManuals.View']
                }
            ],
            permissions: ['Help.Help.Search']
        };

        const sidebar = [
            vesselVisit,
            Chat,
            containers,
            // navis,
            // rollOperations,
            customs,
            pcsApprovals,
            reports
        ];

        // admin and help always go last
        // sidebar.push(demo);
        sidebar.push(administration);
        sidebar.push(help);

        return sidebar;
    }
}
