import { Routes } from '@angular/router';
import { ErrorComponent } from '@common/components/error/error.component';
import { LoginOidcCallbackComponent } from '@common/components/login-oidc-callback/login-oidc-callback.component';
import { CertificateErrorComponent } from '@common/components/login/certificateError/certificate-error.component';
import { RegistrationFormDownloadComponent } from '@common/components/registration-form-download/registration-form-download.component';
import { SsoLoginComponent } from '@common/components/sso-login/sso-login.component';
import { AuthorizationGuard } from '@common/guards/authorization.guard';
import { LoginGuard } from '@common/guards/login.guard';
import { UserRegistrationFormDownloadGuard } from '@common/guards/user-registration-form-download.guard';
import { ForgotPasswordComponent } from './common/components/forgot-password/forgot-password.component';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/login/logout.component';
import { ResetPasswordComponent } from './common/components/reset-password/reset-password.component';
import { UserCreateComponent } from './common/components/user-create/user-create.component';
import { AuthGuard } from './common/guards/auth.guard';
import { UserCreateGuard } from './common/guards/user-create.guard';
import { HomeComponent } from './home.component';

export const ROUTES: Routes = [
    { path: 'error', component: ErrorComponent },
    { path: 'home', component: HomeComponent },
    { path: 'error/:errorCode', component: ErrorComponent },
    { path: 'login/oidc/callback', component: LoginOidcCallbackComponent },
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'certError', component: CertificateErrorComponent },
    { path: 'createUser', component: UserCreateComponent, canActivate: [UserCreateGuard] },
    {
        path: 'user-registration-form',
        component: RegistrationFormDownloadComponent,
        canActivate: [UserRegistrationFormDownloadGuard]
    },
    { path: 'forgotPassword', component: ForgotPasswordComponent },
    { path: 'resetPassword/:username/:code', component: ResetPasswordComponent },
    { path: 'sso-login', component: SsoLoginComponent },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthorizationGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            { path: 'logout', component: LogoutComponent },
            { path: 'logout/local', component: LogoutComponent },

            {
                path: 'administration',
                loadChildren: () =>
                    import('./administration/administration.module').then((m) => m.AdministrationModule),
                data: { title: 'Administration' } // TODO: gettext
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
                data: { title: 'Reports' } // TODO: gettext
            },
            {
                path: 'profile',
                loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
                data: { title: 'Profile' } // TODO: gettext
            },
            {
                path: 'vessel-visit',
                loadChildren: () => import('./vessel-visit/vessel-visit.module').then((m) => m.VesselVisitModule),
                data: { title: 'Vessel visit' } // TODO: gettext
            },
            {
                path: 'chat',
                loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
                data: { title: 'Chat' } // TODO: gettext
            },
            {
                path: 'vessel-notifications',
                loadChildren: () =>
                    import('./vessel-notifications/vessel-notifications.module').then(
                        (m) => m.VesselNotificationsModule
                    )
            },
            {
                path: 'vessel',
                loadChildren: () => import('./vessel/vessel.module').then((m) => m.VesselModule)
            },
            {
                path: 'tos-operations',
                loadChildren: () => import('./tos-operations/tos-operations.module').then((m) => m.TosOperationsModule),
                data: { title: 'Tos operations' }
            },
            {
                path: 'cargo-operations',
                loadChildren: () =>
                    import('./cargo-operations/cargo-operations.module').then((m) => m.CargoOperationsModule),
                data: { title: 'Cargo Operations' }
            },
            {
                path: 'navis',
                loadChildren: () => import('./navis/navis.module').then((m) => m.NavisModule),
                data: { title: 'NAVIS' }
            },
            {
                path: 'customs',
                loadChildren: () => import('./customs/customs.module').then((m) => m.CustomsModule),
                data: { title: 'Customs' }
            },
            {
                path: 'pcs-approvals',
                loadChildren: () => import('./pcs-approvals/pcs-approvals.module').then((m) => m.PcsApprovalsModule),
                data: { title: 'Approvals' }
            },
            {
                path: 'rail-operations',
                loadChildren: () =>
                    import('./rail-operations/rail-operations.module').then((m) => m.RailOperationsModule),
                data: { title: 'Customs' }
            },
            {
                path: 'vessel-schedule',
                loadChildren: () =>
                    import('./vessel-schedule/vessel-schedule.module').then((m) => m.VesselScheduleModule),
                data: { title: 'Vessel schedule' }
            },
            {
                path: 'help',
                loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
                data: { title: 'Help' }
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];
