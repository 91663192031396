import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContainerValidationService {
    charCode: string = '0123456789A?BCDEFGHIJK?LMNOPQRSTU?VWXYZ';

    constructor() {}

    isValid(containerCode: string) {
        // Trim and remove spaces
        containerCode = containerCode.trim().replace(' ', '');

        // Container code can not be an empty string or have a length other than 11
        if (containerCode == null || containerCode.length != 11) {
            return false;
        }

        let num = 0;

        // Convert characters to uppercase
        containerCode = containerCode.toUpperCase();

        for (let i = 0; i < 10; i++) {
            const chr = containerCode.substr(i, 1);

            // Ensure that the current character is in the valid alphabet
            let idx = chr == '?' ? -1 : this.charCode.indexOf(chr);
            if (idx < 0) {
                return false;
            }

            // Calculate power and convert to int
            idx = idx * Math.pow(2, i);
            num += idx;
        }

        num = (num % 11) % 10;

        // Return true if check digit equals num
        let isValid = false;

        try {
            isValid = parseInt(containerCode.substr(10, 1)) == num;
        } catch (error) {
            return throwError(error);
        }

        return isValid;
    }
}
