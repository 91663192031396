<div class="fixed-background login-page" id="imageDiv" [hidden]="!showBackgroundImage">
    <div class="login-wrap" [ngClass]="{ 'login-mobile-wrap': isMobile }">
        <div class="login-html" [ngClass]="{ 'login-html-size': isMobile }">
            <div class="row title" [ngClass]="{ 'login-image-padding': !isMobile }">
                {{ 'Continue Sign In' | translate }}
            </div>
            <app-loader [isBusy]="isBusy">
                <ng-template>
                    <div class="mt-3">
                        <div class="info-helper-text">
                            {{ 'Multiple users exist with same Certificate.' | translate }}
                        </div>
                        <div class="info-helper-text">
                            {{ 'Enter the details of the user you want to log in.' | translate }}
                        </div>
                    </div>

                    <div class="login-form">
                        <form [formGroup]="firstFactorForm" (ngSubmit)="initiateLogin()">
                            <div class="sign-in-htm" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <div class="group">
                                    <label class="label" for="user">{{ 'Username' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-user"></i></span>
                                        <form-textbox class="input-width" formControlName="username"></form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label class="label" for="pass">{{ 'Password' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox
                                            class="input-width"
                                            formControlName="password"
                                            [textboxType]="'password'"></form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <span>
                                        <input type="checkbox" formControlName="tac" #notification kendoCheckBox />
                                        {{ 'I have read and agree with the' | translate }}
                                        <a
                                            *ngIf="translateService.currentLang === 'en'"
                                            class="tac-checkbox"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="http://www.bgports.bg/en/page/78">
                                            {{ 'Terms & Conditions and the Privacy Policy' | translate }}
                                        </a>
                                        <a
                                            *ngIf="translateService.currentLang === 'bg'"
                                            class="tac-checkbox"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="http://www.bgports.bg/bg/page/78">
                                            {{ 'Terms & Conditions and the Privacy Policy' | translate }}
                                        </a>
                                    </span>
                                </div>

                                <div class="group">
                                    <button
                                        class="btn-md btn-primary btn-block button"
                                        type="submit"
                                        translate
                                        [disabled]="getIsFirstFactorDisabled">
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </app-loader>
        </div>
    </div>
</div>
