import { Component, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessageService } from '@common/services/validation-message.service';
import { betweenNumberValidator } from '@common/validators/between-number-validator';
import { AbstractFormControlComponent } from './abstract-form-control.component';

@Component({
    selector: 'form-number',
    styleUrls: ['form-controls.component.scss'],
    template: `
        <div class="form-group" [ngClass]="{ 'has-value': !!value }">
            <app-control
                [type]="'number'"
                [isDisabled]="isDisabled"
                [label]="labelText"
                [ngClass]="{ 'is-invalid': isInvalid }"
                [isInvalid]="isInvalid"
                [formControlValidators]="getFormControlValidators"
                [contextual]="contextual"
                [roundNumberToPlaces]="roundToPlaces"
                [decimal]="decimal"
                [forFilter]="forFilter"
                [(ngModel)]="value"
                (ngModelChange)="changeValue($event)"></app-control>
        </div>
    `,
    providers: []
})
export class NumberComponent extends AbstractFormControlComponent implements OnInit {
    @Input()
    labelText: string;
    @Input()
    contextual: string = null;
    @Input()
    roundToPlaces = 0;
    @Input() decimal: boolean = false;
    @Input() forFilter: boolean = false;

    constructor(
        @Self() @Optional() controlDir: NgControl,
        private elementRef: ElementRef,
        validationMessageService: ValidationMessageService
    ) {
        super(controlDir, validationMessageService);
    }

    ngOnInit() {
        // get all validators from the control
        const previousValidators = this.controlDir.control.validator;

        // adding betweenNumberValidator for all form-number controls
        const validators = [betweenNumberValidator()];

        // if validators already exist for the control, add them to the array
        if (previousValidators) {
            validators.push(previousValidators);
        }

        // add an array of validators to the control, because adding them one by one overwrites old ones
        this.controlDir.control.setValidators(validators);
        this.controlDir.control.updateValueAndValidity();
        if (this.decimal) {
            const parsedValue = parseFloat(this.value);
            if (!isNaN(parsedValue)) {
                this.value = parsedValue.toFixed(3);
            } else {
                this.value = null;
            }
        }
        this.checkIfInsideFilterContainer();
    }

    private checkIfInsideFilterContainer() {
        const element = this.elementRef.nativeElement;
        this.forFilter = this.findParentWithClass(element, 'filter-container');
    }

    private findParentWithClass(element: HTMLElement, className: string): boolean {
        if (!element) {
            return false;
        }
        if (element.classList.contains(className)) {
            return true;
        }
        return this.findParentWithClass(element.parentElement, className);
    }
}
