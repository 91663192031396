import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedBaseTab } from '@common/classes/extended-base-tab';
import { CustomsService } from '@common/services/customs.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CustomExtendedTabService } from 'app/singleton-services/custom-extended-tab.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-service-requests-declaration-tab',
    templateUrl: './service-requests-declaration-tab.component.html',
    styleUrls: ['./service-requests-declaration-tab.component.scss']
})
export class ServiceRequestsDeclarationTabComponent extends ExtendedBaseTab implements OnInit, OnChanges {
    @Input() mrnId: number;
    @Input() isModal = false;
    serviceRequests: any[] = [];
    isBusy = false;

    constructor(
        public activeModal: DialogRef,
        private toastyService: ToastrService,
        private router: Router,
        private service: CustomsService,
        customExtendedTabService: CustomExtendedTabService
    ) {
        super(customExtendedTabService);
    }

    ngOnInit() {
        this.isBusy = true;
        this.defaultPaging();
        if (this.serviceRequests) {
            this.getServiceRequests(this.mrnId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.serviceRequests && !changes.serviceRequests.firstChange) {
            this.loadItems(this.serviceRequests);
        }
    }

    getClass(status): string {
        status = status.toUpperCase();
        if (status === 'NEW') {
            return 'green-pill';
        } else if (status === 'CRT') {
            return 'yellow-pill';
        } else if (status === 'SNT') {
            return 'orange-pill';
        } else if (status === 'WAT') {
            return 'dark-blue-pill';
        } else if (status === 'APT') {
            return 'purple-pill';
        } else if (status === 'UPD') {
            return 'brown-pill';
        } else if (status === 'COM') {
            return 'black-pill';
        } else if (status === 'CAN') {
            return 'white-pill';
        } else if (status === 'DEC') {
            return 'red-pill';
        } else if (status === 'DLT') {
            return 'white-pill';
        } else if (status === 'INP') {
            return 'green-pill';
        }
    }

    navigateToServiceRequest(srId: number) {
        if (this.isModal) this.activeModal.close();
        this.router.navigate(['/tos-operations/service-request/land/edit', srId]);
    }

    private getServiceRequests(id: number) {
        this.service.getServiceRequestsForDeclaration(id).subscribe((response) => {
            this.serviceRequests = response;
            this.loadItems(this.serviceRequests);
            this.isBusy = false;
        });
    }
}
