import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CodelistDisplayModel } from '@common/models/common/CodelistDisplay.model';
import { ModalService } from '@common/services/modal.service';
import { UserService } from '@common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    selector: 'app-change-language-header',
    templateUrl: './change-language-header.component.html',
    styles: []
})
export class ChangeLanguageHeaderComponent implements OnInit {
    public title: string;
    currentLanguage: string;
    languages: CodelistDisplayModel[] = [];

    form: UntypedFormGroup = new UntypedFormGroup({
        languageId: new UntypedFormControl({ value: null, disabled: false })
    });

    constructor(
        public activeModal: DialogRef,
        private dialogService: ModalService,
        private translateService: TranslateService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.userService.getLanguages().subscribe((languages) => {
            this.languages = languages;
            this.currentLanguage = this.translateService.currentLang;
            this.form.get('languageId').patchValue(this.currentLanguage?.toUpperCase() == 'EN' ? 'BG' : 'EN');
        });
    }

    clear(data: any) {
        this.form.reset();
    }

    save() {
        const { languageId } = this.form.getRawValue();
        this.activeModal.close(languageId);
    }
}
