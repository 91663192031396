<div class="modal-header">
    <h4 class="modal-title">{{ title }}: {{ messageNumber }}</h4>
    <button class="close" type="button" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body scrollable-content">
    <h5>Error Content</h5>
    <div>
        <pre class="wrap-pre">{{ errorMessage }}</pre>
    </div>
    <h5 class="mt-4">Message Content</h5>
    <div>
        <pre class="wrap-pre">{{ messageData ? (messageData | prettyPrintXml) : '' }}</pre>
    </div>
</div>
<div class="modal-footer sticky-footer">
    <button
        *ngIf="canResend"
        class="btn btn-sm btn-primary"
        kendoTooltip
        title="{{ 'Resend' | translate }}"
        (click)="resend()">
        <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
    </button>
    <button
        class="btn btn-sm btn-secondary"
        kendoTooltip
        title="{{ 'Close' | translate }}"
        (click)="activeModal.close()">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </button>
</div>
