<nav class="sidebar sidebar-top-padding d-flex flex-column pb-0" [ngStyle]="{ width: getSidebarWidth() }">
    <ng-template ngFor let-item [ngForOf]="navigation">
        <ul class="nav nav-pills flex-column" [ngClass]="{ active: selectedItem === item || isActive(item.route) }">
            <li *ngIf="displaySidebarItem(item)" #anchor>
                <a
                    class="nav-section nav-link"
                    href="javascript:void(0)"
                    style="display: block"
                    (click)="selectItem(item)"
                    (mouseenter)="currentHover = item.title"
                    (mouseleave)="currentHover = null">
                    <div class="d-flex align-items-center">
                        <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
                        <span *ngIf="!isCollapsed" class="nav-title-padding">{{ item.title | translate }}</span>
                        <span
                            *ngIf="item.title === 'Approvals' && user?.pendingApprovals > 0"
                            class="approval-pill"
                            [hidden]="isCollapsed">
                            {{ user.pendingApprovals }}
                        </span>

                        <span class="pull-right text-muted ml-auto" [hidden]="isCollapsed">
                            <i
                                class="fa fa-caret-down"
                                [ngClass]="{
                                    'fa-caret-left': item === selectedItem,
                                    'fa-caret-down': item !== selectedItem
                                }"></i>
                        </span>
                    </div>

                    <!-- Red pill for user.pendingApprovals -->
                </a>

                <kendo-popup
                    *ngIf="currentHover && currentHover === item.title && isCollapsed"
                    [anchor]="anchor"
                    [copyAnchorStyles]="true"
                    [anchorAlign]="anchorAlign"
                    (anchorViewportLeave)="show = false">
                    <div class="px-2 py-2 h-100">
                        {{ item.title }}
                    </div>
                </kendo-popup>

                <ul class="nav nav-pills flex-column" [hidden]="isCollapsed || selectedItem !== item">
                    <ng-template ngFor let-child [ngForOf]="item.childs">
                        <li
                            *ngIf="hasPermission(child)"
                            class="nav-item pl-0"
                            [@openClose]="selectedItem ? 'open' : 'closed'"
                            [ngClass]="{ active: isActive(child.route) }">
                            <a class="nav-link" routerLinkActive="active-router-link" (click)="changeRoute(child)">
                                <fa-icon *ngIf="child.icon" [icon]="child.icon"></fa-icon>
                                <span *ngIf="child.iconText" class="textIcon">{{ child.iconText }}</span>
                                {{ child.title | translate }}
                                <i
                                    *ngIf="child.type === 'email' || child.type === 'link'"
                                    class="fa fa-arrow-right"></i>
                                <!-- <span class="badge badge-danger ml-2" *ngIf="child.route=='/chat'"><fa-icon [icon]="['fas', 'comment']"></fa-icon> 105</span> -->
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ul>
    </ng-template>
</nav>
