import { AbstractControl, ValidatorFn } from '@angular/forms';

export function regexValidator(regexExpression: string): ValidatorFn {
    const regTest = new RegExp(regexExpression);
    return (control: AbstractControl): { [key: string]: any } | null => {
        let valErr = control.errors;

        if (control.value == null || (control.value != null && !regTest.test(control.value))) {
            if (valErr == null) {
                valErr = {};
            }

            if (control.value != null) {
                valErr['regex'] = {
                    code: 'rfv08'
                };
            }
        } else {
            if (valErr && valErr['regex']) delete valErr['regex'];
            if (valErr && Object.keys(valErr).length == 0) valErr = null;
        }

        return valErr;
    };
}
