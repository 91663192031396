import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationDisplayModel } from './models/Notification.display.model';
import { NotificationListDisplayModel } from './models/NotificationList.display.model';
import { NotificationOnLoginDisplayModel } from './models/NotificationOnLogin.display.model';
import { SaveNotificationSaveModel } from './models/SaveNotification.save.model';

@Injectable({
    providedIn: 'root'
})
export class NoticeBoardService {
    constructor(private http: HttpClient) {}

    getNotificationList(filter: NotificationListDisplayModel): Observable<any> {
        return this.http.post<NotificationListDisplayModel[]>('api:///NoticeBoard/GetNotificationList', filter);
    }

    getNotificationsOnLogin(): Observable<any> {
        return this.http.get<NotificationOnLoginDisplayModel[]>('api:///NoticeBoard/GetNotificationsOnLogin');
    }

    getNotification(id: number): Observable<any> {
        return this.http.get<NotificationDisplayModel>(`api:///NoticeBoard/GetNotification/${id}`);
    }

    saveNotification(model: SaveNotificationSaveModel): Observable<any> {
        return this.http.post<number>('api:///NoticeBoard/SaveNotification', model);
    }

    archiveNotifications(ids: number[]): Observable<any> {
        return this.http.post('api:///NoticeBoard/ArchiveNotifications', ids);
    }
}
