<nav
    class="navbar fixed-top navbar-toggleable-md navbar-dark nav-shadow nav-background row"
    [ngClass]="{ 'navbar-mobile-height': isMobile }">
    <div class="col-4">
        <span class="toggle-menu mr-3" (click)="onToggleSidebar()">
            <i class="fa fa-bars" aria-hidden="true" [ngClass]="{ 'small-bar-icon-size': isMobile }"></i>
        </span>
        <a class="navbar-brand" routerLink="/home">
            <h5 [ngClass]="{ 'title-mobile-size': isMobile }">
                BulPCS&nbsp;&nbsp;
                <span *ngIf="displayBuildInfo" class="badge badge-light" [ngClass]="buildInfo">
                    {{ buildInfo | lowercase | translate }}
                </span>
            </h5>
        </a>
    </div>
    <div *ngIf="!user && notificationsCount" class="col-4 text-center">
        <div
            class="alert alert-primary alert-dismissible fade show"
            role="alert"
            (click)="openNotificationsGridModal()">
            <span class="align-middle">
                <fa-icon class="text-danger mr-2" [icon]="['fas', 'exclamation-circle']"></fa-icon>
                <strong>
                    <span class="d-none d-xl-inline">{{ 'You have' | translate }}</span>
                    {{ notificationsCount }}
                    <span class="d-none d-lg-inline">{{ 'important' | translate }}</span>
                    <span class="d-none d-sm-inline">
                        {{ (notificationsCount > 1 ? 'notices' : 'notice') | translate }}.
                    </span>
                </strong>
            </span>
            <button class="btn btn-xs btn-primary ml-2" type="button">
                {{ 'View' | translate }}
            </button>
        </div>
    </div>
    <div class="col-4 text-right">
        <div class="d-flex justify-content-end">
            <button
                class="btn btn-sm text-white"
                kendoTooltip
                title="{{ 'Toggle Dark Mode' | translate }}"
                (click)="toggleDarkMode()">
                <fa-icon *ngIf="!dark" [icon]="['fas', 'moon']"></fa-icon>
                <fa-icon *ngIf="dark" [icon]="['fas', 'sun']"></fa-icon>
            </button>
            <button
                class="btn btn-sm text-white"
                kendoTooltip
                title="{{ 'Change Language' | translate }}"
                (click)="changeLanguage()">
                <fa-icon [icon]="['fas', 'language']"></fa-icon>
            </button>
            <ng-container *ngIf="user">
                <kendo-dropdownbutton class="header-dropdown-button" [data]="profileMenuItems">
                    <span *ngIf="!isMobile" class="name">
                        <i class="fa fa-user-circle"></i>
                        &nbsp;&nbsp;{{ user.fullName ? user.fullName : user.userName }}
                    </span>
                    <span *ngIf="isMobile" class="name mx-1">
                        <i class="fa fa-user"></i>
                    </span>
                    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                        <a
                            *ngIf="dataItem === 'account'"
                            class="dropdown-item w-100"
                            translate
                            [routerLink]="['/profile']">
                            <i class="fa fa-user mr-2"></i>
                            Account
                        </a>
                        <a *ngIf="dataItem === 'sign-out'" class="dropdown-item w-100" translate (click)="logout()">
                            <i class="fa fa-sign-out mr-2" aria-hidden="true"></i>
                            Sign Out
                        </a>
                    </ng-template>
                </kendo-dropdownbutton>
            </ng-container>
        </div>
    </div>
</nav>
