import { Directive, Input, NgZone, ViewChild } from '@angular/core';
import { ModalService } from '@common/services/modal.service';
import { isMobile } from '@common/utils/isMobile';
import { TranslateService } from '@ngx-translate/core';
import {
    GridComponent,
    GridDataResult,
    PageChangeEvent,
    RowArgs,
    RowClassArgs,
    SelectableSettings,
    SelectAllCheckboxState
} from '@progress/kendo-angular-grid';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { CommonController } from 'app/common/classes/common.controller';
import { CustomExtendedTabService } from 'app/singleton-services/custom-extended-tab.service';
import { take } from 'rxjs';

/**
 *  ExtendedBaseTab component which extends CommonController to get access to user, hasPermissions and triggerDeactivationMessage
 *  Also enables selection
 */
@Directive()
export class ExtendedBaseTab extends CommonController {
    protected dialogService: ModalService;
    protected translateService: TranslateService;
    protected ngZone: NgZone;
    constructor(customExtendedTabService: CustomExtendedTabService) {
        super(customExtendedTabService.getDialogService(), customExtendedTabService.getTranslateService());
        this.dialogService = customExtendedTabService.getDialogService();
        this.translateService = customExtendedTabService.getTranslateService();
        this.ngZone = customExtendedTabService.getNgZone();

        if (isMobile()) {
            this.pagingOptions = { type: 'numeric', info: true, buttonCount: 3 };
        }
    }

    attachmentFileUploadRestrictions: FileRestrictions = {
        maxFileSize: 17825792,
        allowedExtensions: [
            '.pdf',
            '.jpg',
            '.png',
            '.csv',
            '.xls',
            '.txt',
            '.xlsx',
            '.doc',
            '.docx',
            '.jpeg',
            '.gif',
            '.bmp',
            '.psd',
            '.xml',
            '.xps',
            '.vsdx',
            '.ttf',
            '.ppt',
            '.pptx'
        ]
    };

    @Input() busyIndicator: boolean;
    @ViewChild(GridComponent)
    public grid: GridComponent;

    public isBusy: boolean = true;
    public itemPage: any;
    public itemsGridView: GridDataResult;
    public multiple = false;
    public allowUnsort = false;
    public pagingOptions: any = { type: 'numeric', info: true, buttonCount: 3, pageSizes: [10, 20, 50] };
    module: string;

    selectAllState: SelectAllCheckboxState = 'unchecked';

    selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: 'multiple'
    };
    selected: any[] = [];

    public sort: SortDescriptor[] = [
        {
            field: 'Id',
            dir: 'desc'
        }
    ];

    protected defaultPaging() {
        this.itemPage = {
            pageSize: 10,
            skip: 0
        };
    }

    protected loadItems(model: any) {
        const ordered = orderBy(model, this.sort);

        this.itemsGridView = {
            data: ordered.slice(this.itemPage.skip, this.itemPage.skip + this.itemPage.pageSize),
            total: model.length
        };
        this.isBusy = false;

        setTimeout(() => {
            if (this.grid && this.grid.columns) {
                this.fitColumns();
            }
        });
    }

    pageChange(event: PageChangeEvent, model: any): void {
        this.itemPage.skip = event.skip;
        this.itemPage.pageSize = event.take;
        this.loadItems(model);
    }

    sortChange(sort: SortDescriptor[], model: any): void {
        this.sort = sort;
        this.loadItems(model);
    }

    protected checkDuplicateItem(item: any, arrayToCheck: any[]) {
        let duplicateExists = false;
        arrayToCheck.forEach((element) => {
            if (element.id == item.id) {
                duplicateExists = true;
            }
        });
        return duplicateExists;
    }

    deletedRow(context: RowClassArgs) {
        if (!context?.dataItem?.active) return 'disabled-row';
    }

    clearSelected() {
        this.selectAllState = 'unchecked';
        this.selected = [];
    }

    onSelectAllChange(checkedState: SelectAllCheckboxState, model: any) {
        if (checkedState === 'checked') {
            this.selected = model.map((item) => item);
            this.selectAllState = 'checked';
        } else {
            this.selected = [];
            this.selectAllState = 'unchecked';
        }
    }

    // angular upgrade fix
    setSelectableSettings(): void {
        this.selectableSettings = {
            checkboxOnly: false,
            mode: 'multiple'
        };
    }

    selectionCallback(context: RowArgs) {
        return context.dataItem.id;
    }

    // check currently selected rows when switching pages
    isRowSelected = (e: RowArgs) => this.selected.indexOf(e.dataItem.id) >= 0;

    // angular upgrade fix
    keyChange(e: any) {
        this.selected = e;
    }

    public onDataStateChange(): void {
        this.fitColumns();
    }

    protected fitColumns(): void {
        this.ngZone.onStable
            .asObservable()
            .pipe(take(1))
            .subscribe(() => {
                this.grid.autoFitColumns();
            });
    }

}
