import { Component, OnInit } from '@angular/core';
import { UserModel } from '@common/models/User.model';
import { FileDownloadService } from '@common/services/file-download.service';
import { PcsDocumentAndApprovalServices } from '@common/services/pcs-document-and-approval-services.service';
import { currentUserSubject } from '@common/services/user.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-attachment-list-dialog',
    templateUrl: './attachment-list-dialog.component.html',
    styleUrls: ['./attachment-list-dialog.component.scss']
})
export class AttachmentListDialogComponent implements OnInit {
    user: UserModel;
    currentUser$: Subscription;
    public attachments: any[] = [];

    public pcsDocumentAndApprovalStatus: string;
    public pcsAttachments = [];
    public customsHoldAttachments = [];
    public faqDocumentAttachments = [];
    public notificationAttachments = [];
    public openedAttachmentId = null;
    public currentlyRemovingAttachmentId = -1;
    public removeAttachmentDialog = false;

    constructor(
        private fileDownloadService: FileDownloadService,
        private service: PcsDocumentAndApprovalServices,
        public activeModal: DialogRef
    ) {}

    ngOnInit(): void {
        this.customsHoldAttachments.length !== 0
            ? (this.attachments = this.customsHoldAttachments)
            : this.faqDocumentAttachments.length !== 0
            ? (this.attachments = this.faqDocumentAttachments)
            : (this.attachments = this.notificationAttachments);
    }

    public openRemoveAttachmentDialog(attachmentId: number) {
        this.removeAttachmentDialog = true;
        this.currentlyRemovingAttachmentId = attachmentId;
    }

    public closeRemoveAttachmentDialog() {
        this.removeAttachmentDialog = false;
        this.currentlyRemovingAttachmentId = -1;
    }

    attachmentDownload(id: number): void {
        this.service.downloadAttachment(id).subscribe((response) => {
            this.fileDownloadService.handleAttachmentDownload(response);
        });
    }

    submitRemoveAttachment() {
        this.service
            .removeAttachment({
                isForApproval: false,
                attachmentId: this.currentlyRemovingAttachmentId
            })
            .subscribe(() => {
                this.pcsAttachments = this.pcsAttachments.filter((x) => x.id !== this.currentlyRemovingAttachmentId);
                this.removeAttachmentDialog = false;
                this.activeModal.close('deleted');
            });
    }

    belongsToOrganization(organization: any): boolean {
        const user = currentUserSubject.getValue();
        if (!user || !organization) {
            return false;
        }
        return user.belongsToOrganization(organization);
    }

    downloadAttachment(data: any) {
        if (data) {
            this.fileDownloadService.handleAttachmentDownload(data);
        }
    }
}
