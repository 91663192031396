import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CodelistService } from '@common/services/codelist.service';
import { DeliveryOrderService } from '@common/services/delivery-order.service';
import { ModalService } from '@common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { DeliveryOrderListDisplayModel } from 'app/vessel-visit/delivery-order-list/models/DeliveryOrders.display.model';
import { ToastrService } from 'ngx-toastr';
import { filter, Observable } from 'rxjs';

@Component({
    selector: 'app-edit-delivery-order',
    templateUrl: './edit-delivery-order.component.html',
    styleUrls: ['./edit-delivery-order.component.scss']
})
export class EditDeliveryOrderComponent implements OnInit, AfterViewInit, AfterContentChecked {
    isBusy: boolean;
    deliveryOrderModel: DeliveryOrderListDisplayModel = new DeliveryOrderListDisplayModel();
    form: UntypedFormGroup;
    deliveryOrderId = 0;
    vesselVisitId = 0;
    portFacilityTerminalId: string;
    portId: string;
    berthId: string;
    vesselMaster: string;
    terminals$: Observable<any>;
    terminalOperators$: Observable<any>;
    berths$: Observable<any>;
    systems$: Observable<any>;
    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public service: DeliveryOrderService,
        private cdr: ChangeDetectorRef,
        public codelistService: CodelistService
    ) {}

    ngOnInit() {
        this.form = this.deliveryOrderModel.generateGroup();
        this.form.get('id').setValue(this.deliveryOrderId);
    }

    ngAfterViewInit() {
        this.trackFormChanges();
        this.cdr.detectChanges();
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    addNewDeliveryOrder() {
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Confirm')) as string,
                this.translateService.instant(
                    marker('Are you sure you want to add the delivery order item?')
                ) as string,
                this.translateService.instant(marker('YES')) as string,
                this.translateService.instant(marker('No')) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((res) => {
                const body = { vesselVisitId: this.vesselVisitId, ...this.form.getRawValue() };
                this.service.addDeliveryOrder(body).subscribe(() => {
                    this.toastyService.success(this.translateService.instant(marker('Delivery order created')));
                    this.activeModal.close(body);
                });
            });
    }

    private trackFormChanges() {
        if (this.portFacilityTerminalId) this.form.get('terminalId').setValue(this.portFacilityTerminalId);
        this.terminals$ = this.service.getTerminalsWithinSamePort(this.portId);
        this.form.get('terminalId').valueChanges.subscribe((res) => {
            this.terminalOperators$ = this.service.getTerminalOperatorsBasedOnTerminal(res);
            if (res && res.length === 1) {
                this.form.get('terminalOperatorId').setValue(res[0].id);
            }

            this.berths$ = this.service.getBerthsBasedOnTerminal(res);
        });
        if (this.vesselMaster) this.form.get('vesselMaster').setValue(this.vesselMaster);
        if (this.berthId) this.form.get('berthId').setValue(this.berthId);
    }
}
