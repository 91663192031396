import { AbstractControl, ValidatorFn } from '@angular/forms';

export function maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let valErr = control.errors;

        if (control.value == null || (control.value != null && control.value.length > maxLength)) {
            if (valErr == null) {
                valErr = {};
            }

            if (control.value != null) {
                const enteredChars = control.value.length;

                valErr['maxLength'] = {
                    code: 'rfv03',
                    maxLengthCharacters: maxLength,
                    enteredCharacters: enteredChars
                };
            }
        } else {
            if (valErr && valErr['maxLength']) delete valErr['maxLength'];
            if (valErr && Object.keys(valErr).length == 0) valErr = null;
        }

        return valErr;
    };
}
