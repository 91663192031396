<div class="row mt-5 text-center">
    <div class="col"></div>
    <div class="col-auto">
        <h2>
            <fa-icon class="text-danger mr-2" [icon]="['fas', 'exclamation-circle']" [size]="'2x'"></fa-icon>
            <br />
            <br />
            <span>{{ 'Error in certificate authentication' | translate }}</span>
        </h2>
        <br />
        <br />
        <br />
        <h4 translate>
            If you selected an incorrect certificate, please close you browser and try again to select correct one.
            <br />
            <br />
            If you were never prompted for a certificate, make sure you have the correct one installed.
            <br />
            <br />
            For any other issue, please contact customer support.
        </h4>
        <br />
        <p>
            <a class="btn btn-sm btn-primary" href="" (click)="reload()">{{ 'Reload' | translate }}</a>
        </p>
    </div>
    <div class="col"></div>
</div>
