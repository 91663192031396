import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@common/services/error.service';
import { ModalService } from '@common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private toastyService: ToastrService,
        private translateService: TranslateService,
        private dialogService: ModalService,
        private errorService: ErrorService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // exclude upload excel request from error handling (the request is handled in the component)
                if (['UploadExcelOnServiceRequest', 'UploadManifest'].some((x) => request.url.includes(x)))
                    return throwError(() => error);
                if (error instanceof HttpErrorResponse) {
                    // server error
                    if (error.status === 500) {
                        if (error.error?.isPcsException) {
                            this.toastyService.error(error.error.errorMessage);
                            if (!environment.production) console.error(error);
                            return throwError(() => error);
                        }
                        this.dialogService.showErrorDialog(error);
                    }
                    // for validation errors
                    if (
                        error.error?.status === 400 &&
                        error.error?.title.includes('One or more validation errors occurred.')
                    ) {
                        if (error.error?.errors) {
                            const errors = error.error.errors;
                            for (const key in errors) {
                                const element = errors[key];
                                this.toastyService.error(element);
                            }
                        } else {
                            this.toastyService.error(error.error.errorMessage);
                        }
                        if (!environment.production) console.error(error);
                    }
                    // not found
                    if (error.status === 404) {
                        this.toastyService.error(error.error?.errorMessage);
                        if (!environment.production) console.error(error);
                        this.router.navigate(['/home']);
                        return of(new HttpResponse({ status: 404 }));
                    }

                    if (error.status === 403) {
                        const message = this.translateService.instant(
                            'Access to this page is restricted. Please contact your administrator if you believe you should have access to this page'
                        );
                        const title = this.translateService.instant('Restricted Access');
                        this.toastyService.error(message, title);

                        if (!environment.production) console.error(error);
                        this.router.navigate(['/home']);
                        return of(new HttpResponse({ status: 403 }));
                    }

                    // intercept refresh token expire error
                    if (error?.error?.error === 'invalid_grant') {
                        window.location.reload();
                    }

                    if (error.status == 401 && error.url.indexOf('authentication/login') != -1) {
                        if (error?.error?.errorMessage.includes('BEA')) {
                            const errorMessages = this.errorService.getServerMessage(error);
                            this.errorService.displayErrorInToasty(errorMessages);
                            if (!environment.production) console.error(error);
                        }
                        return throwError(() => error);
                    }

                    // unaouthorized but not token expired and not current user
                    // expired tokens are handled in http-interceptor
                    // current user is checked in startup
                    if (
                        error.status == 401 &&
                        !error.headers.has('Token-Expired') &&
                        error.url.indexOf('authentication/getCurrentUser') == -1
                    ) {
                        // reload app, it will check user again
                        window.location.reload();
                    }
                }
                return throwError(() => error);
            })
        );
    }
}
