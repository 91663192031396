import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from './services/error.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private injector: Injector,
        private translateService: TranslateService
    ) {}

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);

        let errorMessages: string[];

        if (!(error instanceof HttpErrorResponse)) {
            //  Client errors. Server errors are handled in the ServerErrorInterceptor
            if (isDevMode()) {
                console.error(error);
            }
            const title = this.translateService.instant(marker('Unexpected error:'));
            errorMessages = errorService.getClientMessage(error);
            if (errorMessages[0].includes('NG')) {
                errorMessages = errorMessages.map((errorMessage) =>
                    errorMessage.replace(/^NG\d+: /, '').replace(/Find more at .+$/, '')
                );
            }

            errorService.displayErrorInToasty(errorMessages, title);
        }
    }
}
