import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserModel } from '@common/models/User.model';
import { currentUserSubject } from '../services/user.service';

@Injectable()
export class LoginGuard implements CanActivate {
    private currentUser: UserModel;

    constructor(private router: Router) {
        currentUserSubject.subscribe((user) => {
            this.currentUser = user;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // status - no user available
        const status = !this.currentUser;

        if (!status) {
            const returnUrl = route.queryParams.returnUrl;
            if (returnUrl) {
                this.router.navigate([returnUrl]);
            } else {
                this.router.navigate(['/']);
            }
        }

        return status;
    }
}
