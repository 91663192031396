import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Directive({
    selector: '[preventMultiClick]'
})
export class PreventMultiClickDirective {
    private clickSubject = new Subject<void>();

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if (this.el.nativeElement.disabled) {
            event.preventDefault();
            return;
        }

        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        this.clickSubject.next();

        // Enable the button after a certain delay (e.g., 2 seconds)
        setTimeout(() => {
            this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        }, 2000); // Adjust the delay as needed

        this.clickSubject
            .pipe(debounceTime(2000)) // Adjust the debounce time to match the delay
            .subscribe(() => {
                this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
            });
    }
}
