import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
    selector: '[stripAllWhitespace]'
})
export class StripAllWhitespaceDirective implements AfterViewInit, OnDestroy {
    @Input() public trimEventName: string = 'input';
    obs: Subscription;

    constructor(
        private elementRef: ElementRef,
        @Self() private ngControl: NgControl
    ) {}

    ngOnDestroy(): void {
        this.obs.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.obs = fromEvent(this.elementRef.nativeElement.querySelector('input'), this.trimEventName).subscribe(() => {
            const currentValue: string = this.ngControl.value.toString();
            const newValue = currentValue.replace(/\s/g, '');
            if (currentValue != newValue) {
                this.ngControl.control.patchValue(newValue);
            }
        });
    }
}
