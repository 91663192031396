export const getUrlQueryParams = () => {
    const search = window.location.search;
    if (search != null && search.length > 0 && search.startsWith('?')) {
        const params = {};
        let len = 0;
        search
            .substring(1)
            .split('&')
            .filter((x) => {
                const keyVal = x.split('=');
                return keyVal.length >= 2 && keyVal[0] != '' && keyVal[1] != '';
            })
            .forEach((x) => {
                const keyVal = x.split('=');
                const key = decodeURIComponent(keyVal[0]);
                const val = decodeURIComponent(keyVal[1]);
                params[key] = val;
                len++;
            });

        return len > 0 ? params : null;
    }
    return null;
};
