import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NominatedAgentsSaveOrganizationsSaveModel } from '../../vessel-visit/vessel-visit-list/models/NominatedAgents/NominatedAgentsSaveOrganizations.save.model';

@Injectable({
    providedIn: 'root'
})
export class NominatedAgentService {
    constructor(private http: HttpClient) {}

    // nominated agents
    getNominatedAgents(filter: any) {
        return this.http.post<any>(`api:///NominatedAgent/GetNominatedAgents`, filter);
    }

    getAddCargoAgents(id: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetAddCargoAgents/${id}`);
    }

    getCargoAgentsForNomination(
        vesselVisitId: number,
        direction: string,
        relationOrganizationToOrganizationTypeId: number
    ) {
        return this.http.get<any>(
            `api:///NominatedAgent/GetCargoAgentsForNomination/${vesselVisitId}/${direction}/${relationOrganizationToOrganizationTypeId}`
        );
    }

    getCodeListsForNominatedAgentsGrid(vesselVisitId: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetCodeListsForNominatedAgentsGrid/${vesselVisitId}`);
    }

    AddNominatedAgentOrganizations(model: NominatedAgentsSaveOrganizationsSaveModel) {
        return this.http.post<any>(`api:///NominatedAgent/AddNominatedAgentOrganizations`, model);
    }

    RemoveNominatedAgents(model: any) {
        return this.http.post(`api:///NominatedAgent/RemoveNominatedAgents`, model);
    }

    // shipping lines
    getShippingLines(nominatedAgentId: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetShippingLines/${nominatedAgentId}`);
    }

    getCodeListsForShippingLines(nominatedAgentId: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetCodeListsForShippingLines/${nominatedAgentId}`);
    }

    addNewShippingLines(model: any) {
        return this.http.post<any>(`api:///NominatedAgent/AddNewShippingLines`, model);
    }

    removeShippingLines(model: any) {
        return this.http.post<any>(`api:///NominatedAgent/RemoveShippingLines`, model);
    }

    // non containerized cargo
    getCodeListsForNonContainerized() {
        // uses the same filters as nominated agents
        return this.http.get<any>(`api:///NominatedAgent/GetCodeListsForNominatedAgentsGrid`);
    }

    getNonContainerized(vesselVisitId: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetNonContainerized/${vesselVisitId}`);
    }

    saveTosVoyage(model: any) {
        return this.http.post<any>(`api:///NominatedAgent/SaveTosVoyage`, model);
    }

    // Cargo List
    getCargoManifest(cargoManifestId) {
        return this.http.get<any>(`api:///NominatedAgent/GetCargoManifest/${cargoManifestId}`);
    }

    getOrganizationsForCargoManifest(cargoManifestId) {
        return this.http.get<any>(`api:///NominatedAgent/GetOrganizationsForCargoManifest/${cargoManifestId}`);
    }

    addCargoManifestAttachment(model: any) {
        return this.http.post<any>(`api:///NominatedAgent/AddCargoManifestAttachment`, model);
    }

    removeCargoManifestAttachment(model: any) {
        return this.http.post<any>(`api:///NominatedAgent/RemoveCargoManifestAttachment`, model);
    }

    downloadCargoManifestAttachment(attachmentId: number) {
        return this.http.get<any>(`api:///NominatedAgent/DownloadCargoManifestAttachment/${attachmentId}`);
    }

    getCargoManifestItems(manifestId: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetManifestRows/${manifestId}`);
    }

    getCargoManifestInvalidItems(manifestId: number) {
        return this.http.get<any>(`api:///NominatedAgent/GetManifestInvalidRows/${manifestId}`);
    }

    saveCargoManifestItem(model: any) {
        return this.http.post<any>(`api:///NominatedAgent/SaveCargoManifestItem`, model);
    }

    getCodeListsForManifestItems(id) {
        return this.http.get<any>(`api:///NominatedAgent/ManifestItemsCodeListValues/${id}`);
    }

    removeManifestRow(id) {
        return this.http.post<any>(`api:///NominatedAgent/RemoveManifestItemRow`, id);
    }

    approveCargoManifest(id) {
        return this.http.get(`api:///NominatedAgent/ApproveCargoManifest/${id}`);
    }

    finishCargoList(id) {
        return this.http.get(`api:///NominatedAgent/FinishCargoList/${id}`);
    }

    getServiceRequestsForCargoManifest(vesselVisitId: number, cargoManifestId: number, forContainers: boolean) {
        return this.http.get<any>(
            `api:///NominatedAgent/ServiceRequestsForCargoManifest/${vesselVisitId}/${cargoManifestId}/${forContainers}`
        );
    }
}
