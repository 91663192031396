import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralActSaveModel } from 'app/vessel-visit/general-act/models/GeneralAct.save.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GeneralActService {
    constructor(private http: HttpClient) {}

    getSingleGeneralAct(generalActId: number): Observable<any> {
        return this.http.get<any>(`api:///GeneralAct/GetSingleGeneralAct/${generalActId}`);
    }

    getVesselVisitGeneralActList(vesselVisitId: number): Observable<any> {
        return this.http.get<any>(`api:///GeneralAct/GetVesselVisitGeneralActList/${vesselVisitId}`);
    }

    lockGeneralAct(generalActId: number, remarks: string, generalActItems: any[]) {
        return this.http.post(`api:///GeneralAct/LockGeneralAct`, { generalActId, remarks, generalActItems });
    }

    cancelGeneralAct(generalActId: number, remarks: string) {
        return this.http.post(`api:///GeneralAct/CancelGeneralAct`, { generalActId, remarks });
    }

    printGeneralAct(generalActId: number) {
        return this.http.post(`api:///GeneralAct/PrintGeneralAct`, { generalActId });
    }

    saveNoticeActRemark(noticeActId: number, remark: string) {
        return this.http.post(`api:///GeneralAct/SaveNoticeActRemark`, { noticeActId, remark });
    }

    getGeneralActServiceRequests(generalActId: number): Observable<any> {
        return this.http.get<any>(`api:///GeneralAct/GetGeneralActServiceRequests/${generalActId}`);
    }

    getGeneralActNoticeReceipts(generalActId: number): Observable<any> {
        return this.http.get<any>(`api:///GeneralAct/GetGeneralActNoticeReceipts/${generalActId}`);
    }

    checkIfVesselVisitIsForContainers(
        vesselVisitId: number,
        relationSystemToOrganizationToTerminalId: number
    ): Observable<any> {
        return this.http.get<any>(
            `api:///GeneralAct/CheckIfVesselVisitIsForContainers/${vesselVisitId}/${relationSystemToOrganizationToTerminalId}`
        );
    }

    addAttachment(model: any) {
        return this.http.post<any>(`api:///GeneralAct/AddAttachment`, model);
    }

    removeAttachment(model: any) {
        return this.http.post<any>(`api:///GeneralAct/RemoveAttachment`, model);
    }

    getTerminalOptions(vesselVisitId: number) {
        return this.http.get(`api:///GeneralAct/GetTerminalOptions/${vesselVisitId}`);
    }

    getGeneralActCustomMRNCodelist(vesselVisitId: number) {
        return this.http.get(`api:///GeneralAct/GetGeneralActCustomMRNCodelist/${vesselVisitId}`);
    }

    saveGeneralAct(data: GeneralActSaveModel) {
        return this.http.post(`api:///GeneralAct/SaveGeneralAct`, data);
    }

    getVesselAgents(): any {
        return this.http.get(`api:///GeneralAct/GetVesselAgents/`);
    }
}
