<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button class="close" type="button" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row mt-1">
        <div class="col">
            <h5>Entity Data</h5>
            <ngx-json-viewer [json]="auditLog.entityJSON"></ngx-json-viewer>
        </div>
        <div class="col mr-2 mt-2">
            <ng-container *ngIf="auditLog.originalValues">
                <h5>Original values</h5>
                <div class="row mb-3">
                    <ngx-json-viewer [json]="auditLog.originalValues"></ngx-json-viewer>
                </div>
            </ng-container>
            <ng-container *ngIf="auditLog.newValues">
                <h5>Modified Values</h5>
                <div class="row mb-3">
                    <ngx-json-viewer [json]="auditLog.newValues"></ngx-json-viewer>
                </div>
            </ng-container>
            <ng-container *ngIf="auditLog.changedCollections">
                <h5>Modified Collections</h5>
                <div class="row">
                    <ngx-json-viewer [json]="auditLog.changedCollections"></ngx-json-viewer>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-primary" translate (click)="activeModal.close()">Close</button>
</div>
