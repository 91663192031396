import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseDisplayModel } from '@common/models/baseDisplayModel';

export class DeliveryOrderItemsDisplayModel extends BaseDisplayModel {
    id: number;
    name: string;
    description: string;
    quantity: number;
    grossWeight: number;
    netWeight: number;
    volume: number;
    realizedgrossWeight: number;
    realizednetWeight: number;
    realizedvolume: number;
    realizedQuantity: number;
    deliveryOrderId: number;

    constructor(data: DeliveryOrderItemsDisplayModel = null) {
        super();

        if (data) {
            Object.assign(this, data);
        }
    }

    generateGroup() {
        const form = new UntypedFormGroup({
            name: new UntypedFormControl({ value: null, disabled: false }),
            description: new UntypedFormControl({ value: null, disabled: false }),
            quantity: new UntypedFormControl({ value: null, disabled: false }),
            grossWeight: new UntypedFormControl({ value: null, disabled: false }),
            netWeight: new UntypedFormControl({ value: null, disabled: false }),
            volume: new UntypedFormControl({ value: null, disabled: false })
        });
        return form;
    }
}
