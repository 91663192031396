<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>

    <button class="close" type="button" aria-label="Close" [disabled]="isBusy" (click)="activeModal.close(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div>
    <kendo-fileselect
        [restrictions]="attachmentFileUploadRestrictions"
        [multiple]="true"
        (select)="fileSelected($event)"
        (remove)="fileRemoved($event)"></kendo-fileselect>
</div>

<div class="modal-footer">
    <button *ngIf="files.length > 0" class="btn btn-sm btn-primary" [disabled]="isBusy" (click)="finish()">
        {{ yesButtonName }}
    </button>
    <button class="btn btn-sm btn-secondary" translate [disabled]="isBusy" (click)="activeModal.close(null)">
        Cancel
    </button>
</div>
