import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InstanceTrackerService {
    private storageKey = 'appInstanceCount';
    private sessionKey = 'appSessionInstance';

    constructor() {
        if (!localStorage.getItem(this.storageKey)) {
            localStorage.setItem(this.storageKey, '0');
        }
        if (!sessionStorage.getItem(this.sessionKey)) {
            sessionStorage.setItem(this.sessionKey, '0');
        }
    }

    register(): number {
        if (sessionStorage.getItem(this.sessionKey) === '0') {
            let count = parseInt(localStorage.getItem(this.storageKey)!, 10);
            count++;
            localStorage.setItem(this.storageKey, count.toString());
            sessionStorage.setItem(this.sessionKey, '1');
        }
        return this.getInstanceCount();
    }

    unregister(): number {
        if (sessionStorage.getItem(this.sessionKey) === '1') {
            let count = parseInt(localStorage.getItem(this.storageKey)!, 10);
            count = Math.max(0, count - 1);
            localStorage.setItem(this.storageKey, count.toString());
            sessionStorage.setItem(this.sessionKey, '0');
        }
        return this.getInstanceCount();
    }

    getInstanceCount(): number {
        return parseInt(localStorage.getItem(this.storageKey)!, 10);
    }
}
