export enum ViewMode {
    view = 'view',
    edit = 'edit',
    create = 'create',
    clone = 'clone',
    print = 'print',
    use = 'use',
    without = 'without',
    saveAndLinkMotCreate = 'save&linkMotCreate',
    saveAndLinkMotUse = 'save&linkMotUse'
}
