import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AglCategoryTypes } from '@common/knownTypes/AglCategoryTypes';
import { UserModel } from '@common/models/User.model';
import { AglLoggingService } from '@common/services/agl-logging.service';
import { ModalService } from '@common/services/modal.service';
import { currentUserSubject } from '@common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationGuard implements CanActivateChild {
    user: UserModel;
    static storageEvenetAdded = false;
    static userIdleStarted = false;
    static logoutModalShown = false;

    constructor(
        private router: Router,
        private location: Location,
        private toastyService: ToastrService,
        private aglLoggingService: AglLoggingService,
        private dialogService: ModalService,
        private translateService: TranslateService,
        private userIdle: UserIdleService
    ) {
        currentUserSubject.subscribe((user) => {
            this.user = user;
        });
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.checkLogoutState();

        if (!AuthorizationGuard.storageEvenetAdded) {
            window.addEventListener('storage', (e) => {
                this.handleStorageChange(e);
            });
            AuthorizationGuard.storageEvenetAdded = true;
        }

        if (!AuthorizationGuard.userIdleStarted) {
            AuthorizationGuard.userIdleStarted = true;
            this.userIdle.startWatching();
            let loggedOutDueInactitvity: boolean = false;
            this.userIdle.onTimerStart().subscribe(() => {
                this.userIdle.resetTimer();
                this.userIdle.stopWatching();
                if (!loggedOutDueInactitvity) {
                    loggedOutDueInactitvity = true;
                    this.performLogout();
                }
            });
        }

        if (!this.user) return false; // in theory this should never happen

        if (childRoute.data.permission && !this.user.hasPermission(childRoute.data.permission)) {
            this.logAndNotifyUnauthorizedAccess(state);
            return false;
        }

        if (childRoute.data.permissions && !this.user.hasAtLeastOnePermission(childRoute.data.permissions)) {
            this.logAndNotifyUnauthorizedAccess(state);
            return false;
        }

        return true;
    }

    logAndNotifyUnauthorizedAccess(state: RouterStateSnapshot) {
        const pastedOrReturnUrl =
            !this.router.routerState.snapshot.url || this.router.routerState.snapshot.url.includes('login?returnUrl');

        this.toastyService.error('Error: ' + 'Unauthorized access');

        this.aglLoggingService.logAglUserAction(
            `Unauthorized navigation`,
            `${state.url}`,
            '',
            '',
            AglCategoryTypes.AuthorizationError
        );

        if (pastedOrReturnUrl) this.router.navigate(['/home']);
    }

    // fix for opening app in new tab or window, after timeout in another tab or window
    checkLogoutState() {
        let logoutState = window.localStorage.getItem('loggedout');
        if (logoutState !== null && logoutState === 'true') {
            this.performLogout();
        }
    }

    handleStorageChange(event): void {
        if (event.key == 'loggedout') {
            if (event.newValue == 'true') {
                this.performLogout();
            }
        }
    }

    private performLogout() {
        if (!AuthorizationGuard.logoutModalShown) {
            AuthorizationGuard.logoutModalShown = true;
            window.localStorage.setItem('loggedout', 'true');
            const dialogElements = document.getElementsByClassName('k-dialog-wrapper');
            Array.from(dialogElements).forEach((el) => {
                el.setAttribute('style', 'display:none');
            });

            this.dialogService
                .confirm(
                    this.translateService.instant(marker('Sign Out')) as string,
                    this.translateService.instant(marker('You have been logged out due to inactivity.')) as string,
                    this.translateService.instant(marker('Click here to log in again.')) as string,
                    null
                )
                .pipe()
                .subscribe(() => {
                    this.router.navigate(['/logout']);
                });
        }
    }
}
