<div class="modal-header">
    <h4 class="modal-title">{{ 'Notice Board' | translate }}</h4>
</div>
<div class="modal-body">
    <kendo-grid
        [data]="itemsGridView"
        [skip]="itemPage.skip"
        [pageSize]="itemPage.pageSize"
        [pageable]="pagingOptions"
        [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
        [loading]="isBusy"
        [sort]="sort"
        [scrollable]="'true'"
        [resizable]="true"
        (pageChange)="pageChange($event, notifications)"
        (sortChange)="sortChange($event, notifications)">
        <kendo-grid-column title="{{ 'Title' | translate }}" [width]="300">
            <ng-template kendoGridCellTemplate let-row>
                <div class="font-weight-bold" kendoTooltip title="{{ row.title }}">
                    <span>{{ row.title }}</span>
                    <span *ngIf="row.titleEN">&nbsp;/&nbsp;{{ row.titleEN }}</span>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="{{ 'Created Date' | translate }}" field="createdDate" [width]="130">
            <ng-template kendoGridCellTemplate let-row>
                {{ row.createdDate | date: 'dd.MM.yyyy HH:mm' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
            title="{{ 'Attachments' | translate }}"
            field="notificationAttachments"
            [width]="50"
            [style]="{ 'text-align': 'center' }">
            <ng-template kendoGridCellTemplate let-row>
                <button
                    *ngIf="row.notificationAttachments?.length > 0"
                    class="btn btn-sm btn-primary"
                    kendoTooltip
                    title="{{ 'Attachments' | translate }}"
                    (click)="openAttachment(row.id, row.notificationAttachments)">
                    <fa-icon [icon]="['fas', 'paperclip']"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridDetailTemplate let-dataItem [initiallyExpanded]="true">
            <div class="row pl-3">
                <div class="col-xl-6 long-text-cell" [innerHTML]="dataItem.content"></div>
                <div class="col-12 d-xl-none"><hr /></div>
                <div class="col-xl-6 long-text-cell" [innerHTML]="dataItem.contentEN"></div>
            </div>
        </ng-template>
        <ng-template kendoGridNoRecordsTemplate>
            <p>{{ 'No records available' | translate }}.</p>
        </ng-template>
    </kendo-grid>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-primary" (click)="activeModal.close(false)">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
        &nbsp;&nbsp;{{ 'Close' | translate }}
    </button>
</div>
