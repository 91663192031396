import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor(
        private toastyService: ToastrService,
        private translateService: TranslateService
    ) {}

    getClientMessage(error: any) {
        const errorArray: string[] = [];
        errorArray.push(`${error?.message}`);
        return errorArray;
    }

    getServerMessage(httpErrorResponse: HttpErrorResponse) {
        const errorArray: string[] = [];

        if (httpErrorResponse.url.endsWith('RefreshAccessToken')) return errorArray;

        //  Process single error, eg. NotFound or BadRequest action result
        if (httpErrorResponse.error && !httpErrorResponse.error['errors']) {
            errorArray.push(this.extractMessage(httpErrorResponse.error.errorMessage));
        }

        //  Process multiple errors, eg. BadRequest with multiple errors when creating a new user
        if (
            httpErrorResponse.error &&
            httpErrorResponse.error['errors'] &&
            Array.isArray(httpErrorResponse.error['errors'])
        ) {
            httpErrorResponse.error['errors'].forEach((x) => errorArray.push(x));
        }

        return errorArray;
    }
    extractMessage(errorMessage: any): string {
        if (errorMessage.indexOf('BEA') == 0) return this.backendErrorAuthMessageTranslation(errorMessage);

        return errorMessage;
    }
    backendErrorAuthMessageTranslation(errorMessage: any): string {
        if (errorMessage == 'BEA1')
            return this.translateService.instant(marker('Internal error in two-factor service'));
        if (errorMessage == 'BEA2') return this.translateService.instant(marker('Incorrect username or password'));
        if (errorMessage == 'BEA3') return this.translateService.instant(marker('Email address field is empty'));
        if (errorMessage == 'BEA4') return this.translateService.instant(marker('Cellphone is empty'));
        if (errorMessage == 'BEA5') return this.translateService.instant(marker('Two factor preference is empty'));
        if (errorMessage == 'BEA6') return this.translateService.instant(marker('Incorrect two-factor code'));
        if (errorMessage == 'BEA7')
            return this.translateService.instant(marker('User with selected email was not found'));
        if (errorMessage == 'BEA8') return this.translateService.instant(marker('User account is inactive'));
        if (errorMessage == 'BEA9') return this.translateService.instant(marker('User is disabled'));
        if (errorMessage == 'BEA10') return this.translateService.instant(marker('User does not match Certificate'));
        if (errorMessage == 'BEA11') return this.translateService.instant(marker('Your password has expired'));

        return errorMessage;
    }

    displayErrorInToasty(messages: string[], title = null) {
        messages.forEach((m) => {
            if (m === 'Your password has expired') return;
            else this.toastyService.error(m, title, { enableHtml: true });
        });
    }
}
