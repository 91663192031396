import { UserRegistrationFormDownloadModel } from '@administration/models/UserRegistrationForm.model';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserRegistrationText } from '@common/classes/UserRegistrationText';
import { FileDownloadService } from '@common/services/file-download.service';
import { UserService } from '@common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-registration-download',
    templateUrl: './registration-form-download.component.html',
    styleUrls: ['./registration-form-download.component.scss']
})
export class RegistrationFormDownloadComponent {
    isBusy = false;

    userRegistrationData: UserRegistrationFormDownloadModel;

    userRegistrationText = new UserRegistrationText(this.translateService);
    fileDownloadResult: any;

    constructor(
        private userService: UserService,
        private fileDownloadService: FileDownloadService,
        private router: Router,
        private toastyService: ToastrService,
        private translateService: TranslateService
    ) {
        const navigation = this.router.getCurrentNavigation();
        this.userRegistrationData = navigation.extras.state as UserRegistrationFormDownloadModel;
    }

    private formatCurrentDate(date: Date): string {
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${
            date.getMinutes() < 10 ? '0' : ''
        }${date.getMinutes()}`;
    }
}
