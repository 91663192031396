import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessageService } from '@common/services/validation-message.service';
import { AbstractFormControlComponent } from './abstract-form-control.component';

@Component({
    selector: 'form-checkbox',
    styleUrls: ['form-controls.component.scss'],
    template: `
        <app-control
            [type]="'boolean'"
            [isDisabled]="isDisabled"
            [label]="labelText"
            [ngClass]="{ 'is-invalid': isInvalid }"
            [isInvalid]="isInvalid"
            [contextual]="contextual"
            [(ngModel)]="value"
            (ngModelChange)="changeValue($event)"></app-control>
    `,
    providers: []
})
export class CheckComponent extends AbstractFormControlComponent {
    @Input()
    labelText: string;
    @Input()
    contextual: string = null;

    constructor(@Self() @Optional() controlDir: NgControl, validationMessageService: ValidationMessageService) {
        super(controlDir, validationMessageService);
    }
}
