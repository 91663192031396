import { CreateEmailEventModel } from '@administration/codelist-manager/components/codelist-manager/models/CreateEmailEventModel';
import { EmailEventDisplayModel } from '@administration/codelist-manager/components/codelist-manager/models/EmailEventDisplayModel';
import { RelationCustomsToTerminalSaveModel } from '@administration/models/SaveModels/RelationCustomsToTerminalSaveModel';
import { RelationSystemToOrganizationSaveModel } from '@administration/models/SaveModels/RelationSystemToOrganizationSaveModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CodelistService {
    constructor(private http: HttpClient) {}

    getCodelist(
        codelistName: string,
        id: string | number = '',
        filterId: string = '',
        filterName: string = ''
    ): Observable<any> {
        return this.http
            .post<any>('api:///codelist/GetCodeListOptions', { codelistName, id, filterId, filterName })
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return err;
                })
            );
    }

    saveLanguageToEmailEvent(saveModel: {
        id: number;
        codeListStringId: string;
        language: string;
        localizedCode: string;
        name: string;
        emailSubject: string;
        emailBody: string;
        emailFooter: string;
    }) {
        return this.http.post<any>('api:///codelist/SaveLanguageToEmailEvent', saveModel);
    }

    saveEmailEvent(saveModel: EmailEventDisplayModel): Observable<any> {
        return this.http.post<any>('api:///codelist/SaveEmailEvent', saveModel);
    }

    getEmailEvent(id: string): Observable<any> {
        return this.http.get(`api:///codelist/GetEmailEvent/${id}`);
    }

    getCargoHoldType(id: string): Observable<any> {
        return this.http.get(`api:///codelist/GetCargoHoldType/${id}`);
    }

    saveCargoHoldType(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/SaveCargoHoldType', saveModel);
    }

    createCargoHoldType(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/CreateCargoHoldType', saveModel);
    }

    saveTerminal(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/SaveTerminal', saveModel);
    }

    createTerminal(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/CreateTerminal', saveModel);
    }

    createPortFacility(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/CreatePortFacility', saveModel);
    }

    savePortFacility(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/SavePortFacility', saveModel);
    }

    getCustomsWarehouse(id: string): Observable<any> {
        return this.http.get(`api:///codelist/GetCustomsWarehouse/${id}`);
    }

    saveCustomsWarehouse(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/SaveCustomsWarehouse', saveModel);
    }

    createCustomsWarehouse(saveModel: any): Observable<any> {
        return this.http.post<any>('api:///codelist/CreateCustomsWarehouse', saveModel);
    }

    getLanguagesForEmailEvent(id: string): Observable<any> {
        return this.http.get(`api:///codelist/GetLanguagesForEmailEvent/${id}`);
    }

    getEmailEventDefaultSubscriptions(emailEventId: string): Observable<any> {
        return this.http.get(`api:///codelist/GetEmailEventDefaultSubscription/${emailEventId}`);
    }

    createEmailEvent(saveModel: CreateEmailEventModel) {
        return this.http.post<any>('api:///codelist/CreateNewEmailEvent', saveModel);
    }

    getCodelistData(id: string | number = '') {
        return this.http
            .get<any>(`api:///codelist/GetCodeListPcsDocumentAndApprovalType/${id}`)
            .pipe(catchError((err) => err));
    }
    // getCodelist<TDisplayModel>(id: string, actionName: string) {
    //   return this.http.get<TDisplayModel>(`api:///codelist/${actionName}/${id}`);
    // }

    logGdprAccessFromCodelistManager(entityName: string, accessedIds: string[]) {
        return this.http.post(`api:///codelist/LogCodelistManagerGdprAccess`, { entityName, accessedIds });
    }

    saveCodelist<TSaveModel>(model: TSaveModel, actionName: string) {
        return this.http.post(`api:///codelist/${actionName}`, model);
    }

    addOrgToEmailEvent(organizationId: number, emailEventId: string): Observable<any> {
        const body = {
            organizationId,
            emailEventId
        };
        return this.http.post<any>('api:///codelist/AddOrgToEmailEvent', body);
    }

    removeOrgFromEmailEvent(organizationId: number, emailEventId: string): Observable<any> {
        const body = {
            organizationId,
            emailEventId
        };
        return this.http.post<any>('api:///codelist/RemoveOrgFromEmailEvent', body);
    }

    addUserToEmailEvent(userId: number, emailEventId: string): Observable<any> {
        const body = {
            userId,
            emailEventId
        };
        return this.http.post<any>('api:///codelist/AddUserToEmailEvent', body);
    }

    removeUserFromEmailEvent(userId: number, emailEventId: string): Observable<any> {
        const body = {
            userId,
            emailEventId
        };
        return this.http.post<any>('api:///codelist/RemoveUserFromEmailEvent', body);
    }

    addEmailEventDefaultSubscription(model: any): Observable<any> {
        return this.http.post<any>('api:///codelist/EmailEventDefaultSubscription', model);
    }

    removeEmailEventDefaultSubscription(ids: any): Observable<any> {
        return this.http.post<any>('api:///codelist/RemoveEmailEventDefaultSubscription/', ids);
    }

    getAllCodelists() {
        return this.http.get<any>(`api:///codelist/GetCodeLists`).pipe(catchError((err) => err));
    }

    updateCodelist(request: {
        codelistName: string;
        id: string;
        name: string;
        action: string;
        isNew: boolean;
    }): Observable<any> {
        return this.http.post<any>(`api:///codelist/UpdateCodeList`, request);
    }

    getRelationSystemToOrganization(filter: any): Observable<any> {
        return this.http.post<any>('api:///codelist/GetRelationSystemToOrganization', filter);
    }

    saveSystemRelation(saveModel: RelationSystemToOrganizationSaveModel) {
        return this.http.post<any>('api:///codelist/SaveSystemRelation', saveModel);
    }

    removeSystemRelation(saveModel: RelationSystemToOrganizationSaveModel) {
        return this.http.post<any>('api:///codelist/RemoveSystemRelation', saveModel);
    }

    getRelationCustomsToTerminal(filter: any): Observable<any> {
        return this.http.post<any>('api:///codelist/GetRelationCustomsOfficeToTerminal', filter);
    }

    saveCustomsRelation(saveModel: RelationCustomsToTerminalSaveModel) {
        return this.http.post<any>('api:///codelist/SaveCustomsOfficeToTerminalRelation', saveModel);
    }

    removeCustomsRelation(saveModel: RelationCustomsToTerminalSaveModel) {
        return this.http.post<any>('api:///codelist/RemoveCustomsOfficeToTerminalRelation', saveModel);
    }

    refreshApprovals() {
        return this.http.get(`api:///PcsDocumentAndApproval/RecreateApprovalTransitions`);
    }
}
