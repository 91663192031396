import {
    AfterContentInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { ValidationMessageService } from '@common/services/validation-message.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'lodash-es';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { CommonService } from '../../../singleton-services/common.service';
import { AbstractFormControlComponent } from './abstract-form-control.component';

const noop = (item) => {
    if (item.localizedCode) return `${item.localizedCode} - ${item.name}`;
    if (item.code) return `${item.code} - ${item.name}`;
    if (item.id) return `${item.id} - ${item.name}`;
};

@Component({
    selector: 'form-dropdown',
    styleUrls: ['form-controls.component.scss'],
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="form-group" [ngClass]="{ 'has-value': !!value || hasValue, 'mb-0': !margin }">
            <label class="col-form-label" [ngClass]="{ 'form-control-disabled': isDisabled }">{{ labelText }}</label>
            <div *ngIf="contextual">
                <tooltip orientation="left" text="{{ contextual | translate }}"></tooltip>
            </div>
            <kendo-combobox
                *ngIf="!multi"
                class="form-control form-control-sm"
                placement="top"
                tooltipClass="error-tooltip"
                [data]="data"
                [textField]="displayValue"
                [valueField]="valueField"
                [disabled]="isDisabled"
                [loading]="isBusy"
                [valuePrimitive]="true"
                [filterable]="true"
                [title]="getTooltip(value)"
                [ngClass]="{ 'is-invalid': isInvalid }"
                [placeholder]="placeholder"
                [(ngModel)]="value"
                (filterChange)="handleFilter($event)"
                (valueChange)="changeValue($event)"
                (focus)="focusInHandler($event)"
                (blur)="focusOutHandler($event)"></kendo-combobox>
            <kendo-multiselect
                *ngIf="multi"
                class="multiselect"
                placement="top"
                tooltipClass="error-tooltip"
                [data]="data"
                [textField]="displayValue"
                [valueField]="valueField"
                [disabled]="isDisabled"
                [loading]="isBusy"
                [valuePrimitive]="true"
                [filterable]="true"
                [title]="getTooltip(value)"
                [placeholder]="placeholder"
                [ngClass]="{ 'is-invalid': isInvalid, 'is-read-only': isDisabled, required: required }"
                [(ngModel)]="value"
                (filterChange)="handleFilter($event)"
                (valueChange)="changeValue($event)"
                (focus)="focusInHandler($event)"
                (blur)="focusOutHandler($event)"></kendo-multiselect>
            <kendo-badge *ngIf="multi && !isBusy && value?.length > 0" class="multiselect-count" themeColor="dark">
                {{ value.length }}
            </kendo-badge>
        </div>
    `
})
export class DropDownComponent
    extends AbstractFormControlComponent
    implements OnInit, OnDestroy, OnChanges, AfterContentInit
{
    @Input() labelText: string;
    @Input() codeListName: string;
    @Input() codeListValues: any = [];
    @Input() displayValue = 'label';
    @Input() valueField = 'id';
    @Input() contextual: string = null;
    @Input() forTOSSystem?: string = '';
    @Input() selectLabel = noop;
    @Input() hasValue = false;
    @Input() margin = true;
    @Input() multi = false;
    @Input() observableInput: Observable<any>;
    @Input() type: AppControlType;
    @Input() placeholder: string;
    @Input() automaticPopulateWithFirstValue: boolean = false; // only when we have 1 item in dropdown list
    observableInputExistingValue: any = null;

    isBusy = false;

    untouchedData: any = [];
    data: any = [];
    dataSubscription: any;

    private filterValues: Subject<string> = new Subject<string>();
    searchSub: Subscription;
    valueChangesSub: Subscription;
    statusChangesSub: Subscription;
    private _tooltip;

    @ViewChild('tooltip', { static: false }) set tooltip(value) {
        this._tooltip = value;
        if (value) {
            this.showFormControlValidation();
        }
    }

    get tooltip() {
        return this._tooltip;
    }

    constructor(
        @Self() @Optional() controlDir: NgControl,
        private commonService: CommonService,
        private translateService: TranslateService,
        validationMessageService: ValidationMessageService
    ) {
        super(controlDir, validationMessageService);

        this.searchSub = this.filterValues.pipe(debounceTime(1000)).subscribe((x) => {
            this.filterItems(x);
        });
    }

    public clearSelectedItems(): void {
        this.value = [];
        this.handleFilter(this.value);
    }

    /** Handle value changes when form value is set programmatically - if id is not in initially spliced list, filter is triggered.
     *  eg. after container id input, we create a query to get containerISO CODE for that container and fill that data automatically */
    ngAfterContentInit(): void {
        this.valueChangesSub = this.controlDir?.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            if (value) {
                const itemInSplicedList = this.data.find((dataItem) => dataItem?.id == value);
                if (itemInSplicedList) {
                    return;
                }

                if (!itemInSplicedList) {
                    this.handleFilter(value);
                    return;
                }
            }

            if (!value) {
                this.data = this.untouchedData;
            }
        });

        // updating tooltip on every validation change
        this.statusChangesSub = this.controlDir?.statusChanges.subscribe((val) => {
            this.showFormControlValidation();
        });
    }

    maybeApplyActive() {
        this.hasValue =
            !!this.value ||
            (this.value === 0 && this.type === AppControlType.Number) ||
            (this.value === false && this.type === AppControlType.YesNo);
    }

    focusInHandler() {
        this.hasValue = !!this.value || !this.isDisabled;
        this.placeholder = this.translateService.instant(marker('Select')) + ' ' + this.labelText;
    }

    focusOutHandler() {
        this.hasValue = false;
        this.maybeApplyActive();
        this.placeholder = null;
    }

    changeValue(e) {
        this.markTouched();
        this.onChange(e);

        this.showFormControlValidation();
    }

    getTooltip(value) {
        if (!value) return this.translateService.instant(marker(this.multi ? 'Select value(s)' : 'Select value'));
        else return '';
    }

    //filtering
    ngOnInit() {
        this.isBusy = true;

        if (this.observableInput) {
            this.handleObservableInput();
        }
        if (this.codeListName) {
            this.handleCodeListNameInput();
        } else if (this.codeListValues) {
            this.handleCodelistValuesInput();
        } else {
            this.isBusy = false;
            throw new Error('Either codeListName or codeListValue expected to be passed into the dropdown component.');
        }

        if (this.getFormControlValidators) {
            this.showFormControlValidation();
        }
    }

    /**
     *  Given a codelist Name (eg. CodelistDocumentType) use getDropdownOptions() to make an http call to return the data
     *  getDropdownOptions caches the retrieved data on the client.
     */
    private handleCodeListNameInput() {
        const dataSubject = this.commonService.getDropdownOptions(this.codeListName, this.forTOSSystem);

        this.dataSubscription = dataSubject.subscribe((data) => {
            if (data == null) return;
            data = data.map((x: any) => ({
                label: this.selectLabel(x),
                id: x.id,
                name: x.name,
                localizedCode: x.localizedCode
            }));

            this.untouchedData = data;

            this.extractInitialData();
            this.isBusy = false;
        });
    }

    /**
     *  Given an observable input (eg. this.someService.someBackendQuery()) subscribe directly to the given observable and retrieve the data.
     *  We're using this mainly for custom queries, eg. when we need to filter the dropdown results.
     *  Since it's primary use is for custom queries, eg. filtered list of WorkOrders we are not caching the results.
     *
     *  If custom query is initially null, let's say we have to select some form values before making a query (someQ = this.someService.someBackendQuery()),
     *  ngOnChanges will call this function again when someQ receives its value
     */
    private handleObservableInput() {
        if (this.value) this.observableInputExistingValue = this.value;

        this.dataSubscription = this.observableInput
            .pipe(
                first(),
                catchError((err) => throwError(() => err))
            )

            .subscribe((data) => {
                if (data == null) return;
                data = data.map((x: any) => ({
                    label: this.selectLabel(x),
                    id: x.id,
                    name: x.name,
                    localizedCode: x.localizedCode
                }));

                this.untouchedData = data;

                // automatic population when only 1 item exists in dropdown list
                if (this.automaticPopulateWithFirstValue && data.length == 1) {
                    this.observableInputExistingValue = data[0];
                }

                this.extractInitialData();
                this.isBusy = false;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.observableInput && !changes.observableInput.firstChange) {
            this.handleObservableInput();
        }
        if (changes.codeListValues) {
            this.handleCodelistValuesInput();
        }
    }

    private showFormControlValidation() {
        if (this.getFormControlValidators && this.tooltip) {
            this.tooltip.ngbTooltip = map(this.getFormControlValidators, (x) =>
                this.translateService.instant(marker(x))
            ).join('\n');
        } else if (this.tooltip) {
            this.tooltip.ngbTooltip = null;
        }
    }

    /**
     *  In some cases we can directly pass an object[] as values to this component. In this case, object[] needs to have values before the component is rendered,
     *  so, use *ngIf to prevent rendering the component before the data is loaded.
     */
    private handleCodelistValuesInput() {
        // this is when codelistValues has 1 element with value null. This happens when we get null for that property from DisplayModel on BE
        if (this.codeListValues[0] === null) {
            this.isBusy = false;
            return;
        }

        this.codeListValues = this.codeListValues.map((x: any) => ({
            label: this.selectLabel(x),
            id: x.id,
            name: x.name,
            localizedCode: x.localizedCode
        }));

        this.untouchedData = this.codeListValues;
        this.extractInitialData();
        this.isBusy = false;
    }

    handleFilter(value) {
        this.isBusy = true;
        this.filterValues.next(value);
    }

    extractInitialData() {
        this.data = this.untouchedData.slice(0, 250);

        if (this.value && !this.observableInput) {
            const id = this.value.id ? this.value.id : this.value;
            if (this.data.find((x) => x.id == id)) {
                return;
            }

            const itemFound = this.untouchedData.find((x) => x.id == id);

            if (!itemFound) {
                this.controlDir.reset();
                return;
            }

            this.data.push(itemFound);
        }

        if (!this.value && this.observableInput && this.observableInputExistingValue) {
            this.value = this.observableInputExistingValue;

            const id = this.value.id ? this.value.id : this.value;
            if (this.data.find((x) => x.id == id)) {
                this.writeValue(id);
                return;
            }

            const itemFound = this.untouchedData.find((x) => x.id == id);

            if (!itemFound) {
                this.controlDir.reset();
                return;
            }

            this.data.push(itemFound);
        }

        if (this.value && this.observableInput && this.observableInputExistingValue) {
            const id = this.value.id ? this.value.id : this.value;
            if (this.data.find((x) => x.id == id)) {
                this.writeValue(id);
                return;
            }

            const itemFound = this.untouchedData.find((x) => x.id == id);
            if (!itemFound) {
                this.controlDir.reset();
                return;
            }

            this.data.push(itemFound);
        }
    }

    filterItems(value: any) {
        if (typeof value === 'string') {
            this.data = this.untouchedData
                .filter((s) => s[this.displayValue].toLowerCase().includes(value.toLowerCase()))
                .slice(0, 250);
        }

        if (this.data.length === 0) {
            this.controlDir.reset();
        }

        this.isBusy = false;
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
        this.searchSub?.unsubscribe();
        this.valueChangesSub?.unsubscribe();
        this.statusChangesSub?.unsubscribe();
    }

    public isItemSelected(val: any) {
        return this.value?.some((x) => x === val);
    }
}
