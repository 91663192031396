// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { EnvironmentNames } from '@common/knownTypes/EnvironmentNames';

export const appUrl = `${window.location.protocol}//${window.location.hostname}`;
export const environment = {
    production: false,
    name: EnvironmentNames.LOCAL,
    serverUrl: `${appUrl}/be`,
    apiUrl: `${appUrl}/be/api`,
    certApiUrl: 'https://auth.pcsbg.portline.eu/api',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    title: 'BulPCS',
    version: '0.1.0',
    signaturePolicyIdentifier: 'http://www.carina.hr/b2gtest/politika_uporabe_el._potpisa_za_test.pdf',
    signaturePolicyHashDigestValue: 'jQoItxOcrjJdSK5slPjykzVpoYsP0z1I2aE20TJmwng=',
    reportServerLocation: 'https://pcslur-rep.actual-it.si',
    oidcScope: 'openid profile pcs_access',
    oidcClient: 'pcs_spa',
    defaultDashboardId: '1497921',
    defaultMobileDashboardId: '1492959',

    settings: {
        grid: {
            pageable: true,
            pageSize: 10,
            pageSizes: [10, 20, 50],
            sortable: {
                allowUnsort: true,
                mode: 'single'
            },
            sort: [
                {
                    field: 'id',
                    dir: 'desc'
                }
            ]
        },
        validation: {
            errors: {
                showTooltip: true,
                showFeedback: false
            }
        }
    }
};
