import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@common/services/authentication.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { endsWith } from 'lodash-es';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    private refreshingToken = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private loadingBarService: LoadingBarService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private translateService: TranslateService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        //  https://github.com/aitboudad/ngx-loading-bar/blob/main/packages/http-client/src/loading-bar.interceptor.ts
        req = this.setTokenAndUrl(req, this.authenticationService.getAccessToken());
        req = this.setRequestLanguage(req);
        if (req.headers.has('ignoreLoadingBar')) {
            return next.handle(req.clone({ headers: req.headers.delete('ignoreLoadingBar') }));
        }

        let started = false;
        const ref = this.loadingBarService.useRef('http');

        return next.handle(req).pipe(
            tap(() => {
                if (!started) {
                    ref.start();
                    started = true;
                }
            }),
            finalize(() => started && ref.complete()),
            catchError((error) => (this.shouldRefreshToken(error) ? this.refreshToken(req, next) : throwError(error)))
        );
    }

    private shouldRefreshToken(error: any) {
        return (
            error instanceof HttpErrorResponse &&
            !endsWith(error.url, 'RefreshAccessToken') &&
            error.headers.has('Token-Expired') &&
            error.status === 401
        );
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.refreshingToken) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshAccessToken().pipe(
                switchMap((token: any) => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next(token.accessToken);
                    return next.handle(this.setTokenAndUrl(request, token.accessToken));
                }),
                catchError((error) => {
                    // refresh token is not valid anymore
                    this.refreshingToken = false;
                    this.authenticationService.clearUserAndRedirectToLogin(this.router.url);
                    return throwError(() => error);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => next.handle(this.setTokenAndUrl(request, token)))
            );
        }
    }

    private setTokenAndUrl(request: HttpRequest<any>, token: string) {
        const headers: any = {};
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        return request.clone({
            setHeaders: headers,
            url:
                request.url && request.url.indexOf('api://') === 0
                    ? request.url.replace('api://', this.getBaseUrl())
                    : request.url
        });
    }

    private getBaseUrl() {
        return environment.apiUrl;
    }

    setRequestLanguage(request: HttpRequest<any>) {
        const headers: any = {};
        const currentLang = this.translateService.currentLang;
        if (currentLang) headers['Accept-Language'] = currentLang.toUpperCase();

        return request.clone({ setHeaders: headers });
    }
}
