import { Component } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalService } from '@common/services/modal.service';
import { currentUserSubject } from '@common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { filter } from 'rxjs';

@Component({
    selector: 'app-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {
    public messageId: string;
    public messageNumber: string;
    public messageData: string;
    public errorMessage: string;
    public title: string;
    public canResend = false;

    constructor(
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private dialogService: ModalService
    ) {
        this.canResend = this.hasPermission('Administration.Messages.Resend');
    }

    resend() {
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Resend message')),
                this.translateService.instant(marker('Are you sure you want to resend the message?')),
                this.translateService.instant(marker('Yes')),
                null,
                this.translateService.instant(marker('Cancel'))
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.activeModal.close(this.messageId);
                }
            });
    }

    private hasPermission(fullPermissionName: string) {
        const user = currentUserSubject.getValue();
        if (!user) {
            return false;
        }

        return user.hasPermission(fullPermissionName);
    }
}
