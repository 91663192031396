import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PcsDocumentOrApprovalLocation } from '@components/approvals/models/PcsDocumentOrApprovalLocation';
import { AdditionalDatasSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/AdditionalDataSaveModel';
import { PcsDocumentAndApprovalTypeActionRoleSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalTypeActionRoleSaveModel';
import { PcsDocumentAndApprovalTypeAdditionalDataRoleSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalTypeAdditionalDataRoleSaveModel';
import { PcsDocumentAndApprovalTypeAttachmentRoleSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalTypeAttachmentRoleSaveModel';
import { PcsDocumentAndApprovalTypeNotificationRoleSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalTypeNotificationRoleSaveModel';
import { PcsDocumentAndApprovalTypeWatchersRoleSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalTypeWatchersRoleSaveModel';
import { PcsDocumentsApprovalSaveModel } from 'app/vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentOrApprovalTypeSaveModel';
import { Observable } from 'rxjs';
import { IPcsDocumentOrApprovalDetailsDisplayModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/DetailsDisplayModels/IPcsDocumentOrApprovalDetailsDisplayModel';
import { PcsApprovalDetailsDisplayModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/DetailsDisplayModels/PcsApprovalDetailsDisplayModel';
import { PcsDocumentDetailsDisplayModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/DetailsDisplayModels/PcsDocumentDetailsDisplayModel';
import { PcsApprovalGridDisplayModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/OtherDisplayModels/PcsApprovalGridDisplayModel';
import { PcsApprovalNewPossibleApprovalsDisplayModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/OtherDisplayModels/PcsApprovalNewPossibleApprovalsDisplayModel';
import { PcsDocumentAndApprovalAllPossibleStateTransitionsDisplayModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/OtherDisplayModels/PcsDocumentAndApprovalAllPossibleStateTransitionsDisplayModel';
import { IPcsNewDocumentAndApprovalSaveModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/IPcsNewDocumentAndApprovalSaveModel';
import { PcsDocumentAndApprovalAddAttachmentSaveModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalAddAttachmentSaveModel';
import { PcsDocumentAndApprovalGetPossibleStateTransitionQueryModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalGetPossibleStateTransitionQueryModel';
import { PcsDocumentAndApprovalRemarkSaveModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalRemarkSaveModel';
import { PcsDocumentAndApprovalStatusChangeSaveModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentAndApprovalStatusChangeSaveModel';
import { PcsDocumentOrApprovalRemoveAttachmentSaveModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentOrApprovalRemoveAttachmentSaveModel';
import { PcsDocumentOrApprovalRemoveRemarkSaveModel } from '../../vessel-visit/vessel-visit-list/models/PcdDocumentAndApprovalModels/SaveModels/PcsDocumentOrApprovalRemoveRemarkSaveModel';

@Injectable({
    providedIn: 'root'
})
export class PcsDocumentAndApprovalServices {
    constructor(private http: HttpClient) {}

    // ===== DOCUMENT SPECIFIC =====

    getPcsDocumentsTab(type: string, id: number) {
        return this.http.get<any>(`api:///PcsDocumentAndApproval/GetPcsDocumentsTab/${type}/${id}`);
    }

    getPcsDocumentsDetails(id: number): Observable<any> {
        return this.http.get<PcsDocumentDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/GetPcsDocumentDetails/${id}`
        );
    }

    // ===== LIST =====
    getPcsApprovalsList(filter: any): Observable<any> {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/GetPcsApprovalList`, filter);
    }

    // ===== APPROVAL SPECIFIC =====

    getPcsAdditionalApprovalTab(pcsApprovalLocation: PcsDocumentOrApprovalLocation, currentId: number) {
        return this.http.get<[PcsApprovalGridDisplayModel]>(
            `api:///PcsDocumentAndApproval/GetPcsApprovals/${pcsApprovalLocation}/${currentId}`
        );
    }

    getPcsAdditionalApprovalDetailedData(approvalId: number) {
        return this.http.get<PcsApprovalDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/GetPcsApprovalDetails/${approvalId}`
        );
    }
    // ===== GENERIC FOR DOCUMENT OR APPROVAL

    getAllPossibleNewDocumentsOrApprovals(location: string, isForApproval: boolean) {
        return this.http.get<PcsApprovalNewPossibleApprovalsDisplayModel[]>(
            `api:///PcsDocumentAndApproval/GetAllPossibleNewApprovalTypes/${location}/${isForApproval}`
        );
    }

    createNewPcsDocumentOrApproval(saveModel: IPcsNewDocumentAndApprovalSaveModel) {
        return this.http.post<PcsApprovalGridDisplayModel>(
            `api:///PcsDocumentAndApproval/CreateNewPcsDocumentOrApproval`,
            saveModel
        );
    }

    getAllPossibleStateTransitions(queryModel: PcsDocumentAndApprovalGetPossibleStateTransitionQueryModel) {
        return this.http.post<PcsDocumentAndApprovalAllPossibleStateTransitionsDisplayModel[]>(
            `api:///PcsDocumentAndApproval/GetAllPossiblePcsDocumentOrApprovalActionStatusChanges`,
            queryModel
        );
    }

    newActionStatusChange(saveModel: PcsDocumentAndApprovalStatusChangeSaveModel) {
        return this.http.post<IPcsDocumentOrApprovalDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/CreateNewStateTransitionForPcsDocumentOrApproval`,
            saveModel
        );
    }

    newRemark(saveModel: PcsDocumentAndApprovalRemarkSaveModel) {
        return this.http.post<IPcsDocumentOrApprovalDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/CreateNewRemarkForPcsDocumentOrApproval`,
            saveModel
        );
    }

    removeRemark(saveModel: PcsDocumentOrApprovalRemoveRemarkSaveModel) {
        return this.http.post<IPcsDocumentOrApprovalDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/RemovePcsDocumentOrApprovalRemark`,
            saveModel
        );
    }

    newAttachment(saveModel: PcsDocumentAndApprovalAddAttachmentSaveModel) {
        return this.http.post<IPcsDocumentOrApprovalDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/AddPcsDocumentOrApprovalAttachment`,
            saveModel
        );
    }

    removeAttachment(saveModel: PcsDocumentOrApprovalRemoveAttachmentSaveModel) {
        return this.http.post<IPcsDocumentOrApprovalDetailsDisplayModel>(
            `api:///PcsDocumentAndApproval/RemovePcsDocumentOrApprovalAttachment`,
            saveModel
        );
    }

    downloadAttachment(query: number) {
        return this.http.get<any>(`api:///PcsDocumentAndApproval/DownloadPcsDocumentOrApprovalAttachment/${query}`);
    }

    saveAdditionalData(saveModel: AdditionalDatasSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/EditPcsDocumentOrApprovalAdditionalData`, saveModel);
    }

    downloadPdf(approvalId: number) {
        return this.http.get<any>(`api:///PcsDocumentAndApproval/GetPcsApprovalGenericReportPdfPreview/${approvalId}`);
    }

    /*
    deletePcsDocumentOrApproval(queryModel: PcsDeleteDocumentOrApproval) {
        return this.http.post<PcsApprovalGridDisplayModel>(`api:///PcsDocumentAndApproval/DeletePcsDocumentOrApproval`, queryModel);
    }
    */

    savePcsDocumentOrApprovalType(saveModel: PcsDocumentsApprovalSaveModel) {
        return this.http.post<PcsDocumentsApprovalSaveModel>(
            `api:///PcsDocumentAndApproval/CreatePcsDocumentOrApprovalType`,
            saveModel
        );
    }

    editApprovalType(saveModel: PcsDocumentsApprovalSaveModel) {
        return this.http.post<PcsDocumentsApprovalSaveModel>(
            `api:///PcsDocumentAndApproval/EditApprovalType`,
            saveModel
        );
    }

    saveActionRole(saveModel: PcsDocumentAndApprovalTypeActionRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/SaveActionRole`, saveModel);
    }

    removeActionRole(id: number) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/RemoveActionRole/`, id);
    }

    saveNotificationRole(saveModel: PcsDocumentAndApprovalTypeNotificationRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/SaveNotificationRole`, saveModel);
    }

    removeNotificationRole(saveModel: PcsDocumentAndApprovalTypeNotificationRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/RemoveNotificationRole`, saveModel);
    }

    saveAttachmentRole(saveModel: PcsDocumentAndApprovalTypeAttachmentRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/SaveAttachmentRole`, saveModel);
    }

    removeAttachmentRole(saveModel: PcsDocumentAndApprovalTypeAttachmentRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/RemoveAttachmentRole`, saveModel);
    }

    saveAdditionalDataRole(saveModel: PcsDocumentAndApprovalTypeAdditionalDataRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/SaveAdditionalDataRole`, saveModel);
    }

    saveWatchersRole(saveModel: PcsDocumentAndApprovalTypeWatchersRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/SaveWatchersRole`, saveModel);
    }

    removeWatchersRole(saveModel: PcsDocumentAndApprovalTypeWatchersRoleSaveModel) {
        return this.http.post<any>(`api:///PcsDocumentAndApproval/RemoveWatchersRole`, saveModel);
    }
}
