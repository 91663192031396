import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserRegistrationFormDownloadGuard implements CanActivate {
    constructor(
        private router: Router,
        private location: Location
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // This route can only be reached from createUser component
        if (this.router.url === '/createUser') {
            return true;
        }

        this.location.back();
        return false;
    }
}
