<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'FAQ' | translate }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close(true)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="isBusy">
                <ng-template>
                    <form class="p-3" [formGroup]="form">
                        <div class="row">
                            <div class="col-4">
                                <form-dropdown
                                    formControlName="parentCategoryId"
                                    labelText="{{ 'Category' | translate }}"
                                    [displayValue]="'name'"
                                    [codeListValues]="categories"></form-dropdown>
                            </div>
                            <div class="col-4">
                                <form-dropdown
                                    formControlName="helpCategoryId"
                                    labelText="{{ 'Sub Category' | translate }}"
                                    [displayValue]="'name'"
                                    [codeListValues]="subCategories"></form-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    formControlName="question"
                                    labelText="{{ 'Question' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    formControlName="questionEN"
                                    labelText="{{ 'Question EN' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    formControlName="answer"
                                    labelText="{{ 'Answer' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    formControlName="answerEN"
                                    labelText="{{ 'Answer EN' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <kendo-fileselect
                                    formControlName="helpAttachments"
                                    [restrictions]="attachmentFileUploadRestrictions"
                                    [multiple]="true"
                                    (select)="fileSelected($event)">
                                    <ng-template kendoFileSelectFileTemplate let-files>
                                        <div class="mb-2">
                                            <div class="file-name">{{ files[0].name }}</div>
                                            <div class="file-size small text-muted">
                                                Size: {{ bytesToKB(files[0].size) }}
                                            </div>
                                        </div>
                                        <button
                                            class="btn btn-sm btn-danger remove-button"
                                            kendoButton
                                            (click)="removeAttachment(files[0].uid, files[0].id)">
                                            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                                            &nbsp;&nbsp;{{ 'Remove' | translate }}
                                        </button>
                                    </ng-template>
                                </kendo-fileselect>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-danger" translate (click)="activeModal.close(true)">Cancel</button>
            <button class="btn btn-sm btn-primary" translate [disabled]="!form.valid" (click)="addQuestion()">
                Save
            </button>
        </div>
    </ng-template>
</app-loader>
