<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'Temporary New Vessel' | translate }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="isBusy">
                <ng-template>
                    <form class="p-3" [formGroup]="form">
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    formControlName="name"
                                    labelText="{{ 'Vessel Name' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox
                                    formControlName="imo"
                                    labelText="{{ 'IMO Number' | translate }}"></form-textbox>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-danger" translate (click)="activeModal.close(false)">Cancel</button>
            <button class="btn btn-sm btn-primary" translate [disabled]="!form.valid" (click)="addNewVessel()">
                Save
            </button>
        </div>
    </ng-template>
</app-loader>
