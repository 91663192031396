import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AttachmentService } from '@common/services/attachment.service';
import { CodelistService } from '@common/services/codelist.service';
import { HelpModuleService } from '@common/services/help-module.service';
import { ModalService } from '@common/services/modal.service';
import { NavigationFactoryService } from '@common/services/navigation-factory.service';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';

@Component({
    selector: 'app-add-faq-modal',
    templateUrl: './add-faq-modal.component.html',
    styleUrls: ['./add-faq-modal.component.css']
})
export class AddFaqModalComponent implements OnInit {
    isBusy: boolean;
    form: UntypedFormGroup;
    isEditMode: boolean;
    question: any;
    categories: any[] = [];
    subCategories: any[] = [];
    newAttachments: any[] = [];

    public attachmentFileUploadRestrictions: FileRestrictions = {
        allowedExtensions: new Array<string>(),
        maxFileSize: 0
    };
    files: Array<any> = [];
    attachmentsToRemove: number[] = [];

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public service: HelpModuleService,
        public codelistService: CodelistService,
        private navigationFactoryService: NavigationFactoryService,
        private attachmentService: AttachmentService
    ) {}

    ngOnInit() {
        this.form = this.createFormGroup();
        this.loadAttachmentSettings();
        this.getData();
        this.checkEditMode();
    }

    public addQuestion() {
        const body = { ...this.form.getRawValue(), files: this.files };
        if (this.attachmentsToRemove.length > 0) {
            body['removedAttachmentIds'] = this.attachmentsToRemove;
        }
        this.service.addQuestion(body).subscribe({
            next: () => {
                this.isEditMode
                    ? this.toastyService.success(this.translateService.instant(marker('Question updated successfully')))
                    : this.toastyService.success(this.translateService.instant(marker('Question added successfully')));
                this.activeModal.close();
            }
        });
    }

    fileSelected($event) {
        if ($event.files.length > 10) {
            this.toastyService.error(this.translateService.instant(marker('Please select maximum of 10 files')));
            $event.preventDefault();
            return;
        }

        $event.files.forEach((element) => {
            if (element.size > this.attachmentFileUploadRestrictions.maxFileSize) {
                this.toastyService.error(
                    `${this.translateService.instant(marker('Attachment size invalid'))} - ${element.name}`
                );
                return;
            }
            if (!this.attachmentFileUploadRestrictions.allowedExtensions.some((ext) => ext == element.extension)) {
                this.toastyService.error(
                    `${this.translateService.instant(marker('Invalid attachment'))} - ${element.name}`
                );
                return;
            }

            const raw = element.rawFile;
            if (raw) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.documentOnLoad(e.target.result.toString(), raw.name);
                };
                reader.readAsDataURL(element.rawFile);
                this.newAttachments.push(element);
            }
        });
    }

    removeAttachment(uid: string, attachmentId?: number) {
        const newAttachment = this.newAttachments.find((x) => x.uid == uid);
        if (newAttachment) {
            const file = this.files.filter((x) => x.base64ContentName == newAttachment.name);
            if (file == null || file.length == 0) return;
            this.files.splice(this.files.indexOf(file[0]), 1);
            this.form
                .get('helpAttachments')
                .patchValue(this.form.get('helpAttachments').value.filter((x) => x.name != newAttachment.name));
            return;
        }
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Remove Attachment')),
                this.translateService.instant(
                    marker(
                        'Are you sure you want to remove the attachment? The changes will be applied when you save the FAQ.'
                    )
                ),
                this.translateService.instant(marker('Yes')),
                this.translateService.instant(marker('No'))
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((res) => {
                if (res) {
                    this.form
                        .get('helpAttachments')
                        .patchValue(this.form.get('helpAttachments').value.filter((x) => x.uid != uid));
                    this.attachmentsToRemove.push(attachmentId);
                }
            });
    }

    documentOnLoad(file: string, name: string) {
        this.files.push({ base64Content: file, base64ContentName: name });
    }

    bytesToKB(bytes: number): string {
        const kilobytes = bytes / 1024;
        return kilobytes.toFixed(2) + ' KB';
    }

    private loadAttachmentSettings(): void {
        this.attachmentService.getAttachmentSettingsForUpload().subscribe((response) => {
            this.attachmentFileUploadRestrictions = response;
        });
    }

    private checkEditMode() {
        if (this.question) {
            this.isEditMode = true;
            this.form.patchValue(this.question);
            if (this.question.helpAttachments) {
                const attachments = this.question.helpAttachments.map((attachment) => ({
                    id: attachment.id,
                    base64Content: attachment.attachedFile.base64Content,
                    name: attachment.attachedFile.name,
                    // calculate the size based on the base64Content
                    size: Math.round(attachment.attachedFile.base64Content.length * (3 / 4) - 2)
                }));

                this.form.get('helpAttachments').patchValue(attachments);
            }
        }
    }

    private createFormGroup() {
        const form = new UntypedFormGroup({
            id: new UntypedFormControl(0),
            parentCategoryId: new UntypedFormControl(null, requiredValidator()),
            helpCategoryId: new UntypedFormControl({ value: 0, disabled: true }),
            question: new UntypedFormControl(null, requiredValidator()),
            questionEN: new UntypedFormControl(null),
            answer: new UntypedFormControl(null, requiredValidator()),
            answerEN: new UntypedFormControl(null),
            helpAttachments: new UntypedFormControl(null)
        });
        form.patchValue(this);
        return form;
    }

    private getData() {
        this.categories = this.navigationFactoryService
            .getMenuItems()
            .map((category) => ({ name: category.title, id: category.id }))
            .filter((category) => category.name !== 'Help');
        this.trackFromChanges();
    }

    private trackFromChanges() {
        this.form
            .get('parentCategoryId')
            .valueChanges.pipe(filter((x) => x !== null))
            .subscribe({
                next: (categoryId: number) => {
                    if (categoryId) {
                        this.form.get('helpCategoryId').enable();
                        this.subCategories = this.navigationFactoryService
                            .getMenuItems()
                            .find((x) => x.id === categoryId)
                            .childs.map((category) => ({ name: category.title, id: category.id }));
                    } else {
                        this.form.get('helpCategoryId').disable();
                    }
                }
            });
    }
}
