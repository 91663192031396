import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelpModuleService {
    private categorySelectedInFaq = new BehaviorSubject(null);
    private categorySelectedInUserManuals = new BehaviorSubject<void>(null);
    private categoryUpdateSubject = new BehaviorSubject<void>(null);
    selectedCategoryInFaq$ = this.categorySelectedInFaq.asObservable();
    selectedCategoryInUserManuals$ = this.categorySelectedInUserManuals.asObservable();
    categoryUpdate$ = this.categoryUpdateSubject.asObservable();

    constructor(private http: HttpClient) {}

    setSelectedCategoryInFaq(category: any) {
        this.categorySelectedInFaq.next(category);
    }

    setSelectedCategoryInUserManuals(category: any) {
        this.categorySelectedInUserManuals.next(category);
    }

    updateCategories() {
        this.categoryUpdateSubject.next();
    }

    getFaq(parentCategoryId: number = 0, helpCategoryId: number = 0, isForAdministration = true) {
        return this.http.get<any>(
            `api:///HelpModule/GetFaq/${parentCategoryId}/${helpCategoryId}/${isForAdministration}`
        );
    }

    addQuestion(body: any): Observable<any> {
        return this.http.post<any>('api:///HelpModule/AddQuestion', body);
    }

    removeQuestion(ids: any): Observable<any> {
        return this.http.post<any>('api:///HelpModule/RemoveQuestion/', ids);
    }

    archiveQuestion(ids: any) {
        return this.http.post<any>('api:///HelpModule/ArchiveQuestion/', ids);
    }

    getDocuments(parentCategoryId: number = 0, helpCategoryId: number = 0, isForAdministration = true) {
        return this.http.get<any>(
            `api:///HelpModule/GetDocuments/${parentCategoryId}/${helpCategoryId}/${isForAdministration}`
        );
    }

    addDocument(body: any): Observable<any> {
        return this.http.post<any>('api:///HelpModule/AddDocument', body);
    }

    removeDocument(ids: any): Observable<any> {
        return this.http.post<any>('api:///HelpModule/RemoveDocument/', ids);
    }

    archiveDocument(ids: any) {
        return this.http.post<any>('api:///HelpModule/ArchiveDocument/', ids);
    }
}
