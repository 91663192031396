<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'Vehicle' | translate }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="isBusy">
                <ng-template>
                    <form class="p-3" [formGroup]="form">
                        <div class="row">
                            <div class="col-6">
                                <form-dropdown
                                    formControlName="cargoDirectionId"
                                    labelText="{{ 'Direction' | translate }}"
                                    codeListName="CargoDirection"></form-dropdown>
                            </div>
                            <div class="col-6">
                                <form-dropdown
                                    formControlName="relationSystemToOrganizationToTerminalId"
                                    labelText="{{ 'Terminal' | translate }}"
                                    displayValue="name"
                                    [observableInput]="terminalOptions$"
                                    [automaticPopulateWithFirstValue]="true"></form-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <form-dropdown
                                    formControlName="vehicleTypeId"
                                    labelText="{{ 'Vehicle Type' | translate }}"
                                    codeListName="MeanOfTransportType"></form-dropdown>
                            </div>
                            <div class="col-4">
                                <form-textbox
                                    formControlName="vehicleMake"
                                    labelText="{{ 'Vehicle Make' | translate }}"></form-textbox>
                            </div>
                            <div class="col-4">
                                <form-textbox
                                    formControlName="vehicleModel"
                                    labelText="{{ 'Vehicle Model' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <form-number
                                    formControlName="quantity"
                                    labelText="{{ 'Quantity' | translate }}"></form-number>
                            </div>
                            <div class="col-3">
                                <form-number
                                    formControlName="weight"
                                    labelText="{{ 'Weight (kg)' | translate }}"
                                    [decimal]="true"></form-number>
                            </div>
                            <div class="col-3">
                                <form-number
                                    formControlName="length"
                                    labelText="{{ 'Length (m)' | translate }}"
                                    [decimal]="true"></form-number>
                            </div>
                            <div class="col-3">
                                <form-number
                                    formControlName="width"
                                    labelText="{{ 'Width (m)' | translate }}"
                                    [decimal]="true"></form-number>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <form-textbox
                                    formControlName="vin"
                                    labelText="{{ 'VIN' | translate }}"
                                    contextual="{{
                                        'Allowed characters: A-Z, a-z, 0-9 (no special characters) and max length 17'
                                            | translate
                                    }}"></form-textbox>
                            </div>
                            <div class="col-3">
                                <form-textbox
                                    formControlName="driverName"
                                    labelText="{{ 'Driver Name & Info' | translate }}"></form-textbox>
                            </div>
                            <div class="col-3">
                                <form-textbox
                                    formControlName="licencePlate"
                                    labelText="{{ 'Licence Plate' | translate }}"></form-textbox>
                            </div>
                            <div class="col-3">
                                <form-textbox
                                    formControlName="trailerPlates"
                                    labelText="{{ 'Trailer Plates' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <form-dropdown
                                    formControlName="countryOfOriginId"
                                    labelText="{{ 'Country Of Origin' | translate }}"
                                    codeListName="Flag"></form-dropdown>
                            </div>
                            <div class="col-3">
                                <form-dropdown
                                    formControlName="portOfLoadingId"
                                    labelText="{{ 'Port Of Loading' | translate }}"
                                    codeListName="Port"></form-dropdown>
                            </div>
                            <div class="col-3">
                                <form-dropdown
                                    formControlName="portOfDischargeId"
                                    labelText="{{ 'Port Of Discharge' | translate }}"
                                    codeListName="Port"></form-dropdown>
                            </div>
                            <div class="col-3">
                                <form-dropdown
                                    formControlName="countryOfDestinationId"
                                    labelText="{{ 'Country Of Destination' | translate }}"
                                    codeListName="Flag"></form-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <form-textbox
                                    formControlName="cargoDescription"
                                    labelText="{{ 'Cargo Description' | translate }}"></form-textbox>
                            </div>
                            <div class="col-3">
                                <form-checkbox
                                    formControlName="carriesDangerousGoods"
                                    labelText="{{ 'Dangerous Cargo' | translate }}"></form-checkbox>
                            </div>
                            <div class="col-3">
                                <form-checkbox
                                    formControlName="selfPropelled"
                                    labelText="{{ 'Self-Propelled' | translate }} ?"></form-checkbox>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-primary" [disabled]="!form.valid" (click)="saveVehicle()">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
                &nbsp;&nbsp;{{ 'Save' | translate }}
            </button>
            <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                &nbsp;&nbsp;{{ 'Close' | translate }}
            </button>
        </div>
    </ng-template>
</app-loader>
