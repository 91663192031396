import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {
    constructor(private http: HttpClient) {}

    getAttachmentSettings(): Observable<any> {
        return this.http.get('api:///attachment/GetAttachmentSettings');
    }

    getAttachmentSettingsForUpload(): Observable<any> {
        return this.http.get('api:///attachment/GetAttachmentSettingsForUpload');
    }

    getAttachmentSize(): Observable<any> {
        return this.http.get('api:///attachment/GetAttachmentSize');
    }

    editAttachments(model: any): Observable<any> {
        return this.http.post('api:///attachment/EditAttachments', model);
    }

    editAttachmentSize(attachmentSize: any): Observable<any> {
        return this.http.get(`api:///attachment/EditAttachmentSize/${attachmentSize}`);
    }

    // Will be moved
    getCertificateLogin(): Observable<any> {
        return this.http.get('api:///attachment/GetCertificateLogin');
    }

    setCertificateLogin(certificateLogin: any): Observable<any> {
        return this.http.get(`api:///attachment/SetCertificateLogin/${certificateLogin}`);
    }

    getAttachments(entity: string, id: number): Observable<any> {
        return this.http.get(`api:///attachment/GetAttachments/${entity}/${id}`);
    }

    addAttachment(model: any) {
        return this.http.post<any>(`api:///attachment/AddAttachment`, model);
    }

    removeAttachment(model: any) {
        return this.http.post<any>(`api:///attachment/RemoveAttachment`, model);
    }
}
