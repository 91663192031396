import { Directive } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export class UserRegistrationText {
    completeUserRegistration: string;
    registrationFormDownload: string;

    constructor(private translateService: TranslateService) {
        this.completeUserRegistration = this.translateService.instant(
            marker('BulPCS Administrator will review your request. We thank you for your patience.')
        );
    }
}
