import { flatMap, includes, intersection, map, uniq } from 'lodash-es';
import { CodelistDisplayModel } from './common/CodelistDisplay.model';

export class UserModel {
    id: number;
    userName: string;
    fullName: string;
    email: string;
    active = true;
    organization: any;
    roles: any[];
    language: CodelistDisplayModel;
    userAssignedPorts: string[];
    systemId: string;
    systemIds: string[];
    signInType: string;

    /** FullPermissionNames from user role permissions and organization role permissions */
    private allPermissions: string[];

    /** Role names from user roles and organization roles */
    private allRoles: string[];

    constructor(data: any) {
        Object.assign(this, data);

        const userRoles = flatMap(this.roles, (x) => x.role);

        const firstLevel = flatMap(userRoles, (x) => x.permissions);
        const userPermissions = flatMap(firstLevel, (x) => x);

        let organizationPermissions: any[] = [];
        let organizationRoles: any[] = [];

        if (this.organization != null) {
            organizationRoles = this.organization?.roles;
            organizationPermissions = flatMap(organizationRoles, (x) => x.permissions);
        }

        const all = uniq([...userPermissions, ...organizationPermissions]);

        const roles = uniq([...userRoles, ...organizationRoles]);
        this.allRoles = uniq(map(roles, (x) => x.name));

        this.allPermissions = flatMap(all, (x) => x);
    }

    hasPermission(permission: string) {
        return includes(this.allPermissions, permission);
    }

    hasAnyPermission() {
        return this.allPermissions?.length > 0;
    }

    hasAtLeastOnePermission(permissions: string[]) {
        const itemsFound = intersection(this.allPermissions, permissions);
        return itemsFound.length > 0;
    }

    hasRole(role: string) {
        return includes(this.allRoles, role);
    }

    belongsToOrganization(organization: any): boolean {
        return this.organization.id === Number(organization.id);
    }
}
