import { CommonModule as NgCommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PcsAdditionalApprovalComponent } from '@common/components/approvals/tab-pcs-additional-approvals/pcs-additional-approval/pcs-additional-approval.component';
import { TabPcsAdditionalApprovalsComponent } from '@common/components/approvals/tab-pcs-additional-approvals/tab-pcs-additional-approvals.component';
import { AdditionalDataInputComponent } from '@common/components/approvals/tabs-approval-documents/pcs-tab-additional-data/additional-data-input/additional-data-input.component';
import { PcsApprovalTabAdditionalDataComponent } from '@common/components/approvals/tabs-approval-documents/pcs-tab-additional-data/pcs-approval-tab-additional-data.component';
import { PcsApprovalTabAttachmentsComponent } from '@common/components/approvals/tabs-approval-documents/pcs-tab-attachments/pcs-approval-tab-attachments.component';
import { PcsApprovalTabNotificationsComponent } from '@common/components/approvals/tabs-approval-documents/pcs-tab-notifications/pcs-approval-tab-notifications.component';
import { PcsApprovalTabRemarksComponent } from '@common/components/approvals/tabs-approval-documents/pcs-tab-remarks/pcs-approval-tab-remarks.component';
import { PcsApprovalTabActionsStatusChangesComponent } from '@components/approvals/tabs-approval-documents/pcs-tab-actions-status-changes/pcs-approval-tab-actions-status-changes.component';
import { DatetimerangeComponent } from '@components/form/datetimerange.component';
import { ChatWindowExpandNoteComponent } from '@components/tab-chat/chat-window-expand-note/chat-window-expand-note.component';
import { ChatWindowNewNoteComponent } from '@components/tab-chat/chat-window-new-note/chat-window-new-note.component';
import { TabChatComponent } from '@components/tab-chat/tab-chat.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { BadgeModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DrawerModule, LayoutModule } from '@progress/kendo-angular-layout';
import { AppBarModule } from '@progress/kendo-angular-navigation';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { DatetimerangepickerModule } from 'angular-datetimerangepicker';
import { CancelMovesModalComponent } from 'app/cargo-operations/modals/cancel-moves-modal/cancel-moves-modal.component';
import { ConfirmMovesModalComponent } from 'app/cargo-operations/modals/confirm-moves-modal/confirm-moves-modal.component';
import { UnconfirmMovesModalComponent } from 'app/cargo-operations/modals/unconfirm-moves-modal/unconfirm-moves-modal.component';
import { ChatListComponent } from 'app/chat/chat-list/chat-list.component';
import { TabEmailNotificationsComponent } from 'app/profile/components/tabs/tab-email-notifications/tab-email-notifications.component';
import { ServiceRequestItemsHoldModalComponent } from 'app/tos-operations/service-request/modals/service-request-items-hold-modal/service-request-items-hold-modal.component';
import { SaveGeneralActModalComponent } from 'app/vessel-visit/general-act/modals/save-general-act-modal/save-general-act-modal.component';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppControlComponent } from './components/app-control/app-control.component';
import { AttachmentListDialogComponent } from './components/attachment-list-dialog/attachment-list-dialog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.compontent';
import { ButtonLoaderComponent } from './components/button-loader/button-loader.component';
import { ChangeLanguageHeaderComponent } from './components/change-language-header/change-language-header.component';
import { ChangesDialogComponent } from './components/changes-dialog.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from './components/confirm-dialog';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { DataCardComponent } from './components/data-card/data-card.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { DropdownAddComponent } from './components/dropdown-add/dropdown-add.component';
import { ErrorComponent } from './components/error/error.component';
import { FileUploadGenericModalComponent } from './components/file-upload-generic-modal/file-upload-generic-modal-component';
import { FlagComponent } from './components/flag/flag.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AutoFormComponent } from './components/form/auto-form.component';
import { CheckComponent } from './components/form/checkbox.component';
import { DateComponent } from './components/form/date.component';
import { DatetimeComponent } from './components/form/datetime.component';
import { DropDownComponent } from './components/form/dropdown.component';
import { NumberComponent } from './components/form/number.component';
import { RadioComponent } from './components/form/radio.component';
import { TextareaComponent } from './components/form/textarea.component';
import { TextboxComponent } from './components/form/textbox.component';
import { TimeComponent } from './components/form/time.component';
import { ChangesTabComponent } from './components/generic-changes/changes-tab.component';
import { GenericFormComponent } from './components/generic-form/generic-filters.component';
import { GridCodelistComponent } from './components/grid-codelist/grid-codelist.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { InfoElementComponent } from './components/info-element/info-element.component';
import { InlineCheckboxComponent } from './components/inline-checkbox/inline-checkbox.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginOidcCallbackComponent } from './components/login-oidc-callback/login-oidc-callback.component';
import { CertificateErrorComponent } from './components/login/certificateError/certificate-error.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout.component';
import { RegistrationFormDownloadComponent } from './components/registration-form-download/registration-form-download.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RsReportComponent } from './components/rs-report/rs-report.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SsoLoginComponent } from './components/sso-login/sso-login.component';
import { TabAttachmentsComponent } from './components/tab-attachments/tab-attachments.component';
import { TabNotificationsComponent } from './components/tab-notifications/tab-notifications.component';
import { TitleComponent } from './components/title/title.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UserCreateComponent } from './components/user-create/user-create.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { PreventMultiClickDirective } from './directives/prevent-multiclick.directive';
import { StripAllWhitespaceDirective } from './directives/stripAllWhitespace.directive';
import { ErrorDialogComponent } from './error-dialog.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthorizationGuard } from './guards/authorization.guard';
import { LoginGuard } from './guards/login.guard';
import { UserCreateGuard } from './guards/user-create.guard';
import { AppHttpInterceptor } from './interceptors/http-interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error-interceptor';
import { AddContainersFromBulPCSComponent } from './modals/add-containers-from-bulpcs/add-containers-from-bulpcs.component';
import { AddContainersFromTosComponent } from './modals/add-containers-from-tos/add-containers-from-tos.component';
import { AddDeliveryOrderItemComponent } from './modals/add-delivery-order-item/add-delivery-order-item.component';
import { AddDtsComponent } from './modals/add-dts/add-dts.component';
import { CertificateSelectionDialogComponent } from './modals/certificate-selection/certificate-selection.component';
import { EditDeliveryOrderComponent } from './modals/edit-delivery-order/edit-delivery-order.component';
import { EntitiesErrorsComponent } from './modals/entities-errors/entities-errors.component';
import { SaveCargoItemComponent } from './modals/save-cargo-item/save-cargo-item.component';
import { UpdateDtsComponent } from './modals/update-dts/update-dts.component';
import { AglLoggingService } from './services/agl-logging.service';
import { AuthenticationService } from './services/authentication.service';
import { ContainerValidationService } from './services/container-validation.service';
import { FormService } from './services/form.service';
import { ModalService } from './services/modal.service';
import { OrganizationService } from './services/organization.service';
import { QuickReportsService } from './services/quick-reports.service';
import { RoleService } from './services/role.service';
import { ServiceRequestService } from './services/service-request.service';
import { TitleService } from './services/title.service';
import { UserService } from './services/user.service';
import { VesselVisitService } from './services/vessel-visit.service';

@NgModule({
    imports: [
        NgxMaskModule.forRoot(),
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        GridModule,
        PDFModule,
        ExcelModule,
        DateInputsModule,
        DropDownsModule,
        InputsModule,
        TooltipModule,

        TranslateModule,
        FontAwesomeModule,
        PopupModule,
        LayoutModule,
        LabelModule,
        DialogModule,
        WindowModule,
        ButtonsModule,
        AppBarModule,
        BadgeModule,
        DrawerModule,
        DatetimerangepickerModule,
        NgbModule,
        NgxFlagIconCssModule,
        FileSelectModule,
        NgxJsonViewerModule,
        NgxSpinnerModule,
        PDFExportModule
    ],
    exports: [
        NgxSpinnerModule,
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        PDFModule,
        ExcelModule,
        DateInputsModule,
        DropDownsModule,
        InputsModule,
        TooltipModule,
        DialogModule,
        WindowModule,
        NgbModule,
        LayoutModule,
        LabelModule,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        AppControlComponent,
        CheckboxComponent,
        RadioComponent,
        InlineCheckboxComponent,
        LoaderComponent,
        GenericFormComponent,
        DropdownAddComponent,
        DropDownComponent,
        AutoFormComponent,
        CheckComponent,
        DateComponent,
        DatetimeComponent,
        DatetimerangeComponent,
        TimeComponent,
        NumberComponent,
        TextboxComponent,
        TextareaComponent,
        TitleComponent,
        TooltipComponent,
        TranslateModule,
        InfoElementComponent,
        InfoCardComponent,
        GridCodelistComponent,
        RsReportComponent,
        FontAwesomeModule,
        AutofocusDirective,
        StripAllWhitespaceDirective,
        PreventMultiClickDirective,
        ChangesTabComponent,
        ButtonLoaderComponent,
        DataCardComponent,
        PopupModule,
        ButtonsModule,
        DrawerModule,
        DrawerComponent,
        FlagComponent,
        NgxFlagIconCssModule,
        TabChatComponent,
        ChatWindowNewNoteComponent,
        ChatWindowExpandNoteComponent,
        NgxFlagIconCssModule,
        ContentHeaderComponent,
        PcsAdditionalApprovalComponent,
        AdditionalDataInputComponent,
        TabPcsAdditionalApprovalsComponent,
        FileSelectModule,
        AttachmentListDialogComponent,
        TabEmailNotificationsComponent,
        NgxJsonViewerModule,
        TabAttachmentsComponent,
        ConfirmMovesModalComponent,
        UnconfirmMovesModalComponent,
        CancelMovesModalComponent,
        PDFExportModule,
        SaveGeneralActModalComponent
    ],
    declarations: [
        LoginComponent,
        SsoLoginComponent,
        CertificateErrorComponent,
        LoginOidcCallbackComponent,
        LogoutComponent,
        UserCreateComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        AppControlComponent,
        CheckboxComponent,
        RadioComponent,
        InlineCheckboxComponent,
        LoaderComponent,
        ConfirmDialogComponent,
        ChangesDialogComponent,
        GenericFormComponent,
        DropdownAddComponent,
        DropDownComponent,
        AutoFormComponent,
        CheckComponent,
        DateComponent,
        DatetimeComponent,
        DatetimerangeComponent,
        TimeComponent,
        NumberComponent,
        TextboxComponent,
        TextareaComponent,
        TitleComponent,
        TooltipComponent,
        AddDtsComponent,
        UpdateDtsComponent,
        CertificateSelectionDialogComponent,
        InfoElementComponent,
        InfoCardComponent,
        GridCodelistComponent,
        RsReportComponent,
        RegistrationFormDownloadComponent,
        AutofocusDirective,
        StripAllWhitespaceDirective,
        PreventMultiClickDirective,
        ChangesTabComponent,
        ButtonLoaderComponent,
        DataCardComponent,
        ErrorComponent,
        ErrorDialogComponent,
        EntitiesErrorsComponent,
        DrawerComponent,
        FlagComponent,
        TabChatComponent,
        ChatWindowNewNoteComponent,
        ChatWindowExpandNoteComponent,
        FlagComponent,
        ContentHeaderComponent,
        SaveCargoItemComponent,
        AddContainersFromTosComponent,
        AddContainersFromBulPCSComponent,
        FileUploadGenericModalComponent,
        ServiceRequestItemsHoldModalComponent,
        TabPcsAdditionalApprovalsComponent,
        PcsAdditionalApprovalComponent,
        PcsApprovalTabActionsStatusChangesComponent,
        PcsApprovalTabNotificationsComponent,
        PcsApprovalTabRemarksComponent,
        PcsApprovalTabAttachmentsComponent,
        PcsApprovalTabAdditionalDataComponent,
        AdditionalDataInputComponent,
        AttachmentListDialogComponent,
        EditDeliveryOrderComponent,
        ChatListComponent,
        AddDeliveryOrderItemComponent,
        TabEmailNotificationsComponent,
        TabNotificationsComponent,
        ChangeLanguageHeaderComponent,
        TabAttachmentsComponent,
        ConfirmMovesModalComponent,
        UnconfirmMovesModalComponent,
        CancelMovesModalComponent,
        SaveGeneralActModalComponent
    ],
    providers: [
        AuthenticationService,
        FormService,
        UserService,
        VesselVisitService,
        QuickReportsService,
        OrganizationService,
        TitleService,
        ServiceRequestService,
        ModalService,
        RoleService,
        ContainerValidationService,
        AglLoggingService,
        AuthGuard,
        AuthorizationGuard,
        LoginGuard,
        DatePipe,
        UserCreateGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far);
    }
}
