<div class="fixed-background" id="imageDiv">
    <div class="login-wrap">
        <div class="login-html" [ngClass]="{ 'login-html-size': isMobile }">
            <div class="row">
                <div class="col">
                    <img *ngIf="translateService.currentLang === 'en'" src="../../../../assets/img/eu.svg" alt="EU" />
                    <img *ngIf="translateService.currentLang === 'bg'" src="../../../../assets/img/eu2.svg" alt="EU" />
                </div>
                <div class="col">
                    <img
                        *ngIf="translateService.currentLang === 'en'"
                        src="../../../../assets/img/trasnport2.svg"
                        alt="Transport" />
                    <img
                        *ngIf="translateService.currentLang === 'bg'"
                        src="../../../../assets/img/transport.svg"
                        alt="Transport" />
                </div>
            </div>
            <div *ngIf="translateService.currentLang === 'en'" class="row text-center bottom-text">
                The web portal is developed and maintained under project Development and implementation of a system for
                electronic exchange of information in the Bulgarian ports (Port Community System – PCS)
            </div>
            <div *ngIf="translateService.currentLang === 'bg'" class="row text-center bottom-text">
                Тази интернет страница е разработена по проект Разработване и внедряване на система за електронен обмен
                на информация в българските пристанища (Port Community System – PCS)
            </div>

            <div class="login-form tab">
                <app-loader [isBusy]="false">
                    <ng-template>
                        <form
                            class="form-forgot-pass"
                            [formGroup]="form"
                            [ngClass]="{ 'login-mobile-width': isMobile }"
                            (ngSubmit)="reset()">
                            <div class="sign-in-htm" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <p class="mt-1">
                                    {{
                                        'Please enter the email address associated with your account. We will send you a link to reset your password.'
                                            | translate
                                    }}
                                </p>
                                <div class="group">
                                    <label class="label" for="pass">{{ 'Email' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                                        <form-textbox class="input-width" formControlName="email"></form-textbox>
                                    </div>
                                </div>

                                <div class="group">
                                    <button class="button" type="submit" translate [disabled]="!form.valid">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </app-loader>
            </div>
        </div>
    </div>
</div>
