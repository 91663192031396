import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ExtendedBaseTab } from '@common/classes/extended-base-tab';
import { PasswordValidationService } from '@common/services/password-validation.service';
import { currentUserSubject, UserService } from '@common/services/user.service';
import { requiredValidator } from '@common/validators/required-validator';
import { CustomExtendedTabService } from 'app/singleton-services/custom-extended-tab.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends ExtendedBaseTab implements OnInit {
    isBusy = false;
    username: string;
    code: string;
    title: string;

    breadcrumb = [
        {
            icon: 'user',
            title: this.translateService.get('Profile'),
            route: '/profile'
        },
        {
            icon: 'user-lock',
            title: this.translateService.get('Change Password')
        }
    ];

    form = new UntypedFormGroup({
        newPassword: new UntypedFormControl({ value: null, disabled: false }, requiredValidator()),
        confirmPassword: new UntypedFormControl({ value: null, disabled: false }, requiredValidator())
    });

    constructor(
        private userService: UserService,
        private toastyService: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private passwordValidationService: PasswordValidationService,
        customExtendedTabService: CustomExtendedTabService
    ) {
        super(customExtendedTabService);
    }

    ngOnInit() {
        this.username = this.activatedRoute.snapshot.params.username;
        this.code = this.activatedRoute.snapshot.params.code;
        this.title = this.translateService.instant(marker('Change Password'));
    }

    resetPassword() {
        const body = this.form.getRawValue();
        const currentUser = currentUserSubject.getValue();

        if (this.passwordValidationService.isPasswordValid(body.newPassword, body.confirmPassword)) {
            this.userService.resetPassword({ ...body, username: this.username, code: this.code }).subscribe({
                next: () => {
                    this.toastyService.success(this.translateService.instant(marker('Password reset successfully')));
                    if (!currentUser) this.router.navigate(['/login']);
                    else this.router.navigate(['/logout']);
                },
                error: (error) => {
                    (error.error.errors.confirmPassword || error.error.errors).forEach((errorMessage) => {
                        this.toastyService.error(
                            this.translateService.instant(marker(errorMessage)),
                            this.translateService.instant(marker('One or more validation errors occurred.'))
                        );
                    });
                }
            });
        }
    }
}
