import { Component } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="message"></div>
        <div class="modal-footer">
            <button class="btn btn-sm btn-danger" translate (click)="activeModal.close()">Don't save</button>
            <button class="btn btn-sm btn-primary" translate (click)="activeModal.close(true)">Save</button>
        </div>
    `
})
export class ChangesDialogComponent {
    public title: string;
    public message: string;

    constructor(public activeModal: DialogRef) {}
}
