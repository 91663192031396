import { NoticeBoardService } from '@administration/notice-board/notice-board.service';
import { Component, OnInit } from '@angular/core';
import { ExtendedBaseTab } from '@common/classes/extended-base-tab';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CustomExtendedTabService } from 'app/singleton-services/custom-extended-tab.service';
import { orderBy } from 'lodash-es';
import { AttachmentListDialogComponent } from '../attachment-list-dialog/attachment-list-dialog.component';

@Component({
    selector: 'app-tab-notifications',
    templateUrl: './tab-notifications.component.html',
    styleUrls: ['./tab-notifications.component.scss']
})
export class TabNotificationsComponent extends ExtendedBaseTab implements OnInit {
    notifications: any[] = [];

    constructor(
        public activeModal: DialogRef,
        private service: NoticeBoardService,
        private kendoDialogService: DialogService,
        customExtendedTabService: CustomExtendedTabService
    ) {
        super(customExtendedTabService);
    }

    ngOnInit(): void {
        this.defaultPaging();
        this.getNotifications();
    }

    // this is not to include fitColumns in the grid
    loadItems(model: any) {
        const ordered = orderBy(model, this.sort);

        this.itemsGridView = {
            data: ordered.slice(this.itemPage.skip, this.itemPage.skip + this.itemPage.pageSize),
            total: model.length
        };
        this.isBusy = false;
    }

    public openAttachment(rowId, attachments) {
        const dialog = this.kendoDialogService.open({
            content: AttachmentListDialogComponent
        });
        const attachmentDialog = dialog.content.instance;
        attachmentDialog.notificationAttachments = attachments;
        attachmentDialog.openedAttachmentId = rowId;
    }

    private getNotifications() {
        this.isBusy = true;
        this.service.getNotificationsOnLogin().subscribe((response) => {
            this.notifications = response;
            this.loadItems(this.notifications);
            this.isBusy = false;
        });
    }
}
