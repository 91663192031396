import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CodelistService } from '@common/services/codelist.service';
import { ModalService } from '@common/services/modal.service';
import { VesselService } from '@common/services/vessel.service';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';

@Component({
    selector: 'app-add-vessel',
    templateUrl: './add-vessel.component.html',
    styleUrls: ['./add-vessel.component.scss']
})
export class AddVesselComponent implements OnInit {
    isBusy: boolean;
    form: UntypedFormGroup;
    isEditMode = false;
    vesselId: number;
    vesselModel: any;

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public vesselService: VesselService,
        public codelistService: CodelistService
    ) {}

    ngOnInit() {
        this.form = this.createFormGroup();
        this.checkEditMode();
    }

    public addNewVessel() {
        const confirmMessage = this.isEditMode
            ? (this.translateService.instant(marker('Are you sure you want to update the vessel?')) as string)
            : (this.translateService.instant(marker('Are you sure you want to create the vessel?')) as string);
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Confirm')) as string,
                confirmMessage,
                this.translateService.instant(marker('YES')) as string,
                this.translateService.instant(marker('No')) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe(() => {
                const body = { ...this.form.getRawValue(), id: this.vesselId };
                if (this.isEditMode) {
                    this.vesselService.addTemporaryVessel(body).subscribe({
                        next: () => {
                            this.toastyService.success(
                                this.translateService.instant(marker('Vessel updated successfully'))
                            );
                            this.activeModal.close();
                        }
                    });
                } else {
                    this.vesselService.addTemporaryVessel(body).subscribe((response) => {
                        this.toastyService.success(
                            this.translateService.instant(marker('Vessel created successfully'))
                        );
                        this.activeModal.close({ id: response.id });
                    });
                }
            });
    }

    private createFormGroup() {
        const form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            imo: new UntypedFormControl(null, requiredValidator()),
            name: new UntypedFormControl(null, requiredValidator())
        });
        form.patchValue(this);
        return form;
    }

    private checkEditMode() {
        if (this.vesselId) {
            // Check if vesselId is set
            this.isEditMode = true;
            this.form.get('imo').disable();
            this.loadVessel(this.vesselId);
        }
    }

    private loadVessel(vesselId: number) {
        this.vesselService.getVesselDetails(vesselId).subscribe({
            next: (response) => {
                this.vesselModel = response;
                this.form.patchValue(response);
            }
        });
    }
}
