import { Injectable, NgZone } from '@angular/core';
import { ModalService } from '@common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CustomExtendedTabService {
    constructor(
        private dialogService: ModalService,
        private translateService: TranslateService,
        private ngZone: NgZone
    ) {}

    getDialogService(): ModalService {
        return this.dialogService;
    }

    getTranslateService(): TranslateService {
        return this.translateService;
    }

    getNgZone(): NgZone {
        return this.ngZone;
    }
}
