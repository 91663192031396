import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { UserModel } from '@common/models/User.model';
import { InstanceTrackerService } from '@common/services/instance-tracker.service';
import { StartupService } from '@common/services/startup.service';
import { currentUserSubject } from '@common/services/user.service';
import { isMobile } from '@common/utils/isMobile';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { TitleService } from './common/services/title.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    currentUser: UserModel;
    isSidebarCollapsed = false;

    get isAuthenticated() {
        return !!this.currentUser;
    }

    get isBusy() {
        return this.startupService.isBusy;
    }

    get setupStatus() {
        return this.startupService.status;
    }

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private startupService: StartupService,
        private messageService: MessageService,
        private instanceTracker: InstanceTrackerService,
        titleService: TitleService
    ) {
        // title update subscribe
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const title = this.getDeepestTitle(this.router.routerState.snapshot.root);
                titleService.setTitle(title);
            }
        });

        // subscribe to current user updates
        currentUserSubject.subscribe((value) => {
            this.currentUser = value;

            // if (this.currentUser) {
            //     try {
            //         const lang = this.currentUser.language.id.toLowerCase();
            //         this.translateService.use(lang);
            //         const msgs = <KendoMessageService> this.messageService;
            //         msgs.language = lang;
            //     } catch {}
            // }
        });
    }

    ngOnInit() {
        const count = this.instanceTracker.register();
        sessionStorage.setItem('isRefreshed', 'true');

        window.addEventListener('beforeunloadCustom', () => {
            sessionStorage.removeItem('isRefreshed');
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        const count = this.instanceTracker.unregister();

        if (sessionStorage.getItem('isRefreshed') !== 'true') {
            window.localStorage.setItem('count', count.toString());
            if (count === 0) {
                window.localStorage.setItem('loggedout', 'true');
            }
        }

        window.dispatchEvent(new Event('beforeunloadCustom'));
    }

    ngOnDestroy() {
        const count = this.instanceTracker.unregister();
        if (count == 0) {
            window.localStorage.setItem('loggedout', 'true');
        }
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = routeSnapshot.data ? routeSnapshot.data['title'] : '';

        if (routeSnapshot.firstChild) {
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
        }

        return title;
    }

    getMainContentMargin(isCollapsed) {
        if (this.isAuthenticated) {
            if (isMobile()) {
                return '0px';
            }

            if (isCollapsed) {
                return '70px';
            } else {
                return '260px';
            }
        } else {
            return '0px';
        }
    }
}
