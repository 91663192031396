<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="row justify-content-md-center">
            <div class="col-sm-auto">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-center">{{ 'Reset Password' | translate }}</h6>
                        <ul>
                            <li class="pt-1 info-helper-text small">
                                {{
                                    'password should be unique, do not use the same password for multiple systems'
                                        | translate
                                }}
                            </li>
                            <li class="pt-1 info-helper-text small">
                                {{
                                    'password should be complex, do not use name, surname, pcs, birthdays or 123 in your password'
                                        | translate
                                }}
                            </li>
                            <li class="pt-1 info-helper-text small">
                                {{
                                    'password should be made out of 2 or more unrelated words or random characters, more meaning a safer password'
                                        | translate
                                }}
                            </li>
                        </ul>
                        <form class="form-reset-pass" [formGroup]="form" (ngSubmit)="resetPassword()">
                            <form-textbox
                                passwordAutocomplete="off"
                                labelText="{{ 'New Password' | translate }}"
                                formControlName="newPassword"
                                contextual="{{
                                    'Minimum of 9 characters including lower and capital letter, a number, and a special character.'
                                        | translate
                                }}"
                                [textboxType]="'password'"></form-textbox>
                            <form-textbox
                                passwordAutocomplete="new-password"
                                labelText="{{ 'Confirm password' | translate }}"
                                formControlName="confirmPassword"
                                [textboxType]="'password'"></form-textbox>
                            <button
                                class="btn btn-md btn-primary btn-block mt-3"
                                type="submit"
                                [disabled]="!form.valid">
                                {{ 'Reset Password' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-loader>
