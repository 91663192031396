<div class="form-group" [ngClass]="{ 'has-value': hasValue && AppControlType.Boolean !== type, 'mb-0': !margin }">
    <div *ngIf="contextual">
        <tooltip orientation="left" text="{{ contextual | translate }}"></tooltip>
    </div>
    <label
        *ngIf="label && type !== AppControlType.Boolean && !(type === AppControlType.CodeList && multi)"
        class="col-form-label"
        [ngClass]="{
            'label-star': !isDisabled && nullable === false,
            'label-bg': type === AppControlType.TextArea,
            'disabled-bg': isDisabled
        }"
        [for]="property">
        {{ label }}
    </label>

    <input
        *ngIf="!static && type === AppControlType.String"
        class="k-textbox form-control form-control-sm"
        type="text"
        kendoTextBox
        placement="top"
        placeholder="{{ placeholder }}"
        tooltipClass="error-tooltip"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        (keydown)="handleTextboxKeydown($event)"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)" />

    <textarea
        *ngIf="!static && type === AppControlType.Textarea"
        class="k-textarea form-control form-control-sm"
        type="text"
        kendoTextArea
        placement="top"
        placeholder="{{ placeholder }}"
        tooltipClass="error-tooltip"
        [autoSize]="true"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)"></textarea>

    <div *ngIf="!static && type === AppControlType.Password" class="input-group">
        <input
            *ngIf="!static && type === AppControlType.Password"
            class="k-textbox form-control form-control-sm"
            kendoTextBox
            placeholder="{{ placeholder }}"
            placement="top"
            tooltipClass="error-tooltip"
            [type]="fieldTextType ? 'text' : 'password'"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [autocomplete]="passwordAutocomplete"
            [(ngModel)]="value"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)" />
        <span *ngIf="!static && type === AppControlType.Password" class="eye-icon" tabindex="0">
            <i
                class="fa"
                [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }"
                (click)="toggleFieldTextType()"></i>
        </span>
    </div>

    <input
        *ngIf="!static && type === AppControlType.Number && decimal === false && forFilter === false"
        class="form-control form-control-sm"
        type="text"
        placeholder="{{ placeholder }}"
        placement="top"
        pattern="[0-9]"
        tooltipClass="error-tooltip"
        mask="separator.0"
        thousandSeparator="."
        [allowNegativeNumbers]="true"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)" />

    <input
        *ngIf="!static && type === AppControlType.Number && decimal === true && forFilter === false"
        class="form-control form-control-sm"
        type="text"
        placeholder="{{ placeholder }}"
        step="0.01"
        placement="top"
        tooltipClass="error-tooltip"
        mask="separator.3"
        thousandSeparator="."
        decimalMarker=","
        [allowNegativeNumbers]="true"
        [min]="min"
        [max]="max"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)" />

    <input
        *ngIf="!static && type === AppControlType.Number && forFilter === true && decimal === false"
        class="form-control form-control-sm"
        type="number"
        placeholder="{{ placeholder }}"
        placement="top"
        tooltipClass="error-tooltip"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)" />

    <div *ngIf="!static && type === AppControlType.DateTime && kendoType === 'date'">
        <i
            *ngIf="dateValue && !isDisabled"
            class="k-icon k-clear-value k-i-close date-time-close noborder"
            placement="bottom"
            [ngClass]="{ 'date-time-close-table': isInTable }"
            (click)="dateValue = null"></i>
        <kendo-datepicker
            class="k-datetimepicker noborder form-control form-control-sm pcs-control"
            #datePicker
            placeholder=""
            placement="top"
            tooltipClass="error-tooltip"
            format="dd.MM.yyyy"
            [navigation]="false"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [formatPlaceholder]="dateFormatPlaceholder"
            [(ngModel)]="dateValue"
            (valueChange)="dateTimePickerValueChange($event)"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)">
            <kendo-datetimepicker-messages
                today="{{ 'Today' | translate }}"
                dateTab="{{ 'Date' | translate }}"
                timeTab="{{ 'Time' | translate }}"
                now="{{ 'Now' | translate }}"
                accept="{{ 'Accept' | translate }}"
                cancel="{{ 'Cancel' | translate }}"></kendo-datetimepicker-messages>
        </kendo-datepicker>
    </div>

    <div *ngIf="!static && type === AppControlType.DateTime && kendoType === 'datetime'">
        <i
            *ngIf="dateValue && !isDisabled"
            class="k-icon k-clear-value k-i-close date-time-close noborder"
            placement="bottom"
            [ngClass]="{ 'date-time-close-table': isInTable }"
            (click)="dateValue = null"></i>
        <kendo-datetimepicker
            class="k-datetimepicker noborder form-control form-control-sm pcs-control"
            #dateTimePicker
            placeholder=""
            placement="top"
            tooltipClass="error-tooltip"
            format="dd.MM.yyyy HH:mm"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [formatPlaceholder]="dateTimeFormatPlaceholder"
            [(ngModel)]="dateValue"
            (valueChange)="dateTimePickerValueChange($event)"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)">
            <kendo-datetimepicker-messages
                today="{{ 'Today' | translate }}"
                dateTab="{{ 'Date' | translate }}"
                timeTab="{{ 'Time' | translate }}"
                now="{{ 'Now' | translate }}"
                accept="{{ 'Accept' | translate }}"
                cancel="{{ 'Cancel' | translate }}"></kendo-datetimepicker-messages>
        </kendo-datetimepicker>
    </div>

    <div *ngIf="!static && type === AppControlType.DateTime && kendoType === 'time'">
        <i
            *ngIf="dateValue && !isDisabled"
            class="k-icon k-clear-value k-i-close date-time-close noborder"
            placement="bottom"
            [ngClass]="{ 'date-time-close-table': isInTable }"
            (click)="dateValue = null"></i>
        <kendo-timepicker
            class="noborder form-control form-control-sm pcs-control"
            placeholder=""
            placement="top"
            tooltipClass="error-tooltip"
            format="HH:mm"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [formatPlaceholder]="timeFormatPlaceholder"
            [(ngModel)]="dateValue"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)"></kendo-timepicker>
    </div>

    <!-- <app-checkbox
        *ngIf="!static && type === AppControlType.Boolean"
        [size]="size"
        [(ngModel)]="value"
        [disabled]="isDisabled"
    ></app-checkbox> -->
    <app-checkbox
        *ngIf="type === AppControlType.Boolean"
        [label]="label"
        [size]="size"
        [disabled]="isDisabled"
        [click]="click"
        [(ngModel)]="value"
        (keyup.enter)="onKeyUp($event)"></app-checkbox>
    <div *ngIf="type === AppControlType.Static" class="form-control-plaintext form-control-sm">
        {{ value }}
    </div>

    <div *ngIf="showFeedback && errors.length > 0" class="invalid-feedback">
        {{ errors[0].errorMessage }}
    </div>
</div>
