<div *ngIf="isModal" class="model-header">
    <h4 class="modal-title">{{ 'Service Request List for MRN' | translate }} {{ mrn }}</h4>
</div>

<div class="row mt-3">
    <div class="col">
        <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <div
            kendoTooltip
            showOn="none"
            filter=".k-grid td"
            [tooltipTemplate]="template"
            (mouseover)="showTooltip($event)">
            <kendo-grid
                class="mb-2"
                [data]="itemsGridView"
                [skip]="itemPage.skip"
                [pageSize]="itemPage.pageSize"
                [pageable]="pagingOptions"
                [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
                [loading]="isBusy"
                [sort]="sort"
                [scrollable]="'true'"
                [resizable]="true"
                (pageChange)="pageChange($event, serviceRequests)"
                (sortChange)="sortChange($event, serviceRequests)">
                <kendo-grid-column title="{{ 'Number' | translate }}" field="number" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        <span *ngIf="!hasPermission('TosOperations.ServiceRequest.Search')">
                            {{ row.number }}
                        </span>
                        <a
                            *ngIf="hasPermission('TosOperations.ServiceRequest.Search')"
                            class="row-link"
                            (click)="navigateToServiceRequest(row.number)">
                            {{ row.number }}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Type' | translate }}" field="type" [width]="100"></kendo-grid-column>
                <kendo-grid-column
                    title="{{ 'Manipulation' | translate }}"
                    field="manipulation"
                    [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{ 'Status' | translate }}" field="status.id" [width]="80">
                    <ng-template kendoGridCellTemplate let-row>
                        <div class="row">
                            <span class="pill" [ngClass]="getClass(row.status.id)">{{ row.status.localizedCode }}</span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    title="{{ 'TOS Number' | translate }}"
                    field="tosNumber"
                    [width]="130"></kendo-grid-column>
                <kendo-grid-column title="{{ 'Created Date' | translate }}" field="createdDate" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{ row.createdDate | date: 'dd.MM.yyyy' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Completed Date' | translate }}" field="completedDate" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{ row.completedDate | date: 'dd.MM.yyyy' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    title="{{ 'Customer' | translate }}"
                    field="customer"
                    [width]="150"></kendo-grid-column>
                <kendo-grid-column title="{{ 'Net Weight (kg)' | translate }}" field="netWeight" [width]="150">
                    <ng-template kendoGridCellTemplate let-row>
                        {{ row.netWeight | number: '1.3-3' : 'de' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Gross Weight (kg)' | translate }}" field="grossWeight" [width]="150">
                    <ng-template kendoGridCellTemplate let-row>
                        {{ row.grossWeight | number: '1.3-3' : 'de' }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Volume' | translate }}" field="volume" [width]="100"></kendo-grid-column>
                <kendo-grid-column
                    title="{{ 'Terminal' | translate }}"
                    field="terminal"
                    [width]="120"></kendo-grid-column>
                <kendo-grid-column
                    title="{{ 'Terminal Operator' | translate }}"
                    field="terminalOperator"
                    [width]="120"></kendo-grid-column>
                <ng-template kendoGridDetailTemplate let-dataItem>
                    <section>
                        <div class="card windowcard bg-white">
                            <div class="card-body">
                                <kendo-grid
                                    [data]="dataItem.serviceRequestVersionShipmentDetails"
                                    [skip]="skip"
                                    [selectable]="true"
                                    [scrollable]="'true'"
                                    [resizable]="true">
                                    <kendo-grid-column
                                        title="{{ 'Cargo Description' | translate }}"
                                        field="cargoDescription"
                                        [width]="200"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Cargo Type' | translate }}"
                                        field="cargoType"
                                        [width]="250"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Quantity' | translate }}"
                                        field="quantity"
                                        [width]="80"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Package Type' | translate }}"
                                        field="packageType"
                                        [width]="150"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Net Weight (kg)' | translate }}"
                                        field="netWeight"
                                        [width]="150">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{ row.netWeight | number: '1.3-3' : 'de' }}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Gross Weight (kg)' | translate }}"
                                        field="grossWeight"
                                        [width]="150">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{ row.grossWeight | number: '1.3-3' : 'de' }}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Volume' | translate }}"
                                        field="volume"
                                        [width]="100"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Cargo Reference' | translate }}"
                                        field="cargoReference"
                                        [width]="100"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'Container Number' | translate }}"
                                        field="containerNumber"
                                        [width]="100"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'MOT Type' | translate }}"
                                        field="motType"
                                        [width]="100"></kendo-grid-column>
                                    <kendo-grid-column
                                        title="{{ 'MOT Number' | translate }}"
                                        field="motNumber"
                                        [width]="100"></kendo-grid-column>
                                    <ng-template kendoGridNoRecordsTemplate>
                                        <p>{{ 'No records available' | translate }}.</p>
                                    </ng-template>
                                </kendo-grid>
                            </div>
                        </div>
                    </section>
                </ng-template>
                <ng-template kendoGridNoRecordsTemplate>
                    <p>{{ 'No records available' | translate }}.</p>
                </ng-template>
            </kendo-grid>
        </div>
    </div>
</div>

<div *ngIf="isModal" class="modal-footer">
    <button class="btn btn-sm btn-danger" translate (click)="activeModal.close(false)">Close</button>
</div>
