import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@common/services/authentication.service';

@Component({
    template: ''
})
export class LogoutComponent {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
        if (this.router.url == '/logout/local') {
            this.authenticationService.logoutAndRedirectToLogin(null, true);
        } else {
            this.authenticationService.logoutAndRedirectToLogin();
        }
    }
}
