<form autofocus [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title">{{ title | translate }}</h4>
        <button class="close" type="button" aria-label="Close" (click)="activeModal.close(null)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row card-border-top">
            <div class="card-body col">
                <div class="row">
                    <div class="col">
                        <form-textbox formControlName="mrn" [labelText]="'MRN' | translate"></form-textbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form-textbox formControlName="lrn" labelText="{{ 'LRN' | translate }}"></form-textbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form-datetime formControlName="fromDate" labelText="{{ 'From' | translate }}"></form-datetime>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form-datetime formControlName="toDate" labelText="{{ 'To' | translate }}"></form-datetime>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-sm btn-primary"
            type="submit"
            translate
            [disabled]="!(form.valid && !isFormEmpty())"
            (click)="confirmCustomsCall()">
            <fa-icon [icon]="['fas', 'check']"></fa-icon>
            &nbsp;&nbsp;{{ 'Confirm' | translate }}
        </button>
        <button class="btn btn-sm btn-secondary" type="button" translate (click)="activeModal.close(null)">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            &nbsp;&nbsp;{{ 'Cancel' | translate }}
        </button>
    </div>
</form>
