import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormService {
    setValidators(formControl: AbstractControl, validators: ValidatorFn | ValidatorFn[]) {
        formControl.setValidators(validators);
        formControl.updateValueAndValidity({ emitEvent: false });
    }

    unsetValidators(formControl: AbstractControl) {
        formControl.clearValidators();
        formControl.updateValueAndValidity({ emitEvent: false });
    }
}
