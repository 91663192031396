<kendo-dialog
    *ngIf="pcsAttachments?.length > 0"
    title="{{ 'Attachment' | translate }}"
    [minWidth]="1250"
    [maxWidth]="1250"
    [maxHeight]="800"
    (close)="activeModal.close()">
    <div class="row">
        <div class="col">
            <kendo-grid [data]="pcsAttachments">
                <kendo-grid-column title="{{ 'Attachment Name' | translate }}" field="attachmentName">
                    <ng-template kendoGridCellTemplate let-row>
                        <span class="attachmentLink" (click)="attachmentDownload(row.attachmentId)">
                            {{ row.attachmentName }}
                        </span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    title="{{ 'Description' | translate }}"
                    field="attachmentDescription"></kendo-grid-column>
                <kendo-grid-column title="{{ 'Created On Status' | translate }}" widht="120">
                    <ng-template kendoGridCellTemplate let-row>
                        {{ row.createdOnStatus.localizedCode + '-' + row.createdOnStatus.name }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Created By' | translate }}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{ row.createdBy.firstName + ' ' + row.createdBy.lastName }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Created Date' | translate }}" field="createdDate"></kendo-grid-column>
                <kendo-grid-column title="{{ 'Remove' | translate }}">
                    <ng-template kendoGridCellTemplate let-row>
                        <button
                            class="btn btn-sm btn-danger"
                            [disabled]="
                                pcsDocumentAndApprovalStatus !== row.createdOnStatus.name ||
                                !belongsToOrganization(row.organizationOfCreatedBy)
                            "
                            (click)="openRemoveAttachmentDialog(row.id)">
                            Remove
                        </button>
                    </ng-template>
                </kendo-grid-column>
                <ng-template kendoGridNoRecordsTemplate>
                    <p>{{ 'No records available' | translate }}.</p>
                </ng-template>
            </kendo-grid>
        </div>
    </div>
    <kendo-dialog-actions layout="end">
        <button class="btn btn-sm btn-primary" kendoButton (click)="activeModal.close()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            &nbsp;&nbsp;{{ 'Close' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
    *ngIf="attachments?.length > 0"
    title="{{ 'Attachment' | translate }}"
    [minWidth]="500"
    [maxWidth]="800"
    [maxHeight]="800"
    (close)="activeModal.close()">
    <div class="row">
        <div class="col">
            <kendo-grid [data]="attachments">
                <kendo-grid-column title="{{ 'Attachment Name' | translate }}" field="name">
                    <ng-template kendoGridCellTemplate let-row>
                        <span class="attachmentLink" (click)="downloadAttachment(row.attachedFile)">
                            {{ row.name }}
                        </span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{ 'Description' | translate }}" field="description"></kendo-grid-column>
                <ng-template kendoGridNoRecordsTemplate>
                    <p>{{ 'No records available' | translate }}.</p>
                </ng-template>
            </kendo-grid>
        </div>
    </div>
</kendo-dialog>
<kendo-dialog
    *ngIf="removeAttachmentDialog"
    title="{{ 'Remove Attachment' | translate }}"
    [minWidth]="350"
    (close)="closeRemoveAttachmentDialog()">
    <div class="row">
        <span>{{ 'Are you sure you want to remove this attachment?' | translate }}</span>
    </div>
    <kendo-dialog-actions>
        <button class="btn btn-sm btn-danger" kendoButton (click)="closeRemoveAttachmentDialog()">
            {{ 'Close' | translate }}
        </button>
        <button class="btn btn-sm btn-success" kendoButton (click)="submitRemoveAttachment()">
            {{ 'Submit' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
