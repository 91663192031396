import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@common/services/authentication.service';
import { ModalService } from '@common/services/modal.service';
import { UserService } from '@common/services/user.service';
import { isMobile } from '@common/utils/isMobile';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    templateUrl: './certificate-error.component.html',
    styleUrls: ['./certificate-error.component.scss']
})
export class CertificateErrorComponent {
    isMobile = isMobile();

    constructor(
        private dialogService: ModalService,
        private toastyService: ToastrService,
        private router: Router,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService
    ) {}

    reload() {
        this.router.navigate(['/']);
    }
}
