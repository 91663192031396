import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CodelistService } from '@common/services/codelist.service';
import { DeliveryOrderService } from '@common/services/delivery-order.service';
import { ModalService } from '@common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { DeliveryOrderItemsDisplayModel } from 'app/vessel-visit/delivery-order-list/models/DeliveryOrderItems.display.model';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';

@Component({
    selector: 'app-add-delivery-order-item',
    templateUrl: './add-delivery-order-item.component.html',
    styleUrls: ['./add-delivery-order-item.component.css']
})
export class AddDeliveryOrderItemComponent implements OnInit {
    isBusy: boolean;
    deliveryOrderItemsModel: DeliveryOrderItemsDisplayModel = new DeliveryOrderItemsDisplayModel();
    form: UntypedFormGroup;
    deliveryOrderId = 0;
    isEditMode = false;
    deliveryOrderItemId = 0;
    selectedDeliveryOrderItem: DeliveryOrderItemsDisplayModel;

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public service: DeliveryOrderService,
        public codelistService: CodelistService
    ) {}

    ngOnInit() {
        this.form = this.deliveryOrderItemsModel.generateGroup();
        this.checkEditMode();
    }

    checkEditMode() {
        if (this.deliveryOrderItemId) {
            // Check if deliveryOrderItemId is set
            this.isEditMode = true;
            this.loadDeliveryOrderItem(this.deliveryOrderItemId);
        }
    }

    addNewDeliveryOrder() {
        const confirmMessage = this.isEditMode
            ? (this.translateService.instant(
                  marker('Are you sure you want to update the delivery order item?')
              ) as string)
            : (this.translateService.instant(
                  marker('Are you sure you want to add the delivery order item?')
              ) as string);
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Confirm')) as string,
                confirmMessage,
                this.translateService.instant(marker('YES')) as string,
                this.translateService.instant(marker('No')) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((res) => {
                const body = {
                    ...this.form.getRawValue(),
                    deliveryOrderId: this.deliveryOrderId,
                    id: this.deliveryOrderItemId
                };

                if (this.isEditMode) {
                    this.service.addDeliveryOrderItem(body).subscribe(() => {
                        this.toastyService.success(this.translateService.instant(marker('Delivery order updated')));
                        this.activeModal.close(body);
                    });
                } else {
                    this.service.addDeliveryOrderItem(body).subscribe(() => {
                        this.toastyService.success(this.translateService.instant(marker('Delivery order created')));
                        this.activeModal.close(body);
                    });
                }
            });
    }

    loadDeliveryOrderItem(deliveryOrderItemId: number) {
        this.service.getSingleDeliveryOrderItem(deliveryOrderItemId).subscribe((item) => {
            this.selectedDeliveryOrderItem = item;
            this.form.patchValue(item);
        });
    }
}
