import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessageService } from '@common/services/validation-message.service';
import { AbstractFormControlComponent } from './abstract-form-control.component';

@Component({
    selector: 'form-textbox',
    styleUrls: ['form-controls.component.scss'],
    template: `
        <div class="form-group" [ngClass]="{ 'has-value': !!value }">
            <app-control
                [type]="textboxType"
                [isDisabled]="isDisabled"
                [label]="labelText"
                [ngClass]="{ 'is-invalid': isInvalid }"
                [isInvalid]="isInvalid"
                [formControlValidators]="getFormControlValidators"
                [contextual]="contextual"
                [passwordAutocomplete]="passwordAutocomplete"
                [(ngModel)]="value"
                (ngModelChange)="changeValue($event)"></app-control>
        </div>
    `,
    providers: []
})
export class TextboxComponent extends AbstractFormControlComponent {
    @Input() labelText: string;
    @Input() contextual: string = null;
    @Input() isDisabled: boolean;
    @Input() textboxType = 'string';
    @Input() passwordAutocomplete: string;

    constructor(@Self() @Optional() controlDir: NgControl, validationMessageService: ValidationMessageService) {
        super(controlDir, validationMessageService);
    }
}
