import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessageService } from '@common/services/validation-message.service';
import { AbstractFormControlComponent } from './abstract-form-control.component';

@Component({
    selector: 'form-datetime',
    styleUrls: ['form-controls.component.scss'],
    template: `
        <div class="form-group has-value ">
            <label class="col-form-label">
                {{ labelText }}
            </label>
            <app-control
                [type]="'datetime'"
                [kendoType]="'datetime'"
                [isDisabled]="isDisabled"
                [ngClass]="{ 'is-invalid': isInvalid }"
                [isInvalid]="isInvalid"
                [formControlValidators]="getFormControlValidators"
                [contextual]="contextual"
                [(ngModel)]="value"
                (ngModelChange)="changeValue($event)"></app-control>
        </div>
    `,
    providers: []
})
export class DatetimeComponent extends AbstractFormControlComponent {
    @Input()
    labelText: string;
    @Input()
    contextual: string = null;

    constructor(@Self() @Optional() controlDir: NgControl, validationMessageService: ValidationMessageService) {
        super(controlDir, validationMessageService);
    }
}
