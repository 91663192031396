import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AglLogModel } from '@common/models/common/AglLog.model';
import { LogType } from '@common/models/common/LogType';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AglLoggingService {
    constructor(private http: HttpClient) {}

    logAglAppEvent(component: string, category: string, message: string) {
        const body = new AglLogModel();
        body.component = component;
        body.category = category;
        body.message = message;
        body.logType = LogType.AppEvent;

        this.logToAgl(body);
    }

    logAglUserAction(actionName: string, subject: string, subjectId: string, message: string, category: string) {
        const body = new AglLogModel();
        body.actionName = actionName;
        body.subject = subject;
        body.subjectId = subjectId;
        body.message = message;
        body.category = category;
        body.logType = LogType.UserAction;

        this.logToAgl(body);
    }

    logToAgl(model: AglLogModel) {
        return this.http.post<any>(`api:///Agl/LogToAgl/`, model).pipe(take(1)).subscribe();
    }
}
