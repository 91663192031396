import { Component } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button class="close" type="button" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="message"></div>
        <div *ngIf="yesButtonName || noButtonName || cancelButtonName; else defaultModal" class="modal-footer">
            <button
                *ngIf="yesButtonName"
                class="btn btn-sm btn-primary"
                preventMultiClick
                (click)="activeModal.close(true)">
                {{ yesButtonName }}
            </button>
            <button *ngIf="noButtonName" class="btn btn-sm btn-secondary" (click)="activeModal.close(false)">
                {{ noButtonName }}
            </button>
            <button *ngIf="cancelButtonName" class="btn btn-sm btn-secondary" (click)="activeModal.close(false)">
                {{ cancelButtonName }}
            </button>
        </div>
        <ng-template #defaultModal>
            <div class="modal-footer">
                <button class="btn btn-sm btn-primary" preventMultiClick translate (click)="activeModal.close(true)">
                    Ok
                </button>
                <button class="btn btn-sm btn-secondary" translate (click)="activeModal.close(false)">Cancel</button>
            </div>
        </ng-template>
    `
})
export class ConfirmDialogComponent {
    public title: string;
    public message: string;
    public yesButtonName: string;
    public noButtonName: string;
    public cancelButtonName: string;

    constructor(public activeModal: DialogRef) {}
}
