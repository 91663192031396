import { LogType } from './LogType';

export class AglLogModel {
    public logType: LogType;
    public component: string;
    public category: string;
    public actionName: string;
    public message: string;
    public subject: string;
    public subjectId: string;
}
