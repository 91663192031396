<div class="model-header">
    <h4 class="modal-title">{{ 'Add New DTS' | translate }}</h4>
    <button class="close" type="button" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <app-loader [isBusy]="false">
        <ng-template>
            <form class="p-3" [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <form-textbox formControlName="mrn" labelText="{{ 'MRN' | translate }}"></form-textbox>
                    </div>
                    <div class="col">
                        <form-datetime
                            formControlName="acceptanceDate"
                            labelText="{{ 'Acceptance Date' | translate }}"></form-datetime>
                    </div>
                    <div class="col">
                        <form-dropdown
                            formControlName="lodgingOfficeId"
                            labelText="{{ 'Lodging Office' | translate }}"
                            codeListName="CodeListBGCustomsOffice"></form-dropdown>
                    </div>
                    <div class="col">
                        <form-textbox
                            formControlName="warehouse"
                            labelText="{{ 'Warehouse' | translate }}"></form-textbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form-textbox
                            formControlName="goodsDescription"
                            labelText="{{ 'Goods Description' | translate }}"></form-textbox>
                    </div>
                    <div class="col">
                        <form-number
                            formControlName="netWeight"
                            labelText="{{ 'Net Weight (kg)' | translate }}"
                            [decimal]="true"></form-number>
                    </div>
                    <div class="col">
                        <form-number
                            formControlName="grossWeight"
                            labelText="{{ 'Gross Weight (kg)' | translate }}"
                            [decimal]="true"></form-number>
                    </div>
                    <div class="col">
                        <form-number formControlName="quantity" labelText="{{ 'Quantity' | translate }}"></form-number>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form-dropdown
                            formControlName="packagingTypeId"
                            labelText="{{ 'Packaging Type' | translate }}"
                            codeListName="CodeListCargoPackagingTypePerSystem"
                            [forTOSSystem]="'CUST'"></form-dropdown>
                    </div>
                    <div class="col">
                        <form-number
                            formControlName="volume"
                            labelText="{{ 'Volume' | translate }}"
                            [decimal]="true"></form-number>
                    </div>
                    <div class="col">
                        <form-textbox
                            formControlName="declarant"
                            labelText="{{ 'Declarant' | translate }}"></form-textbox>
                    </div>
                    <div class="col">
                        <form-dropdown
                            formControlName="portId"
                            labelText="{{ 'Port' | translate }}"
                            codeListName="CustomPort"></form-dropdown>
                    </div>
                </div>
            </form>
        </ng-template>
    </app-loader>
</div>

<div class="modal-footer">
    <button class="btn btn-sm btn-danger" translate (click)="activeModal.close(false)">Cancel</button>
    <button class="btn btn-sm btn-primary" translate (click)="addNewDts()">Save</button>
</div>
