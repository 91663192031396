<app-loader [isBusy]="isBusy">
    <ng-template>
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="card card-border-top mt-3">
                <div class="card-header mt-3">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4 text-center">
                            <h5 style="color: #184b7e">{{ 'Welcome to BulPCS' | translate }}</h5>
                            <div class="row d-flex justify-content-center">
                                {{ form.value.firstName }} {{ form.value.lastName }}
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <button class="btn btn-sm btn-secondary mr-2" type="button" (click)="goBack()">
                                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                &nbsp;&nbsp;{{ 'Cancel' | translate }}
                            </button>
                            <button class="btn btn-sm btn-primary" type="submit" [disabled]="getIsFormDisabled">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>
                                &nbsp;&nbsp;{{ 'Save' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 pt-1 info-helper-text">
                            {{
                                'To create a PCS user fill out the form below. User name will be automatically created for you and sent to your email address.'
                                    | translate
                            }}
                        </div>

                        <ul>
                            <li class="col-12 pt-1 info-helper-text">
                                {{
                                    'password should be unique, do not use the same password for multiple systems'
                                        | translate
                                }}
                            </li>
                            <li class="col-12 pt-1 info-helper-text">
                                {{
                                    'password should be complex, do not use name, surname, pcs, birthdays or 123 in your password'
                                        | translate
                                }}
                            </li>
                            <li class="col-12 pt-1 info-helper-text">
                                {{
                                    'password should be made out of 2 or more unrelated words or random characters, more meaning a safer password'
                                        | translate
                                }}
                            </li>
                        </ul>
                    </div>

                    <hr />

                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <form-textbox
                                stripAllWhitespace
                                labelText="{{ 'First Name' | translate }}"
                                formControlName="firstName"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                stripAllWhitespace
                                labelText="{{ 'Middle Name' | translate }}"
                                formControlName="middleName"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                stripAllWhitespace
                                labelText="{{ 'Last Name' | translate }}"
                                formControlName="lastName"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                labelText="{{ 'User Name' | translate }}"
                                formControlName="userName"></form-textbox>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <form-textbox
                                labelText="{{ 'Academic Title' | translate }}"
                                formControlName="academicTitle"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                labelText="{{ 'Professional Title' | translate }}"
                                formControlName="professionalTitle"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-dropdown
                                formControlName="organizationId"
                                labelText="{{ 'Organization' | translate }}"
                                #organizationDropdown
                                [codeListValues]="organizations"></form-dropdown>
                        </div>
                        <div class="col-sm-3">
                            <form-dropdown
                                formControlName="requestedRoleIds"
                                labelText="{{ 'Requested access levels' | translate }}"
                                [codeListValues]="roles"
                                [multi]="true"></form-dropdown>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <form-textbox labelText="{{ 'Email' | translate }}" formControlName="email"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-number
                                labelText="{{ 'Phone Number' | translate }}"
                                formControlName="phoneNumber"></form-number>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                labelText="{{ 'Password' | translate }}"
                                formControlName="password"
                                textboxType="password"
                                passwordAutocomplete="new-password"
                                contextual="{{
                                    'Minimum of 9 characters including lower and capital letter, a number, and a special character.'
                                        | translate
                                }}"></form-textbox>
                        </div>

                        <div class="col-sm-3">
                            <form-textbox
                                labelText="{{ 'Confirm password' | translate }}"
                                formControlName="confirmPassword"
                                textboxType="password"
                                passwordAutocomplete="new-password"></form-textbox>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <form-dropdown
                                formControlName="twoFactorPreferenceId"
                                labelText="{{ 'Two Factor Preference' | translate }}"
                                #twoFactorPreferenceDropdown
                                [displayValue]="'name'"
                                [codeListValues]="twoFactorPreferences"></form-dropdown>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                formControlName="iddNumber"
                                labelText="{{ 'IDD Number' | translate }}"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-textbox
                                labelText="{{ 'Certificate Serial Number' | translate }}"
                                formControlName="certificateNumber"></form-textbox>
                        </div>
                        <div class="col-sm-3">
                            <form-dropdown
                                formControlName="selectedLanguage"
                                labelText="{{ 'Language' | translate }}"
                                [codeListValues]="languages"></form-dropdown>
                        </div>
                    </div>

                    <div class="tac-row row">
                        <div class="col-12">
                            <span>
                                <input type="checkbox" formControlName="tac" #notification kendoCheckBox />
                                {{ 'I have read and agree with the' | translate }}
                                <a
                                    class="tac-checkbox"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="http://www.bgports.bg/en/page/78">
                                    {{ 'Terms & Conditions and the Privacy Policy' | translate }}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="row file-select">
                        <div class="col-md-12 col-lg-5 float-left">
                            <div class="pt-1 info-helper-text file-text">
                                <div>
                                    {{
                                        'Select a PDF document that confirms employment at selected company.'
                                            | translate
                                    }}
                                </div>
                                <div *ngIf="files.length === 0" class="error-file"></div>
                            </div>
                            <kendo-fileselect
                                [restrictions]="attachmentFileUploadRestrictions"
                                [multiple]="true"
                                (select)="fileSelected($event)"
                                (remove)="fileRemoved($event)">
                                <kendo-upload-messages
                                    select="{{ 'Select files' | translate }}"
                                    dropFilesHere="{{
                                        'Drop files here to select' | translate
                                    }}"></kendo-upload-messages>
                            </kendo-fileselect>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</app-loader>
