import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ViewMode } from '@common/models/view-mode';
import { TranslateService } from '@ngx-translate/core';
import { DateInputCustomFormatPlaceholder } from '@progress/kendo-angular-dateinputs';
import { filter, isArray, isDate, isString, map } from 'lodash-es';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Size } from '../checkbox/checkbox.component';

export enum AppControlType {
    String = 'string',
    Textarea = 'textarea',
    Boolean = 'boolean',
    DateTime = 'datetime',
    Number = 'number',
    Array = 'array',
    Password = 'password',
    CodeList = 'codelist',
    File = 'file',
    YesNo = 'yesno',
    Select = 'select',
    Static = 'static'
}

const noop = (item) => `${item.code} - ${item.name}`;

// TODO:  - split codelist component
//        - remote filtering

@Component({
    selector: 'app-control',
    templateUrl: 'app-control.component.html',
    styleUrls: ['app-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppControlComponent,
            multi: true
        }
    ]
})
export class AppControlComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    fieldTextType: boolean;

    @ViewChild('tooltip', { static: false }) set tooltip(value) {
        this._tooltip = value;
        if (value) {
            this.showValidationErrors();
        }
    }

    get tooltip() {
        return this._tooltip;
    }

    get navigationProperty() {
        if (!this.property) {
            return this.property;
        }
        return this.property;
    }

    set value(value) {
        // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        if (this.type === AppControlType.Number) {
            this.val = this.roundNumber(value);
        } else {
            this.val = value;
        }
        this.onChange(value);
        this.onTouch(value);
    }

    get value() {
        return this.val;
    }
    get dateValue() {
        if (isDate(this.val)) {
            return this.val;
        }

        if (isString(this.val)) {
            const date = new Date(this.val);

            if (!date || !this._date || date.getTime() !== this._date.getTime()) {
                this._date = date;
            }

            return this._date;
        }

        return this.val;
    }

    set dateValue(value) {
        this._date = value;

        if (isDate(value)) {
            this.value = value.toISOString();
        } else {
            this.value = value;
        }
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService
    ) {}
    AppControlType: typeof AppControlType = AppControlType;
    errors: any[] = [];

    filteredOptions: any[] = [];

    showTooltip = environment.settings.validation.errors.showTooltip;
    showFeedback = environment.settings.validation.errors.showFeedback;

    isBusy = false;

    private val;
    private _subscription;
    private _syntheticProperty;
    private _tooltip;

    minYear = 1;

    public dateTimeFormatPlaceholder: DateInputCustomFormatPlaceholder;
    public dateFormatPlaceholder: Pick<DateInputCustomFormatPlaceholder, 'year' | 'month' | 'day'>;
    public timeFormatPlaceholder: Pick<DateInputCustomFormatPlaceholder, 'hour' | 'minute'>;

    @Input() options: any[] = [];
    @Input() type: AppControlType;
    @Input() label: string;
    @Input() ngModel: NgModel;
    @Input() modelPath?: string;
    @Input() path?: string;
    @Input() property?: string;
    @Input() codelist?: string;
    @Input() selectLabel = noop;
    @Input() fetch: (search, selectedValue) => Promise<any[]>;
    @Input() size: Size = Size.normal;
    @Input() multi = false;
    @Input() kendoType = 'datetime';
    @Input() isInTable = false;
    @Input() decimal: boolean;
    @Input() forFilter: boolean;
    @Input() min;
    @Input() max;
    @Input() static = false;
    @Input() isDisabled;
    @Input() isInvalid;
    @Input() formControlValidators;
    @Input() contextual: string = null;
    @Input() hasValue = false;
    @Input() margin = true;
    @Input() placeholder: string;

    @Input() passwordAutocomplete: string;
    @Input() roundNumberToPlaces = 0;

    private childEntityName: string;
    private fkName: string;

    currentDateValue$ = new Subject<Date>();

    _date: Date;

    roundModifier = 0;

    maybeApplyActive() {
        this.hasValue =
            !!this.value ||
            (this.value === 0 && this.type === AppControlType.Number) ||
            (this.value === false && this.type === AppControlType.YesNo);
    }

    multiSelectFocusInHandler() {
        this.hasValue = !!this.value || !this.isDisabled;
        if (this.label) this.placeholder = this.translateService.instant(marker('Input')) + ' ' + this.label;
    }

    multiSelectFocusOutHandler() {
        this.hasValue = false;
        this.maybeApplyActive();
        this.placeholder = null;
    }

    onChange: any = () => {};
    onTouch: any = () => {};

    ngOnInit() {
        this.roundModifier = this.roundNumberToPlaces * 10;

        if (!this.type) {
            this.type = AppControlType.String;
        }

        if (this.type === AppControlType.DateTime) {
            this.dateTimeFormatPlaceholder = this.getDateTimeFormatPlaceHolder();
            this.dateFormatPlaceholder = this.getDateFormatPlaceHolder();
            this.timeFormatPlaceholder = this.getTimeFormatPlaceholder();
        }

        this._syntheticProperty = this.navigationProperty;

        if (
            this.isDisabled === undefined &&
            this.activatedRoute.snapshot.data &&
            this.activatedRoute.snapshot.data.mode === ViewMode.view
        ) {
            this.isDisabled = true;
        }

        this.currentDateValue$.pipe(debounceTime(1000)).subscribe((currDate: Date) => {
            if (currDate?.getFullYear() <= this.minYear) {
                currDate.setFullYear(this.minYear);
                this.dateValue = currDate;
            }
        });
    }

    dateTimePickerValueChange = (date: Date) => this.currentDateValue$.next(date);

    ngOnDestroy() {
        if (this.currentDateValue$) {
            this.currentDateValue$.unsubscribe();
        }
    }

    ngOnChanges(changes) {
        if (changes && changes.ngModel && changes.ngModel.firstChange) {
            this.writeValue(changes.ngModel.currentValue);
        }

        this.showFormControlValidation();
    }

    private showValidationErrors() {
        if (this.errors.length > 0) {
            this.tooltip.ngbTooltip = map(this.errors, (x) => x.errorMessage).join('\n');
            if (this.showTooltip) {
                // this.tooltip.open();
            }
        } else {
            this.tooltip.ngbTooltip = null;
            this.tooltip.close();
        }
    }

    private showFormControlValidation() {
        if (this.formControlValidators && this.tooltip) {
            this.tooltip.ngbTooltip = map(this.formControlValidators, (x) =>
                this.translateService.instant(marker(x))
            ).join('\n');
        } else {
            if (this.tooltip) {
                this.tooltip.ngbTooltip = null;
            }
        }
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // this.isDisabled = isDisabled;
    }

    public isItemSelected(val) {
        return this.value && this.value.some((x) => x === val);
    }

    getTooltip(value) {
        if (!value) {
            return undefined;
        }

        if (!isArray(value)) {
            value = [value];
        }

        const labels = map(
            filter(this.options, (x: any) => value.includes(x.value)),
            (x: any) => x.label
        );

        return labels && labels.join('\n');
    }

    getDateTimeFormatPlaceHolder = (): DateInputCustomFormatPlaceholder => {
        return {
            day: this.translateService.instant(marker('day')),
            month: this.translateService.instant(marker('month')),
            year: this.translateService.instant(marker('year')),
            hour: this.translateService.instant(marker('hour')),
            minute: this.translateService.instant(marker('minute')),
            second: this.translateService.instant(marker('second'))
        };
    };

    getDateFormatPlaceHolder = (): Pick<DateInputCustomFormatPlaceholder, 'year' | 'month' | 'day'> => {
        return {
            day: this.translateService.instant(marker('day')),
            month: this.translateService.instant(marker('month')),
            year: this.translateService.instant(marker('year'))
        };
    };

    getTimeFormatPlaceholder = (): Pick<DateInputCustomFormatPlaceholder, 'hour' | 'minute'> => {
        return {
            hour: this.translateService.instant(marker('hour')),
            minute: this.translateService.instant(marker('minute'))
        };
    };

    /** For app-control of type string (form-textbox) prevent ' ' as first character */
    handleTextboxKeydown($event: KeyboardEvent) {
        if ($event.code === 'Space' && !this.value) {
            $event.preventDefault();
        }
    }

    roundNumber(val) {
        const numValue: number = +val;
        if (this.roundModifier !== 0) {
            return (Math.round((numValue + Number.EPSILON) * this.roundModifier) / this.roundModifier).toString();
        }
        return val;
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }
}
