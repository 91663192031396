import { Injectable } from '@angular/core';

declare global {
    interface Navigator {
        msSaveBlob: (blob: Blob, defaultName?: string) => boolean;
    }
}

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {
    constructor() {}

    public handleAttachmentDownload(data) {
        const arrayBuffer = this.base64ToArrayBuffer(data.base64Content);
        this.createAndDownloadBlobFile(arrayBuffer, data.name);
    }

    private base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    private createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}
