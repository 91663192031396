import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { requiredValidator } from '@common/validators/required-validator';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-mot-on-storage-document-modal-component',
    templateUrl: './customs-declaration-modal.component.html',
    styleUrls: ['./customs-declaration-modal.component.scss']
})
export class CustomsDeclarationModalComponent implements OnInit, AfterViewInit, OnDestroy {
    public title: string;

    destroyed$ = new Subject<void>();
    form: FormGroup;

    private isUpdating = false;

    constructor(public activeModal: DialogRef) {}

    ngOnInit() {
        this.form = this.createFormGroup();
    }

    ngAfterViewInit(): void {
        this.trackFormChanges();
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    confirmCustomsCall() {
        this.activeModal.close(this.form.getRawValue());
    }

    trackFormChanges() {
        this.form
            .get('fromDate')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                if (this.isUpdating) return;

                this.isUpdating = true;
                if (value) {
                    this.form.get('toDate').setValidators(requiredValidator());
                } else {
                    this.form.get('toDate').clearValidators();
                }
                this.form.get('toDate').updateValueAndValidity();
                this.isUpdating = false;
            });

        this.form
            .get('toDate')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                if (this.isUpdating) return;

                this.isUpdating = true;
                if (value) {
                    this.form.get('fromDate').setValidators(requiredValidator());
                } else {
                    this.form.get('fromDate').clearValidators();
                }
                this.form.get('fromDate').updateValueAndValidity();
                this.isUpdating = false;
            });
    }

    private createFormGroup() {
        const form = new UntypedFormGroup({
            mrn: new UntypedFormControl(),
            lrn: new UntypedFormControl(),
            fromDate: new UntypedFormControl(),
            toDate: new UntypedFormControl()
        });

        form.patchValue(this);
        return form;
    }

    isFormEmpty(): boolean {
        return Object.values(this.form.controls).every((control) => !control.value);
    }
}
