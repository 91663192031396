import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomsService {
    constructor(private http: HttpClient) {}

    getDeclarationsList(filter: any): Observable<any> {
        return this.http.post('api:///Customs/GetDeclarationsList', filter);
    }

    getDeclaration(id: number): Observable<any> {
        return this.http.get<any>(`api:///Customs/GetDeclaration/${id}`);
    }

    getDeclarationByMrn(mrn: string): Observable<any> {
        return this.http.get<any>(`api:///Customs/GetDeclarationByMrn/${mrn}`);
    }

    getCargos(filter: any): Observable<any> {
        return this.http.post<any>(`api:///Customs/GetCargos`, filter);
    }

    getRealizedQuantities(mrn: string): Observable<any> {
        return this.http.get<any>(`api:///Customs/GetRealizedQuantities/${mrn}`);
    }

    getServiceRequestsForDeclaration(mrnId: number): Observable<any> {
        return this.http.get<any>(`api:///Customs/GetServiceRequestsForDeclaration/${mrnId}`);
    }

    manualGetMessage(model: any): Observable<any> {
        return this.http.post('api:///Customs/ManualGetMessage', model);
    }
}
