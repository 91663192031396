<form autofocus [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
        <button class="close" type="button" aria-label="Close" (click)="activeModal.close(null)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form-dropdown
            formControlName="languageId"
            labelText="{{ 'Language' | translate }}"
            [codeListValues]="languages"></form-dropdown>
        <br />
        <label>{{ 'Changing the language will reload the page you are currently in. Continue?' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-primary br-2" translate [disabled]="!form.valid" (click)="save()">Save</button>
        <button class="btn btn-sm btn-secondary" translate (click)="activeModal.close(null)">Cancel</button>
    </div>
</form>
