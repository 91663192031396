export class PagingAndOrderingModel {
    offset: number;
    pageSize: number;
    orderBy: string;
    orderDirection: string;

    constructor(offset?: number, pageSize?: number, orderBy?: string, orderDirection?: string) {
        this.offset = offset != null ? offset : null;
        this.pageSize = pageSize != null ? pageSize : null;
        this.orderBy = orderBy != null ? orderBy : null;
        this.orderDirection = orderDirection != null ? orderDirection : null;
    }
}
