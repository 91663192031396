import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    errorNumber: string;

    private errorList: string[] = ['401', '500', '404', '503'];

    private defaultError = '500';

    @Input()
    public error: string;

    @Input()
    showErrorNumber: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private locationStrategy: LocationStrategy
    ) {}

    async ngOnInit(): Promise<void> {
        this.route.params.subscribe(async (x) => {
            // get error number
            let num = this.route.snapshot.params['errorNumber'];
            if (this.errorList.findIndex((x) => x == num) == -1) {
                num = this.defaultError;
            }
            this.errorNumber = num;
        });
    }

    reload() {
        const getUrl = window.location;
        const baseUrl = getUrl.protocol + '//' + getUrl.host + this.locationStrategy.getBaseHref();
        window.location.href = baseUrl;
    }
}
