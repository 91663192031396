import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PagingAndOrderingModel } from '@common/models/common/PagingAndOrdering.model';
import { requiredValidator } from '@common/validators/required-validator';

export class DeliveryOrderListDisplayModel extends PagingAndOrderingModel {
    deliveryOrderId = 0;
    statusId: string;
    typeId: string;
    berthId: string;
    pcsApprovals: any[];
    serviceProviderId: number;
    meanOfTransport: string;
    deliveryTime: Date;
    quantity: number;
    grossWeight: number;
    netWeight: number;
    volume: number;
    vesselVisitId: number;
    vessel: string;
    terminalId: string;
    deliveryTimeFrom: Date;
    deliveryTimeTo: Date;
    createdDateFrom: Date;
    createdDateTo: Date;
    completedDateFrom: Date;
    completedDateTo: Date;

    constructor(pageFilter?: PagingAndOrderingModel, data = null) {
        super();

        if (data) {
            Object.assign(this, data);
        }
    }

    generateGroup() {
        const form = new UntypedFormGroup({
            typeId: new UntypedFormControl({ value: null, disabled: false }, requiredValidator()),
            statusId: new UntypedFormControl({ value: null, disabled: false }),
            berthId: new UntypedFormControl({ value: null, disabled: false }),
            serviceProviderId: new UntypedFormControl({ value: null, disabled: false }, requiredValidator()),
            meanOfTransport: new UntypedFormControl({ value: null, disabled: false }),
            quantity: new UntypedFormControl({ value: null, disabled: false }),
            grossWeight: new UntypedFormControl({ value: null, disabled: false }),
            netWeight: new UntypedFormControl({ value: null, disabled: false }),
            volume: new UntypedFormControl({ value: null, disabled: false }),
            id: new UntypedFormControl({ value: null, disabled: false }),
            pcsApprovalId: new UntypedFormControl({ value: null, disabled: false }),
            vesselVisitNumber: new UntypedFormControl({ value: null, disabled: false }),
            vessel: new UntypedFormControl({ value: null, disabled: false }),
            terminalId: new UntypedFormControl({ value: null, disabled: false }),
            terminalOperatorId: new UntypedFormControl({ value: null, disabled: false }),
            launchOperatorId: new UntypedFormControl({ value: null, disabled: false }),
            mrn: new UntypedFormControl({ value: null, disabled: false }),
            vesselMaster: new UntypedFormControl({ value: null, disabled: false }),
            remarks: new UntypedFormControl({ value: null, disabled: false }),
            name: new UntypedFormControl({ value: null, disabled: false }),
            description: new UntypedFormControl({ value: null, disabled: false }),
            deliveryTime: new UntypedFormControl({ value: null, disabled: false }),
            deliveryTimeFrom: new UntypedFormControl({ value: null, disabled: false }),
            deliveryTimeTo: new UntypedFormControl({ value: null, disabled: false }),
            createdDateFrom: new UntypedFormControl({ value: null, disabled: false }),
            createdDateTo: new UntypedFormControl({ value: null, disabled: false }),
            completedDateFrom: new UntypedFormControl({ value: null, disabled: false }),
            completedDateTo: new UntypedFormControl({ value: null, disabled: false })
        });
        form.patchValue(this);
        return form;
    }
}
