import { Component } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { environment } from 'environments/environment';
import * as _ from 'lodash';

@Component({
    template: `
        <div class="modal-header">
            <div class="row" style="width: 100%">
                <div class="col-10">
                    <h4 class="modal-title">{{ title }}</h4>
                </div>
                <div class="col-2">
                    <button class="close" type="button" aria-label="Close" (click)="activeModal.close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="alert alert-danger" role="alert">
                <div [innerHTML]="message"></div>
            </div>

            <div *ngIf="hasStackTrace()">
                <div class="panel panel-danger">
                    <div class="panel-heading" translate>StackTrace</div>
                    <div class="panel-body">
                        <pre
                            style="color: #c7254e; max-height: 500px; overflow-y: scroll;"
                            [innerHTML]="stackTrace"></pre>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-sm btn-primary" translate (click)="activeModal.close(true)">Ok</button>
        </div>
    `
})
export class ErrorDialogComponent {
    stackTrace: string;
    message: string;
    isServerSideError: boolean;
    titleText: string;
    validationErrors: any[];
    isForExcel: boolean;

    constructor(
        public activeModal: DialogRef,
        private translateService: TranslateService
    ) {}

    get title() {
        if (this.titleText) {
            return this.titleText;
        }

        return this.isServerSideError
            ? this.translateService.instant(marker('System Error'))
            : this.translateService.instant(marker('Client side error occurred'));
    }

    hasStackTrace() {
        return !!this.stackTrace;
    }

    setMessage(message, validationErrors) {
        this.message = `<h6>${message}</h6><br />`;
        if (this.isForExcel) return;
        this.message += `<span> ${validationErrors.split('at Bulgaria.')[0]}</span><br />`;
    }

    setStackTrace(stackTrace) {
        if (environment.production) return;

        if (stackTrace && !_.isString(stackTrace)) {
            console.error(stackTrace);
            stackTrace = stackTrace.toString();
        }

        this.stackTrace = stackTrace;
    }
}
