import { Component, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AttachmentService } from '@common/services/attachment.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'file-upload-generic-modal',
    templateUrl: './file-upload-generic-modal-component.html',
    styleUrls: ['./file-upload-generic-modal-component.scss']
})
export class FileUploadGenericModalComponent implements OnInit {
    files = [];

    yesButtonName;

    title;

    maximumFileCount = 10;

    isBusy = false;

    constructor(
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        private attachmentService: AttachmentService,
        private toastrService: ToastrService
    ) {}

    attachmentFileUploadRestrictions: FileRestrictions;

    ngOnInit() {
        if (!this.attachmentFileUploadRestrictions) this.loadAttachmentSettings();
        if (this.yesButtonName == null) this.yesButtonName = this.translateService.instant(marker('Finish selection'));

        if (this.title == null) this.title = this.translateService.instant(marker('File selection'));
    }

    private loadAttachmentSettings(): void {
        this.attachmentService.getAttachmentSettingsForUpload().subscribe((response) => {
            this.attachmentFileUploadRestrictions = response as FileRestrictions;
        });
    }

    fileSelected($event) {
        if ($event.files.length > this.maximumFileCount) {
            this.toastyService.error(this.translateService.instant(marker('Please select maximum of 10 files')));
            $event.preventDefault();
            return;
        }

        $event.files.forEach((element) => {
            if (element.size > this.attachmentFileUploadRestrictions.maxFileSize) {
                this.toastyService.error(
                    this.translateService.instant(marker('Attachment size invalid')) + ' - ' + element.name
                );
                return;
            }
            if (!this.attachmentFileUploadRestrictions.allowedExtensions.some((ext) => ext == element.extension)) {
                this.toastyService.error(
                    this.translateService.instant(marker('Invalid attachment')) + ' - ' + element.name
                );
                return;
            }
            const raw = element.rawFile;
            if (raw) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.documentOnLoad(e.target.result.toString(), raw.name);
                };
                reader.readAsDataURL(element.rawFile);
            }
        });
    }

    documentOnLoad(file: string, name: string) {
        this.files.push({ base64Content: file, base64ContentName: name });
    }

    fileRemoved($event) {
        $event.files.forEach((element) => {
            const file = this.files.filter((x) => x.base64ContentName == element.name);

            if (file == null || file.length == 0) return;

            this.files.splice(this.files.indexOf(file[0]), 1);
        });
    }

    finish() {
        this.activeModal.close(this.files);
    }
}
