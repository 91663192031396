import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-audit-log-modal',
    templateUrl: './audit-log-modal.component.html',
    styleUrls: ['./audit-log-modal.component.scss']
})
export class AuditLogModalComponent implements OnInit, OnDestroy {
    public auditLog: any;
    public title: string;

    destroyed$ = new Subject<void>();

    constructor(
        public activeModal: DialogRef,
        private datePipe: DatePipe
    ) {}

    ngOnInit() {
        ['entityJSON', 'originalValues', 'newValues', 'changedCollections'].forEach((prop) => {
            if (this.auditLog[prop]?.length === 0) this.auditLog[prop] = null;
            if (typeof this.auditLog[prop] === 'object') return;

            var result = (this.auditLog[prop] = JSON.parse(this.auditLog[prop] || null));

            if (result) {
                const dateFields = [
                    'CreatedDate',
                    'createdDate',
                    'LastModifiedDate',
                    'lastModifiedDate',
                    'LastTokenRefresh',
                    'lastTokenRefresh',
                    'LastLockoutReactivationDate',
                    'lastLockoutReactivationDate',
                    'DeviceTwoFactorLoginDate',
                    'deviceTwoFactorLoginDate',
                    'ActivationDate',
                    'activationDate',
                    'LastLockoutReactivationDate',
                    'lastLockoutReactivationDate',
                    'LockoutEnd',
                    'lockoutEnd',
                    'PasswordExpire',
                    'passwordExpire'
                ];
                dateFields.forEach((field) => {
                    if (result[field]) {
                        let utcDate = new Date(result[field]);
                        utcDate.setTime(utcDate.getTime() + 1 * 60 * 60 * 1000);
                        const localDatetime = utcDate.toLocaleString();

                        result[field] = localDatetime;
                    }
                });
            }

            return result;
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
